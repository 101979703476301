import { AppState } from "../../../store/app.state";
import EmailForm from "./email-form.component";
import { connect } from "react-redux";
import { getEmailModalWindow } from "../../../store/change-email/change-email.selector";
import { Dispatch } from "redux";
import {
  ChangeEmailDto,
  sendEmail,
  toggleEmailModalWindow,
} from "../../../store/change-email/change-email.actions";

interface IncomingProps {
  isOpen?: boolean;
}

const mapStateToProps = (state: AppState) => ({
  isOpen: getEmailModalWindow(state),
});

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  sendEmail: (data: ChangeEmailDto) => dispatch(sendEmail(data)),
  toggleEmailModalWindow: (isOpen: boolean) => dispatch(toggleEmailModalWindow(isOpen)),
});

const EmailFormContainer = (props: IncomingProps) => <EmailForm {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(EmailFormContainer);
