import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ResetPasswordActionType } from "./reset-password.actions";

export interface ResetPasswordState {
  email: string;
  loadStatus: LoadingStatus;
  lastReset: Date | undefined;
}

export const initialState = {
  email: "",
  loadStatus: loadInitial(),
  lastReset: undefined,
};

export default createReducer(initialState, {
  [ResetPasswordActionType.SUBMIT_EMAIL](
    state: ResetPasswordState,
    action: Action<string>
  ): ResetPasswordState {
    return { ...state, email: action.payload, loadStatus: loadPending() };
  },
  [ResetPasswordActionType.SUBMIT_EMAIL_SUCCESS](state: ResetPasswordState): ResetPasswordState {
    return { ...state, loadStatus: loadSuccess(), lastReset: new Date(Date.now()) };
  },
  [ResetPasswordActionType.SUBMIT_EMAIL_FAILURE](
    state: ResetPasswordState,
    action: Action<string>
  ): ResetPasswordState {
    return { ...state, loadStatus: loadFailed(action.payload), lastReset: new Date(Date.now()) };
  },
});
