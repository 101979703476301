import { ComponentType, useContext } from "react";
import BrowserStorageContext from "../context/context";
import AuthService from "./auth.service";

const withAuthService = (Component: ComponentType<any>) => (props: any) => {
  const storage = useContext(BrowserStorageContext);
  const authService = AuthService.getInstance(storage);
  return <Component {...props} authService={authService} />;
};

export default withAuthService;
