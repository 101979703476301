import { FunctionComponent, useState } from "react";
import AppButton from "../common/app-button/app-button";
import renderTextField from "../common/render-text-field/render-text-field";
import useStyles from "./change-email-form.styles";
import ShowPasswordButton from "../common/show-password-button/show-password-button.component";
import { FieldName, PlaceHolder } from "../../shared/field-data";
import { ButtonSize } from "../../shared/buttons-style";
import { SubmitHandler, reduxForm, Field } from "redux-form";
import { useTranslation } from "react-i18next";
import {
  required,
  email,
  MinLengths,
  MaxLengths,
  password,
} from "../../utils/authValidation/auth-validation";

interface Props {
  handleSubmit: SubmitHandler;
  onClose: () => void;
}

const ChangeEmailForm: FunctionComponent<Props> = ({ handleSubmit, onClose }) => {
  const classes = useStyles();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const { t } = useTranslation();

  const switchPasswordType = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={classes.flexWrapper}>
        <Field
          name={FieldName.NEW_EMAIL}
          placeholder={t(PlaceHolder.EMAIL)}
          component={renderTextField}
          className={classes.field}
          validate={[required, email]}
          type="text"
        />
        <div className={classes.passwordField}>
          <Field
            name={FieldName.PASSWORD}
            placeholder={t(PlaceHolder.PASSWORD)}
            component={renderTextField}
            className={classes.field}
            validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
            type={isPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton switchHandler={switchPasswordType} isPasswordHidden={isPasswordHidden} />
        </div>
        <div className={classes.buttonSection}>
          <AppButton className={classes.approveButton} type="submit" size={ButtonSize.LARGE_BUTTON}>
            {t("BESTÄTIGUNGLINK ANFORDERN")}
          </AppButton>
          <AppButton className={classes.discardButton} handler={onClose} size={ButtonSize.MEDIUM_BUTTON}>
            {t("ABBRECHEN")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default reduxForm<any, any>({ form: "changeEmail" })(ChangeEmailForm);
