import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { RejectAuthorizeVvvoActionType } from "./reject-authorize-vvvo.actions";

export interface RejectAuthorizeVvvoState {
  isRejected: boolean;
  loadStatus: LoadingStatus;
  lastReject: Date | undefined;
}

export const initialState = {
  isRejected: false,
  loadStatus: loadInitial(),
  lastReject: undefined,
};

export default createReducer(initialState, {
  [RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO](
    state: RejectAuthorizeVvvoState
  ): RejectAuthorizeVvvoState {
    return { ...state, loadStatus: loadPending() };
  },
  [RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO_SUCCESS](
    state: RejectAuthorizeVvvoState,
    action: Action<boolean>
  ): RejectAuthorizeVvvoState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      isRejected: action.payload,
      lastReject: new Date(Date.now()),
    };
  },
  [RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO_FAILURE](
    state: RejectAuthorizeVvvoState,
    action: Action<string>
  ): RejectAuthorizeVvvoState {
    return { ...state, loadStatus: loadFailed(action.payload), lastReject: new Date(Date.now()) };
  },
});
