import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ConfirmEmailActionType } from "./confirm-creation-change-email.actions";

export interface ConfirmEmailState {
  isCreated: boolean;
  isChanged: boolean;
  loadStatus: LoadingStatus;
}

export const initialState = {
  isCreated: false,
  isChanged: false,
  loadStatus: loadInitial(),
};

export default createReducer(initialState, {
  [ConfirmEmailActionType.CONFIRM_CREATION_EMAIL](state: ConfirmEmailState): ConfirmEmailState {
    return { ...state, loadStatus: loadPending() };
  },
  [ConfirmEmailActionType.CONFIRM_CREATION_EMAIL_SUCCESS](
    state: ConfirmEmailState,
    action: Action<boolean>
  ): ConfirmEmailState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      isCreated: action.payload,
    };
  },
  [ConfirmEmailActionType.CONFIRM_CREATION_EMAIL_FAILURE](
    state: ConfirmEmailState,
    action: Action<string>
  ): ConfirmEmailState {
    return { ...state, loadStatus: loadFailed(action.payload) };
  },
  [ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL](state: ConfirmEmailState): ConfirmEmailState {
    return { ...state, loadStatus: loadPending() };
  },
  [ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL_SUCCESS](
    state: ConfirmEmailState,
    action: Action<boolean>
  ): ConfirmEmailState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      isChanged: action.payload,
    };
  },
  [ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL_FAILURE](
    state: ConfirmEmailState,
    action: Action<string>
  ): ConfirmEmailState {
    return { ...state, loadStatus: loadFailed(action.payload) };
  },
});
