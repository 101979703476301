import { useState, FunctionComponent, useEffect } from "react";
import { IChecklisteDto } from "../../../api/backend-api-v1";
import EnterChecklistenNameContainer from "../enter-checklisten-name/enter-checklisten-name.container";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Paper,
  Dialog,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AppButton from "../../common/app-button/app-button";
import { Colors } from "../../../theme/colors";
import { LoadingStatus } from "../../../store/loading-status";

// Darstellung der Elemente in der Komponente
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: "flex",
      minWidth: "800px",
      minHeight: "360px",
      border: "2px solid black",
      flexDirection: "column",
      position: "relative",
    },
    dialogActions: {},
    formControl: {
      marginLeft: 0,
    },
    button: {
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    table: {
      minWidth: 650,
    },
    buttonWrapper: {
      borderRadius: "8px",
      height: "38px",
    },
    tableTitle: {
      color: Colors.Grey,
      paddingLeft: "16px",
      fontWeight: "bold",
    },
    rowName: {
      color: Colors.Grey,
      fontWeight: "bold",
    },
    cancelBtn: {
      position: "absolute",
      bottom: "20px",
      right: "20px",
      backgroundColor: Colors.Grey,
      "&:hover": {
        opacity: 0.75,
      },
    },
    circularProgress: {
      position: "absolute",
      top: "45%",
      left: "45%",
      color: Colors.DarkGreen,
    },
    hiddenCell: {
      borderBottom: "none",
    },
  })
);

export interface ConnectedState {
  checklisten: { [checklisteId: number]: IChecklisteDto };
  checklistenStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  loadChecklisten: () => void;
  navigateTo: (path: string) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {
  open: boolean;
  setOpen: (open: boolean) => void;
  betriebId: number;
  betriebName: string;
  betriebVvvo: string;
}

const AddChecklistenComponent: FunctionComponent<Props> = props => {
  const {
    open,
    setOpen,
    checklisten,
    checklistenStatus,
    loadChecklisten,
    betriebId,
    betriebName,
    betriebVvvo,
  } = props;
  const classes = useStyles();
  const [isEnterChecklistenNameOpen, setIsEnterChecklistenNameOpen] = useState(false);
  const [checklisteId, setChecklisteId] = useState(0);
  const { t } = useTranslation();

  const handleAbbrechenButton = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadChecklisten();
  }, [loadChecklisten]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ className: classes.dialog }}>
        <p className={classes.tableTitle}>{t("Bitte wählen Sie die gewünschte Checkliste aus:")}</p>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowName}>{t("Checkliste")}</TableCell>
              <TableCell className={classes.rowName}>{t("Beschreibung")}</TableCell>
              <TableCell className={classes.rowName}>{t("Version")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {checklistenStatus.pending ? (
              <TableRow>
                <TableCell className={classes.hiddenCell}>
                  <CircularProgress className={classes.circularProgress} size={40} />
                </TableCell>
              </TableRow>
            ) : (
              Object.values(checklisten).map(checkliste => (
                <TableRow key={checkliste.id}>
                  <TableCell>{checkliste.name}</TableCell>
                  <TableCell>{checkliste.description}</TableCell>
                  <TableCell>{checkliste.version}</TableCell>
                  <TableCell>
                    <Paper className={classes.buttonWrapper}>
                      {isEnterChecklistenNameOpen && (
                        <EnterChecklistenNameContainer
                          open={isEnterChecklistenNameOpen}
                          setOpen={setIsEnterChecklistenNameOpen}
                          betriebId={betriebId}
                          betriebName={betriebName}
                          betriebVvvo={betriebVvvo}
                          checklisteId={checklisteId}
                        />
                      )}
                      <AppButton
                        handler={() => {
                          setChecklisteId(checkliste.id!);
                          setIsEnterChecklistenNameOpen(true);
                        }}
                      >
                        {t("Anlegen")}
                      </AppButton>
                    </Paper>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <AppButton className={classes.cancelBtn} handler={() => handleAbbrechenButton()}>
          {t("ABBRECHEN")}
        </AppButton>
      </Dialog>
    </>
  );
};

export default AddChecklistenComponent;
