import { FunctionComponent, useEffect, useState } from "react";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import AppButton from "../../components/common/app-button/app-button";
import PrivacyPolicy from "../../components/modal-windows/privacy-policy/privacy-policy.component";
import Contacts from "../../components/modal-windows/contacts/contacts.component";
import Impressum from "../../components/modal-windows/impressum/impressum.component";
import TermsAndConditions from "../../components/modal-windows/terms-and-conditions/terms-and-conditions.component";
import { useStyles } from "./info.styles";
import { useTranslation } from "react-i18next";
// @ts-ignore
import licencesFile from "./licences.txt";
import { LoadingStatus } from "../../store/loading-status";
import PreLoader from "../../components/preloader/preloader.component";

export interface ConnectedDispatch {
  setCurrentTitle: (title: string) => void;
}

export interface ConnectedState {
  logoutStatus: LoadingStatus;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const InfoPage: FunctionComponent<Props> = ({ setCurrentTitle, logoutStatus }) => {
  const classes = useStyles();
  const [isContactsOpen, setIsContactsOpen] = useState(false);
  const [isImpressumOpen, setIsImpressumOpen] = useState(false);
  const [isAGBOpen, setIsAGBOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTitle(t("Informationen"));
  }, [setCurrentTitle, t]);

  const contactsHandler = () => {
    setIsContactsOpen(!isContactsOpen);
  };

  const impressumHandler = () => {
    setIsImpressumOpen(!isImpressumOpen);
  };

  const AGBHandler = () => {
    setIsAGBOpen(!isAGBOpen);
  };

  const privacyPolicyHandler = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  };

  if (logoutStatus.pending) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return (
    <div>
      <AppBarContainer />
      <div className={classes.flexWrapper}>
        <AppButton className={classes.button} handler={contactsHandler}>
          {t("Kontakt")}
        </AppButton>
        {isContactsOpen && <Contacts closeModal={contactsHandler} open={isContactsOpen} />}
        <AppButton className={classes.button} handler={privacyPolicyHandler}>
          {t("Datenschutzrichtlinien")}
        </AppButton>
        {isPrivacyPolicyOpen && (
          <PrivacyPolicy closeModal={privacyPolicyHandler} open={isPrivacyPolicyOpen} />
        )}
        <AppButton className={classes.button} handler={AGBHandler}>
          {t("AGB")}
        </AppButton>
        {isAGBOpen && <TermsAndConditions closeModal={AGBHandler} open={isAGBOpen} />}
        <AppButton className={classes.button} handler={impressumHandler}>
          {t("Impressum")}
        </AppButton>
        {isImpressumOpen && <Impressum closeModal={impressumHandler} open={isImpressumOpen} />}
        <a href={licencesFile} download={true} target={"_blank"} rel="noopener noreferrer" tabIndex={-1}>
          <AppButton className={classes.button}>{t("Lizenzhinweise")}</AppButton>
        </a>
      </div>
    </div>
  );
};

export default InfoPage;
