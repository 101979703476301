export interface IButtonSize {
  width: string;
}

export const ButtonSize = {
  SMALL_BUTTON: { width: "100px" },
  MEDIUM_BUTTON: { width: "200px" },
  LARGE_BUTTON: { width: "290px" },
  EXTRA_LARGE_BUTTON: { width: "340px" },
};
