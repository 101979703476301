import BackendClient from "../../api/backend-client";
import { ICreateUserRequestDto, ISendUserCreationEmailRequestDto, IUserDto } from "../../api/backend-api-v1";
import { takeLeading, takeEvery, put, call } from "redux-saga/effects";
import {
  saveUserSucces,
  saveUserFailure,
  RegistrationActionType,
  resendRegistrationEmailSuccess,
  resendRegistrationEmailFailure,
} from "./registration.actions";
import { Dispatch } from "redux";
import { Action } from "../action";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import { showFailedRequest } from "../notifications/notifications.actions";
import { openModalWindow } from "../common/common.actions";

let backendClient: BackendClient;
const logError = logger.error("authenticate.saga");

export function* createUser(action: Action<ICreateUserRequestDto>) {
  try {
    const user = action.payload;
    const response: IUserDto = yield call([backendClient, apiMethods.CreateUser], user);
    yield put(saveUserSucces(response));
    yield put(openModalWindow(true));
  } catch (e) {
    const errors = !!e.response && !!e.response.data && Object.values(e.response.data);
    if (errors) {
      yield put(showFailedRequest(errors));
    }

    if (e.hasOwnProperty("DuplicateEmail") || e.hasOwnProperty("DuplicateUserName")) {
      yield put(showFailedRequest("E-Mail Adresse bereits vorhanden."));
    }

    yield put(saveUserFailure(e.message));
    logError("Couldn't create user", e.message);
  }
}

export function* resendRegistrationEmail(action: Action<ISendUserCreationEmailRequestDto>) {
  try {
    yield call([backendClient, apiMethods.ResendUserRegistrationEmail], action.payload);
    yield put(resendRegistrationEmailSuccess());
  } catch (e) {
    logError(e.message);
    yield put(resendRegistrationEmailFailure(e.message));
  }
}

export default function* signUpSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(RegistrationActionType.SAVE_USER, createUser);
  yield takeLeading(RegistrationActionType.RESEND_REGISTRATION_EMAIL, resendRegistrationEmail);
}
