import { Action } from "../action";

export enum LogoutActionType {
  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",
}

export const logout = (): Action<null> => ({
  type: LogoutActionType.LOGOUT,
  payload: null,
});

export const logoutSuccess = (isLogout: boolean): Action<boolean> => ({
  type: LogoutActionType.LOGOUT_SUCCESS,
  payload: isLogout,
});

export const logoutFailure = (errorMessage: string): Action<string> => ({
  type: LogoutActionType.LOGOUT_FAILURE,
  payload: errorMessage,
});
