import ModalFailure from "../../common/modal-failure/modal-failure.component";
import I18n from "i18next";

const ChangePasswordFailure = () => {
  const title = I18n.t('"Passwort zurücksetzen" nicht möglich');
  const description = I18n.t("Das Passwort konnte nicht zurückgesetzt werden");
  return <ModalFailure title={title} description={description} />;
};

export default ChangePasswordFailure;
