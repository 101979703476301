import { useEffect } from "react";
import LocationService from "../../shared/services/location.service";
import RoutePaths from "../../routes/route-paths";
import { useDispatch } from "react-redux";
import { history } from "../../store/configureStore";
import { isDateExpired } from "../../utils/checkExpiresDate";
import { confirmCreationEmail } from "../../store/confirm-creation-change-email/confirm-creation-change-email.actions";

const ConfirmEmailContainer = () => {
  const locationService = LocationService.getInstance(history.location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    const expiresDate = locationService.getExpiresAtValue();
    const token = locationService.getToken();
    const userId = locationService.getUserId();
    if (isDateExpired(expiresDate)) {
      history.push(RoutePaths.RegistrationFailure);
    } else {
      history.push(RoutePaths.Login);
      dispatch(confirmCreationEmail({ token, userId }));
    }
  });
  return <div />;
};

export default ConfirmEmailContainer;
