import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

export const useStyles = makeStyles({
  formControl: {
    width: "30%",
  },
  inputClasses: {
    height: "40px",
    border: `1px solid ${Colors.Grey}`,
    borderRadius: "4px",
  },
  selectInput: {
    padding: "10px",
    color: Colors.Grey,
    backgroundColor: "none",
  },
});
