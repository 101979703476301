import { FunctionComponent, MouseEventHandler } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./vvvo-existing.styles";
import AppButton from "../../common/app-button/app-button";
import { ButtonSize } from "../../../shared/buttons-style";
import { IVvvoDto } from "../../../api/backend-api-v1";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

interface Props {
  open: boolean;
  closeModal: MouseEventHandler;
  vvvo: IVvvoDto;
}

const VvvoExistingModalWindow: FunctionComponent<Props> = ({ closeModal, open, vvvo }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Zugriff auf Betriebsstätte bereits vorhanden")}</Typography>
        <Typography className={classes.description}>
          {t("Zugriff auf Betriebsstätte bereits vorhanden - 2")}{" "}
          {t("VVVO-Nr. _PLACEHOLDER_")?.replace("_PLACEHOLDER_", `"${vvvo}"`)}
        </Typography>
        <Typography className={classes.description}>
          {t("Zugriff auf Betriebsstätte bereits vorhanden - 3")}
        </Typography>
        <div className={classes.buttonWrapper}>
          <AppButton size={ButtonSize.MEDIUM_BUTTON} handler={closeModal}>
            {t("BESTÄTIGEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default VvvoExistingModalWindow;
