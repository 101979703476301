import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./delete-business-premises.styles";
import AppButton from "../../common/app-button/app-button";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface ConnectedDispatch {
  deleteBetrieb: (betriebId: number) => void;
}

interface Props extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
  betriebId: number;
}

const DeleteBusinessPremises: FunctionComponent<Props> = ({ closeModal, open, betriebId, deleteBetrieb }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const deleteBetriebHandler = () => {
    deleteBetrieb(betriebId);
  };

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>
          {t("Möchten Sie die Betriebsstätte unwiderruflich löschen?")}
        </Typography>
        <div className={classes.buttons}>
          <AppButton className={classes.confirmButton} handler={deleteBetriebHandler}>
            {t("JA, LÖSCHEN")}
          </AppButton>
          <AppButton className={classes.cancelButton} handler={closeModal}>
            {t("ABBRECHEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default DeleteBusinessPremises;
