import { FunctionComponent } from "react";
import renderTextField from "../../../components/common/render-text-field/render-text-field";
import useStyles from "./contact-form.style";
import AppButton from "../../../components/common/app-button/app-button";
import ChangeContactDetailsContainer from "../../../components/modal-windows/change-contact-details/change-contact-details.container";
import { Field, reduxForm } from "redux-form";
import { PlaceHolder, FieldName, FieldLabel, UserGroupValue } from "../../../shared/field-data";
import { MaxLengths, MinLengths } from "../../../utils/authValidation/auth-validation";
import { ContactDetailsDto } from "../../contact-details/contact-details.component";
import { Typography, FormControl, RadioGroup, FormControlLabel } from "@material-ui/core";
import AppRadio from "../../../components/common/app-radio/app-radio.component";
import { useTranslation } from "react-i18next";

export interface ConnectedDispatch {
  toggleProfileDetailsModalWindow: (isOpen: boolean) => void;
}

export interface ConnectedState {
  isOpen: boolean;
  user: ContactDetailsDto;
}

interface Props extends ConnectedDispatch, ConnectedState {}

const radioValue: any = {
  [UserGroupValue.LANDWIRT]: "0",
  [UserGroupValue.BERATER]: "1",
  [UserGroupValue.TIERARZT]: "2",
};

const ProfileContactDetailsForm: FunctionComponent<Props> = ({
  isOpen,
  toggleProfileDetailsModalWindow,
  user,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openWindow = () => {
    toggleProfileDetailsModalWindow(true);
  };

  const closeWindow = () => {
    toggleProfileDetailsModalWindow(false);
  };

  return (
    <div>
      <form className={classes.flexWrapper}>
        <div className={classes.userInfo}>
          <div>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.NAME)}</label>
              <Field
                component={renderTextField}
                name={FieldName.NAME}
                placeholder={t(PlaceHolder.NAME)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.ADRESSE)}</label>
              <Field
                component={renderTextField}
                name={FieldName.ADRESSE}
                placeholder={t(PlaceHolder.ADRESSE)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.ORT)}</label>
              <Field
                component={renderTextField}
                name={FieldName.ORT}
                placeholder={t(PlaceHolder.ORT)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.VORNAME)}</label>
              <Field
                component={renderTextField}
                name={FieldName.VORNAME}
                placeholder={t(PlaceHolder.VORNAME)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.PLZ)}</label>
              <Field
                component={renderTextField}
                name={FieldName.PLZ}
                placeholder={t(PlaceHolder.PLZ)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label>{t(FieldLabel.LAND)}</label>
              <Field
                component={renderTextField}
                name={FieldName.LAND}
                placeholder={t(PlaceHolder.LAND)}
                className={classes.field}
                disabled={true}
                type="text"
              />
            </div>
          </div>
        </div>
        <div className={classes.nutzergruppe}>
          <Typography className={classes.formDescription}> {t("Nutzergruppe")}</Typography>
          <div className={classes.userGroupInfo}>
            <div className={classes.radioGroup}>
              <FormControl>
                <RadioGroup>
                  <div className={classes.checkboxWrapper}>
                    <FormControlLabel
                      value={FieldName.LANDWIRT}
                      control={<AppRadio />}
                      label={t(FieldLabel.LANDWIRT)}
                      disabled={UserGroupValue.LANDWIRT !== radioValue[user.userGroup]}
                      checked={UserGroupValue.LANDWIRT === radioValue[user.userGroup]}
                    />
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <FormControlLabel
                      value={FieldName.BERATER}
                      control={<AppRadio />}
                      label={t(FieldLabel.BERATER)}
                      disabled={UserGroupValue.BERATER !== radioValue[user.userGroup]}
                      checked={UserGroupValue.BERATER === radioValue[user.userGroup]}
                    />
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <FormControlLabel
                      value={FieldName.TIERARZT}
                      control={<AppRadio />}
                      label={t(FieldLabel.TIERARZT)}
                      disabled={UserGroupValue.TIERARZT !== radioValue[user.userGroup]}
                      checked={UserGroupValue.TIERARZT === radioValue[user.userGroup]}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.company}>
              <Field
                component={renderTextField}
                name={FieldName.COMPANY_BERATER}
                placeholder={t(PlaceHolder.UNTERNEHMEN)}
                className={classes.adviserField}
                validate={[MinLengths.LENGTH_3, MaxLengths.LENGTH_50]}
                disabled={true}
                type="text"
              />
              <Field
                component={renderTextField}
                name={FieldName.COMPANY_TIERARZT}
                placeholder={t(PlaceHolder.UNTERNEHMEN)}
                className={classes.vetField}
                validate={[MinLengths.LENGTH_3, MaxLengths.LENGTH_50]}
                disabled={true}
                type="text"
              />
            </div>
          </div>
        </div>
      </form>
      <div className={classes.submitButton}>
        <AppButton handler={openWindow}>{t("ÄNDERN")}</AppButton>
      </div>
      {isOpen && <ChangeContactDetailsContainer closeWindow={closeWindow} open={isOpen} />}
    </div>
  );
};
export default reduxForm<any, any>({ form: "profileContactDetails", enableReinitialize: true })(
  ProfileContactDetailsForm
);
