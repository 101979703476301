import { FunctionComponent } from "react";
import renderTextField from "../../components/common/render-text-field/render-text-field";
import useStyles from "./contact-details-form.styles";
import AppButton from "../../components/common/app-button/app-button";
import AppRadioGroup from "../../components/common/app-radio-group/app-radio-group.component";
import { Field, reduxForm, SubmitHandler } from "redux-form";
import { FormName } from "../../shared/form-name";
import { PlaceHolder, FieldName, FieldLabel } from "../../shared/field-data";
import {
  required,
  MaxLengths,
  MinLengths,
  requiredUsergroup,
} from "../../utils/authValidation/auth-validation";
import { Typography } from "@material-ui/core";
import { ContactDetailsDto } from "../../pages/contact-details/contact-details.component";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "../../shared/buttons-style";

interface Props {
  handleSubmit: SubmitHandler;
  userGroupDescription: string;
  fields: ContactDetailsDto;
  buttonName: string;
  submitButtonStyle?: object;
  resetField: (form: string, field: string, value: string) => void;
}

const ContactDetailsForm: FunctionComponent<Props> = ({
  handleSubmit,
  userGroupDescription,
  buttonName,
  fields,
  resetField,
  submitButtonStyle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const cleanField = (fieldName: string) => {
    switch (fieldName) {
      case "0":
        resetField("contactDetails", "companyBerater", "");
        resetField("contactDetails", "companyTierarzt", "");
        break;
      case "1":
        resetField("contactDetails", "companyTierarzt", "");
        break;
      case "2":
        resetField("contactDetails", "companyBerater", "");
        break;
      default:
        break;
    }
  };
  const field = (tabIndex: number, name: string, placeholder: string) => (
    <Field
      component={renderTextField}
      name={name}
      placeholder={placeholder}
      className={classes.field}
      validate={[required, MinLengths.LENGTH_3, MaxLengths.LENGTH_50]}
      type="text"
      tabIndex={tabIndex}
    />
  );

  return (
    <form onSubmit={handleSubmit} className={classes.flexWrapper}>
      <div className={classes.userInfo}>
        <div>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.NAME)}</label>
            {field(1, FieldName.NAME, t(PlaceHolder.NAME))}
          </div>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.ADRESSE)}</label>
            {field(3, FieldName.ADRESSE, t(PlaceHolder.ADRESSE))}
          </div>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.ORT)}</label>
            {field(5, FieldName.ORT, t(PlaceHolder.ORT))}
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.VORNAME)}</label>
            {field(2, FieldName.VORNAME, t(PlaceHolder.VORNAME))}
          </div>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.PLZ)}</label>
            {field(4, FieldName.PLZ, t(PlaceHolder.PLZ))}
          </div>
          <div className={classes.fieldWrapper}>
            <label>{t(FieldLabel.LAND)}</label>
            {field(6, FieldName.LAND, t(PlaceHolder.LAND))}
          </div>
        </div>
      </div>
      <div className={classes.formDescription}>
        <Typography>{userGroupDescription}</Typography>
      </div>
      <div className={classes.userInfo}>
        <div>
          <Field
            name={FieldName.USERGROUP}
            component={AppRadioGroup}
            validate={[requiredUsergroup]}
            clickHandler={cleanField}
          />
        </div>
        <div className={classes.company}>
          <Field
            component={renderTextField}
            name={FieldName.COMPANY_BERATER}
            placeholder={t(PlaceHolder.UNTERNEHMEN)}
            className={classes.adviserField}
            validate={[MinLengths.LENGTH_3, MaxLengths.LENGTH_50]}
            formName={FormName.CONTACT_DETAILS_FORM}
            disabled={fields && fields.userGroup === FieldName.BERATER ? false : true}
            type="text"
          />
          <Field
            component={renderTextField}
            name={FieldName.COMPANY_TIERARZT}
            placeholder={t(PlaceHolder.UNTERNEHMEN)}
            className={classes.vetField}
            validate={[MinLengths.LENGTH_3, MaxLengths.LENGTH_50]}
            formName={FormName.CONTACT_DETAILS_FORM}
            disabled={fields && fields.userGroup === FieldName.TIERARZT ? false : true}
            type="text"
          />
        </div>
      </div>
      <div className={classes.submitButton} style={submitButtonStyle}>
        <AppButton size={ButtonSize.MEDIUM_BUTTON} type="submit">
          {t("ÜBERNEHMEN")}
        </AppButton>
      </div>
    </form>
  );
};

export default reduxForm<any, any>({ form: "contactDetails", enableReinitialize: true })(ContactDetailsForm);
