import SuccessfulRegistrationComponent, { ConnectedDispatch } from "./successful-registration.component";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Dispatch } from "redux";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(SuccessfulRegistrationComponent);
