import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: "100px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16pt",
    color: Colors.Grey,
  },
  description: {
    margin: "20px 0",
    width: "80%",
    fontSize: "14pt",
    color: Colors.Grey,
  },
}));

export default useStyles;
