import RoutePaths from "../../../routes/route-paths";
import TransitionsModal from "../../common/modal/modal-window";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { FunctionComponent, useState, useRef, useEffect, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../common/app-button/app-button";
import { Colors } from "../../../theme/colors";

export interface ConnectedDispatch {
  navigateTo: (path: string) => void;
  showMissingName: () => void;
}

interface Props extends ConnectedDispatch {
  open: boolean;
  setOpen: (open: boolean) => void;
  betriebId: number;
  betriebName: string;
  betriebVvvo: string;
  checklisteId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    btnGroup: {
      display: "flex",
      justifyContent: "center",
      marginTop: "15px",
    },
    okButton: {
      marginRight: "15px",
    },
    cancelButton: {
      marginLeft: "15px",
      backgroundColor: Colors.Grey,
      "&:hover": {
        backgroundColor: Colors.Grey,
      },
    },
    inputField: {
      height: "40px",
      width: "400px",
      borderRadius: "4px",
      border: "1px solid black",
      fontSize: "14pt",
      "&:focus": {
        outline: "none",
      },
    },
    title: {
      fontSize: "14pt",
      color: Colors.Grey,
      fontWeight: "bold",
    },
  })
);

const EnterChecklistenNameComponent: FunctionComponent<Props> = props => {
  const {
    open,
    setOpen,
    navigateTo,
    betriebId,
    betriebName,
    betriebVvvo,
    checklisteId,
    showMissingName,
  } = props;
  const [checklistenEingabeName, setChecklistenEingabeName] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();

  const inputRef: MutableRefObject<any> = useRef();

  useEffect(() => {
    const setFocus = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(setFocus);
    };
  });

  const handleOkButton = (enteredChecklistenEingabeName: string | null) => {
    if (enteredChecklistenEingabeName === "") {
      showMissingName();
      return;
    }

    navigateTo(
      `${RoutePaths.Checkliste}/${checklisteId}/${betriebId}/${betriebName}/${betriebVvvo}/${enteredChecklistenEingabeName}`
    );
  };

  return (
    <>
      {open && (
        <TransitionsModal open={open} closeModal={() => setOpen(false)} backdropBackground={open}>
          <div className={classes.wrapper}>
            <p className={classes.title}>{t("Checklistennamen eingeben")}</p>
            <input
              className={classes.inputField}
              id="checklistenName"
              placeholder={t("Name")}
              ref={inputRef}
              onChange={e => setChecklistenEingabeName(e.target.value)}
            />
            <div className={classes.btnGroup}>
              <AppButton className={classes.okButton} handler={() => handleOkButton(checklistenEingabeName)}>
                {t("BESTÄTIGEN")}
              </AppButton>
              <AppButton className={classes.cancelButton} handler={() => setOpen(false)}>
                {t("ABBRECHEN")}
              </AppButton>
            </div>
          </div>
        </TransitionsModal>
      )}
    </>
  );
};
export default EnterChecklistenNameComponent;
