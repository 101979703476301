import Profile from "./profile.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ConnectedDispatch } from "./profile.component";
import { setCurrentTitle } from "../../store/common/common.actions";
import { loadContactDetails } from "../../store/contact-details/contact-details.actions";
import { getProfileStatus } from "../../store/contact-details/contact-details.selectors";
import { AppState } from "../../store/app.state";
import { getChangeEmailStatus } from "../../store/change-email/change-email.selector";
import { getChangePasswordStatus } from "../../store/change-password/change-password.selector";
import { getLogoutStatus } from "../../store/logout/logout.selectors";

const mapStateToProps = (state: AppState) => ({
  profileStatus: getProfileStatus(state),
  changeEmailStatus: getChangeEmailStatus(state),
  changePasswordStatus: getChangePasswordStatus(state),
  logoutStatus: getLogoutStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setCurrentTitle: (title: string) => dispatch(setCurrentTitle(title)),
  setContactDetails: () => dispatch(loadContactDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
