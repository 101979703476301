import { Action } from "../action";

export enum ResetPasswordActionType {
  SUBMIT_EMAIL = "SUBMIT_EMAIL",
  SUBMIT_EMAIL_SUCCESS = "SUBMIT_EMAIL_SUCCESS",
  SUBMIT_EMAIL_FAILURE = "SUBMIT_EMAIL_FAILURE",
}

export const submitEmail = (email: string): Action<string> => ({
  type: ResetPasswordActionType.SUBMIT_EMAIL,
  payload: email,
});

export const submitEmailSuccess = (): Action<null> => ({
  type: ResetPasswordActionType.SUBMIT_EMAIL_SUCCESS,
  payload: null,
});

export const submitEmailFailure = (errorMessage: string): Action<string> => ({
  type: ResetPasswordActionType.SUBMIT_EMAIL_FAILURE,
  payload: errorMessage,
});
