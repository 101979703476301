import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import RoutePaths from "../../routes/route-paths";
import I18n from "i18next";
import {
  ContactDetailsActionType,
  saveContactDetailsSuccess,
  loadContactDetailsSuccess,
  loadContactDetailsFailure,
  saveContactDetailsFailure,
} from "./contact-details.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { takeLeading, put, call } from "redux-saga/effects";
import { Dispatch } from "redux";
import { Action } from "../action";
import { history } from "../../store/configureStore";
import { ContactDetailsDto } from "../../pages/contact-details/contact-details.component";
import { UserGroupValue } from "../../shared/field-data";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import { IUserProfileDto } from "../../api/backend-api-v1";
import AuthService from "../../shared/services/auth.service";

let backendClient: BackendClient;
const logError = logger.error("contactDetails.saga");
let authService: AuthService;

const changeUserObj = (data: ContactDetailsDto) => {
  const { company, userGroup, ...rest } = data;
  switch (userGroup) {
    case UserGroupValue.BERATER:
      return { ...rest, userGroup, companyBerater: company };
    case UserGroupValue.TIERARZT:
      return { ...rest, userGroup, companyTierarzt: company };
    case UserGroupValue.LANDWIRT:
      return { ...rest, userGroup, company: "" };
    default:
      return data;
  }
};

export function* loadContactDetails() {
  try {
    const userProfile: ContactDetailsDto = yield call([backendClient, apiMethods.GetUserProfile]);
    const changedUserObj = changeUserObj(userProfile);
    yield put(loadContactDetailsSuccess(changedUserObj));
  } catch (e) {
    logError("Could not fetch user", e.message);
    yield put(loadContactDetailsFailure(`Contact details error: ${e.message}`));
  }
}

export function* saveData(action: Action<IUserProfileDto>) {
  try {
    yield call([backendClient, apiMethods.PutContactDetails], action.payload);
    yield put(saveContactDetailsSuccess(action.payload));
    authService.setFirstLogin(false);
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_SAVE_CONTACT_DETAILS_DATA)));
    if (history.location.pathname !== RoutePaths.Profile) {
      history.push(RoutePaths.Betriebe);
    }
  } catch (e) {
    logError("Could not save data", e.message);
    yield put(saveContactDetailsFailure(e.message));
    if (e.message === FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA_WITHOUT_NETWORK));
    } else {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA)));
    }
  }
}

export default function* contactDetailsSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  authService = AuthService.getInstance();
  yield takeLeading(ContactDetailsActionType.LOAD_CONTACT_DETAILS, loadContactDetails);
  yield takeLeading(ContactDetailsActionType.SAVE_CONTACT_DETAILS, saveData);
}
