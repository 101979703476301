import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import iqImages from "../../images/iq-images";

const useStyles = makeStyles(() => ({
  partnerSection: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  leftedPartners: {
    alignSelf: "center",
  },
  prtblLogo: {
    height: "130px",
    paddingLeft: "20px",
  },
  img: {
    height: "85px",
    paddingLeft: "20px",
  },
}));

const PartnerSection: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.partnerSection}>
      <div className={classes.leftedPartners}>
        <img className={classes.img} src={iqImages.fbf} alt="FBF logo" />
        <img className={classes.img} src={iqImages.agrar} alt="IQ-Agrar logo" />
        <img className={classes.img} src={iqImages.isn} alt="ISN logo" />
      </div>
      <img className={classes.prtblLogo} src={iqImages.prtbl} alt="prtbl logo" />
    </div>
  );
};

export default PartnerSection;
