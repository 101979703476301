import { FunctionComponent } from "react";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  eyeIcon: {
    position: "absolute",
    top: "13px",
    right: "5px",
  },
}));

interface Props {
  switchHandler: () => void;
  isPasswordHidden: boolean;
}

const ShowPasswordButton: FunctionComponent<Props> = ({ switchHandler, isPasswordHidden }) => {
  const classes = useStyles();
  return (
    <IconButton size="small" className={classes.eyeIcon} onClick={switchHandler}>
      {isPasswordHidden ? (
        <VisibilityOffOutlinedIcon style={{ color: Colors.Grey }} />
      ) : (
        <VisibilityOutlinedIcon style={{ color: Colors.Grey }} />
      )}
    </IconButton>
  );
};

export default ShowPasswordButton;
