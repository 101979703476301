import { MouseEventHandler, useState } from "react";
import BetriebStaetteAuth, { ConnectedDispatch, ConnectedState } from "./betriebstaette-auth.component";
import { IBetriebDto, VvvoDto } from "../../api/backend-api-v1";
import { connect } from "react-redux";
import {
  authorizeNewBetriebVVVO,
  checkVvvo,
  authorizeExistingBetriebVVVO,
  resetBetriebState,
  toggleExistingVvvoModalWindow,
} from "../../store/betriebstaette-auth/betriebstaette-auth.actions";
import { getFormValues, reset, change } from "redux-form";
import { getModalWindow } from "../../store/common/common.selectors";
import { openModalWindow, setCurrentTitle } from "../../store/common/common.actions";
import {
  getExistingVvvoModalWindow,
  getBetriebExists,
  getExistedEmail,
  getAuthorizationExists,
  getAuthorizationConfirmed,
  getBetriebstaetteStatus,
} from "../../store/betriebstaette-auth/betriebstaette-auth.selectors";
import { AppState } from "../../store/app.state";
import { Dispatch } from "redux";
import { getLogoutStatus } from "../../store/logout/logout.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  fields: getFormValues("betriebstaetteAuth")(state),
  isOpen: getModalWindow(state),
  isExistingVvvoOpen: getExistingVvvoModalWindow(state),
  isBetriebExists: getBetriebExists(state),
  existedEmail: getExistedEmail(state),
  isAuthorizationExists: getAuthorizationExists(state),
  isAuthorizationConfirmed: getAuthorizationConfirmed(state),
  betriebstaetteStatus: getBetriebstaetteStatus(state),
  logoutStatus: getLogoutStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setCurrentTitle: (title: string) => dispatch(setCurrentTitle(title)),
  openModalWindow: (isOpen: boolean) => dispatch(openModalWindow(isOpen)),
  resetBetriebState: (resetFlag: boolean) => dispatch(resetBetriebState(resetFlag)),
  toggleExistingVvvoModalWindow: (isOpen: boolean) => dispatch(toggleExistingVvvoModalWindow(isOpen)),
  resetForm: (formName: string) => dispatch(reset(formName)),
  changeVvvo: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
  authorizeNewBetriebVVVO: (modifiedBetrieb: IBetriebDto) =>
    dispatch(authorizeNewBetriebVVVO(modifiedBetrieb)),
  authorizeExistingBetriebVVVO: (modifiedBetrieb: IBetriebDto) =>
    dispatch(authorizeExistingBetriebVVVO(modifiedBetrieb)),
  checkVvvo: (vvvo: string) => dispatch(checkVvvo(vvvo)),
});

const VVVO_LENGTH = 15;

const BetriebStaetteAuthContainer = (props: any) => {
  const [isUnauthorizeBetrieb, setIsUnauthorizeBetrieb] = useState(false);
  const vvvo = !!props.fields && props.fields.vvvo;

  const submitFormHandler: any = (betrieb: IBetriebDto) => {
    const modifiedBetrieb: IBetriebDto = {
      ...betrieb,
      vvvo: new VvvoDto({ value: vvvo }),
    };

    const isAutorizatedBetrieb = props.isAuthorizationConfirmed && props.isAuthorizationExists;
    if (!props.isBetriebExists && !isAutorizatedBetrieb) {
      props.authorizeNewBetriebVVVO(modifiedBetrieb);
    }

    if (props.isBetriebExists && !isAutorizatedBetrieb) {
      props.authorizeExistingBetriebVVVO(modifiedBetrieb);
    }

    return;
  };

  const submitVvvoHandler: MouseEventHandler = () => {
    if (!!vvvo && vvvo.length === VVVO_LENGTH) {
      props.checkVvvo(vvvo);
      setIsUnauthorizeBetrieb(false);
    }

    const isAuthorizedBetrieb = props.isAuthorizationExists && props.isAuthorizationConfirmed;
    if (props.isBetriebExists && !isAuthorizedBetrieb) {
      setIsUnauthorizeBetrieb(true);
    }
  };

  return (
    <BetriebStaetteAuth
      onSubmit={submitFormHandler}
      handleVvvoPrufen={submitVvvoHandler}
      vvvo={vvvo}
      isUnauthorizeBetrieb={isUnauthorizeBetrieb}
      {...props}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetriebStaetteAuthContainer);
