import { FunctionComponent } from "react";
import TextField from "@material-ui/core/TextField";
import { WrappedFieldMetaProps, CommonFieldInputProps } from "redux-form";
import { FormName } from "../../../shared/form-name";
import I18n from "i18next";

export interface Props {
  input: CommonFieldInputProps;
  touched: boolean | string;
  error?: any;
  meta: WrappedFieldMetaProps;
  disabled?: boolean;
  formName?: string;
  tabIndex?: number;
}

interface HelperProps {
  helper: object;
}

interface DisabledField {
  DISABLED_BETRIEBSTATTE_PLACEHOLDER: string;
  DISABLED_NUTZEGRUPPE_PLACEHOLDER: string;
}

const disabledField: DisabledField = {
  DISABLED_BETRIEBSTATTE_PLACEHOLDER: "Daten vorhanden",
  DISABLED_NUTZEGRUPPE_PLACEHOLDER: "",
};

const placeholder: any = {
  [FormName.BETRIEBSTATTE_FORM]: disabledField.DISABLED_BETRIEBSTATTE_PLACEHOLDER,
  [FormName.CONTACT_DETAILS_FORM]: disabledField.DISABLED_NUTZEGRUPPE_PLACEHOLDER,
};

const renderTextField: FunctionComponent<Props> = ({
  input,
  meta: { touched, error, invalid },
  disabled,
  formName,
  tabIndex,
  ...custom
}) => {
  const styles: HelperProps = {
    helper: { position: "absolute", top: "35px" },
  };
  return (
    <TextField
      variant="outlined"
      margin="dense"
      size="medium"
      error={touched && invalid}
      helperText={touched && error}
      FormHelperTextProps={{ style: styles.helper }}
      disabled={disabled}
      label={disabled && !!formName && I18n.t(placeholder[formName])}
      {...input}
      {...custom}
      InputProps={{ inputProps: { tabIndex } }}
    />
  );
};
export default renderTextField;
