import { Action } from "../action";
import { IChecklisteDto } from "../../api/backend-api-v1";

export interface ChecklistenEntities {
  checklisten: { [checklisteId: number]: IChecklisteDto };
}

export enum ChecklistenActionType {
  LOAD_CHECKLISTEN = "LOAD_CHECKLISTEN",
  LOAD_CHECKLISTEN_SUCCESS = "LOAD_CHECKLISTEN_SUCCESS",
  LOAD_CHECKLISTEN_FAILURE = "LOAD_CHECKLISTEN_FAILURE",
  SAVE_CHECKLISTE = "SAVE_CHECKLISTE",
  SAVE_CHECKLISTE_SUCCESS = "SAVE_CHECKLISTE_SUCCESS",
  SAVE_CHECKLISTE_FAILURE = "SAVE_CHECKLISTE_FAILURE",
}

export const loadChecklisten = (): Action<null> => ({
  type: ChecklistenActionType.LOAD_CHECKLISTEN,
  payload: null,
});

export const loadChecklistenSuccess = (entities: ChecklistenEntities): Action<ChecklistenEntities> => ({
  type: ChecklistenActionType.LOAD_CHECKLISTEN_SUCCESS,
  payload: entities,
});

export const loadChecklistenFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenActionType.LOAD_CHECKLISTEN_FAILURE,
  payload: errorMessage,
});

export const saveCheckliste = (): Action<null> => ({
  // TODO Checkliste als Parameter und dem Payload hinzufügen
  type: ChecklistenActionType.SAVE_CHECKLISTE,
  payload: null,
});

export const saveChecklisteSuccess = (checkliste: ChecklistenEntities): Action<ChecklistenEntities> => ({
  type: ChecklistenActionType.SAVE_CHECKLISTE_SUCCESS,
  payload: checkliste,
});

export const saveChecklisteFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenActionType.SAVE_CHECKLISTE_FAILURE,
  payload: errorMessage,
});
