import { Action } from "../action";

export enum ChangeConfirmPasswordActionType {
  SEND_CONFIRM_PASSWORD = "SEND_CONFIRM_PASSWORD",
  SEND_CONFIRM_PASSWORD_SUCCESS = "SEND_CONFIRM_PASSWORD_SUCCESS",
  SEND_CONFIRM_PASSWORD_FAILURE = "SEND_CONFIRM_PASSWORD_FAILURE",
}

export interface ChangeConfirmPasswordDto {
  userId: string;
  token: string;
  password: string;
  confirmPassword: string | undefined;
}

export const sendConfirmPassword = (data: ChangeConfirmPasswordDto): Action<ChangeConfirmPasswordDto> => ({
  type: ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD,
  payload: data,
});

export const sendConfirmPasswordSuccess = (): Action<null> => ({
  type: ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD_SUCCESS,
  payload: null,
});

export const sendConfirmPasswordFailure = (errorMessage: string): Action<string> => ({
  type: ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD_FAILURE,
  payload: errorMessage,
});
