import { Action } from "../action";

export enum ErrorActionType {
  ERROR_OCCURRED = "ERROR_OCCURRED",
}

export const errorOccurred = (): Action<null> => ({
  type: ErrorActionType.ERROR_OCCURRED,
  payload: null,
});
