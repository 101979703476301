import ResendRegistrationEmailComponent, {
  ConnectedState,
  ConnectedDispatch,
} from "./resend-registration-email.component";
import { resendRegistrationEmail } from "../../../store/registration/registration.actions";
import { getTranslationsStatus } from "../../../store/i18n/i18n.selectors";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../store/app.state";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  resendRegistrationEmail: (userId: string) => dispatch(resendRegistrationEmail({ userId })),
});

const mapStateToProps = (state: AppState): ConnectedState => ({
  translationsStatus: getTranslationsStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendRegistrationEmailComponent);
