import { Action } from "../action";
import { ContactDetailsDto } from "../../pages/contact-details/contact-details.component";

export enum ContactDetailsActionType {
  SAVE_CONTACT_DETAILS = "SAVE_CONTACT_DETAILS",
  SAVE_CONTACT_DETAILS_SUCCESS = "SAVE_CONTACT_DETAILS_SUCCESS",
  SAVE_CONTACT_DETAILS_FAILURE = "SAVE_CONTACT_DETAILS_FAILURE",
  LOAD_CONTACT_DETAILS = "LOAD_CONTACT_DETAILS",
  LOAD_CONTACT_DETAILS_SUCCESS = "LOAD_CONTACT_DETAILS_SUCCESS",
  LOAD_CONTACT_DETAILS_FAILURE = "LOAD_CONTACT_DETAILS_FAILURE",
  TOGGLE_CONTACT_DETAILS_MODAL_WINDOW = "TOGGLE_CONTACT_DETAILS_MODAL_WINDOW",
}

export const loadContactDetails = (): Action<null> => ({
  type: ContactDetailsActionType.LOAD_CONTACT_DETAILS,
  payload: null,
});

export const loadContactDetailsSuccess = (user: ContactDetailsDto): Action<ContactDetailsDto> => ({
  type: ContactDetailsActionType.LOAD_CONTACT_DETAILS_SUCCESS,
  payload: user,
});

export const loadContactDetailsFailure = (message: string): Action<string> => ({
  type: ContactDetailsActionType.LOAD_CONTACT_DETAILS_FAILURE,
  payload: message,
});

export const saveContactDetails = (contactDetails: ContactDetailsDto): Action<ContactDetailsDto> => ({
  type: ContactDetailsActionType.SAVE_CONTACT_DETAILS,
  payload: contactDetails,
});

export const saveContactDetailsSuccess = (contactDetails: ContactDetailsDto): Action<ContactDetailsDto> => ({
  type: ContactDetailsActionType.SAVE_CONTACT_DETAILS_SUCCESS,
  payload: contactDetails,
});

export const saveContactDetailsFailure = (message: string): Action<string> => ({
  type: ContactDetailsActionType.SAVE_CONTACT_DETAILS_FAILURE,
  payload: message,
});

export const toggleProfileDetailsModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: ContactDetailsActionType.TOGGLE_CONTACT_DETAILS_MODAL_WINDOW,
  payload: isOpen,
});
