import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "16pt",
    color: Colors.Grey,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "-40px",
  },
  cancelButton: {
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
  },
});

export default useStyles;
