import { Action } from "../action";
import { IConfirmUserVvvoAuthorizationRequestDto } from "../../api/backend-api-v1";

export enum ConfirmAuthorizeVvvoActionType {
  CONFIRM_AUTHORIZE_VVVO = "CONFIRM_AUTHORIZE_VVVO",
  CONFIRM_AUTHORIZE_VVVO_SUCCESS = "CONFIRM_AUTHORIZE_VVVO_SUCCESS",
  CONFIRM_AUTHORIZE_VVVO_FAILURE = "CONFIRM_AUTHORIZE_VVVO_FAILURE",
}

export const confirmAuthorizeVvvo = (
  data: IConfirmUserVvvoAuthorizationRequestDto
): Action<IConfirmUserVvvoAuthorizationRequestDto> => ({
  type: ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO,
  payload: data,
});

export const confirmAuthorizeVvvoSuccess = (isSentMail: boolean): Action<boolean> => ({
  type: ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO_SUCCESS,
  payload: isSentMail,
});

export const confirmAuthorizeVvvoFailure = (errorMessage: string): Action<string> => ({
  type: ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO_FAILURE,
  payload: errorMessage,
});
