import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
  },
  formDescription: {
    marginRight: "20px",
    marginTop: "10px",
  },
  userInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  userGroupInfo: {
    display: "flex",
    width: "72%",
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "350px",
    marginTop: "15px",
  },
  field: {
    width: "250px",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    marginBottom: "10px",
  },
  rightColumn: {
    marginLeft: "60px",
  },
  company: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  adviserField: {
    width: "250px",
    marginLeft: "40px",
    marginBottom: "10px",
  },
  vetField: {
    width: "250px",
    marginLeft: "40px",
  },
  submitButton: {
    display: "flex",
    justifyContent: "flex-end",
    width: "96%",
    paddingTop: "20px",
  },
  cancelButton: {
    position: "absolute",
    right: "250px",
  },
  title: {
    color: Colors.Grey,
    textAlign: "center",
  },
  nutzergruppe: {
    display: "flex",
    justifyContent: "start",
    width: "100%",
    marginTop: "15px",
  },
  radioGroup: {
    marginTop: "5px",
  },
}));

export default useStyles;
