import { Colors } from "../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: " 0 auto",
    overflowY: "hidden",
    color: Colors.Grey,
  },
  description: {
    width: "705px",
    marginBottom: "15px",
    alignSelf: "center",
    textAlign: "center",
  },
  field: {
    width: "340px",
    marginBottom: "15px",
  },
  logo: {
    height: "200px",
  },
  button: {
    marginTop: "10px",
  },
  links: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    marginBottom: "10px",
  },
  passwordField: {
    position: "relative",
  },
  languageMenu: {
    position: "fixed",
    top: 0,
    right: 0,
  },
}));

export default useStyles;
