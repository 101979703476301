import { Action } from "../action";

export enum ChangePasswordActionType {
  SEND_PASSWORD = "SEND_PASSWORD",
  SEND_PASSWORD_SUCCESS = "SEND_PASSWORD_SUCCESS",
  SEND_PASSWORD_FAILURE = "SEND_PASSWORD_FAILURE",
  TOGGLE_PASSWORD_MODAL_WINDOW = "TOGGLE_PASSWORD_MODAL_WINDOW",
}

export interface ChangePasswordDto {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const sendPassword = (data: ChangePasswordDto): Action<ChangePasswordDto> => ({
  type: ChangePasswordActionType.SEND_PASSWORD,
  payload: data,
});

export const sendPasswordSuccess = (): Action<null> => ({
  type: ChangePasswordActionType.SEND_PASSWORD_SUCCESS,
  payload: null,
});

export const sendPasswordFailure = (errorMessage: string): Action<string> => ({
  type: ChangePasswordActionType.SEND_PASSWORD_FAILURE,
  payload: errorMessage,
});

export const togglePasswordModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: ChangePasswordActionType.TOGGLE_PASSWORD_MODAL_WINDOW,
  payload: isOpen,
});
