import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import CloseButton from "../../common/close-button/close-button.component";
import useStyles from "./cookies.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const Cookies: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open} sizeWidth="900px">
      <CloseButton closeModal={closeModal} />
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Cookies")}</Typography>
        <div>
          <Typography className={classes.description}>
            {t("Text - Cookies")}
            <br />
            {t("Text - Cookies 2")}
          </Typography>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default Cookies;
