import { useEffect, FunctionComponent } from "react";
import LocationService from "../../shared/services/location.service";
import RoutePaths from "../../routes/route-paths";
import { isDateExpired } from "../../utils/checkExpiresDate";
import { history } from "../../store/configureStore";
import { FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";
import { LoadingStatus } from "../../store/loading-status";

export interface ConnectedState {
  translationsStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  confirmChangeEmail: (token: string, userId: string, newEmail: string) => void;
  showFailedRequest: (message: string) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const ConfirmChangeEmail: FunctionComponent<Props> = ({
  confirmChangeEmail,
  translationsStatus,
  showFailedRequest,
}) => {
  const locationService = LocationService.getInstance(history.location.search);

  useEffect(() => {
    // TODO: It's a quick and dirty solution, we need to wait until translations will be uploaded for preventing unnecessary rendering.
    if (translationsStatus.success) {
      const expiresDate = locationService.getExpiresAtValue();
      const token = locationService.getToken();
      const userId = locationService.getUserId();
      const newEmail = locationService.getEmail();
      if (isDateExpired(expiresDate)) {
        history.push(RoutePaths.Login);
        showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_CONFIRM_CHANGE_EMAIL));
      } else {
        confirmChangeEmail(token, userId, newEmail);
      }
    }
  });

  return <div />;
};

export default ConfirmChangeEmail;
