import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, makeStyles } from "@material-ui/core";
import { Colors } from "../../theme/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "45%",
    left: "45%",
  },
  circularProgress: {
    color: Colors.DarkGreen,
  },
  description: {
    fontSize: "16pt",
    color: Colors.Grey,
    marginTop: "10px",
  },
}));

const Preloader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flexWrapper}>
      <CircularProgress className={classes.circularProgress} size={60} />
      <Typography className={classes.description}> {t("Seite wird geladen...")}</Typography>
    </div>
  );
};

export default Preloader;
