import { FunctionComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: "sticky",
    width: "50px",
    top: "0",
    float: "right",
  },
}));

interface Props {
  closeModal: () => void;
}

const CloseButton: FunctionComponent<Props> = ({ closeModal }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.closeIcon} onClick={closeModal}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
