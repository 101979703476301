import { FunctionComponent, useEffect, useCallback } from "react";
import { NotificationMessage } from "../../store/notifications/notifications.actions";
import { useSnackbar } from "notistack";
import { createStyles, Theme, withStyles, WithStyles, Button } from "@material-ui/core";
import { NotificationDelay } from "./notification-delay";

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      fontSize: "1em",
      color: "white",
      border: "1px solid white",
      padding: "4px 16px",
      "&:first-child": {
        marginRight: "1em",
      },
    },
  });

export interface ConnectedState {
  notification: NotificationMessage | undefined;
  isAppUpdateAvailable: boolean | undefined;
}

export interface ConnectedDispatch {
  setIsAppUpdateAvailable: (isAvailable: boolean) => void;
}
interface Props extends ConnectedState, ConnectedDispatch, WithStyles<typeof styles> {}

const NotificationsComponent: FunctionComponent<Props> = props => {
  const { classes, notification, setIsAppUpdateAvailable, isAppUpdateAvailable } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const appUpdateAvailableNotification = useCallback(() => {
    const action = () => (
      <>
        <Button
          className={classes.btn}
          onClick={() => {
            closeSnackbar("appUpdateSnackbar");
          }}
        >
          Später
        </Button>
        <Button
          className={classes.btn}
          onClick={() => {
            closeSnackbar("appUpdateSnackbar");
            setIsAppUpdateAvailable(false);
            window.location.reload();
          }}
        >
          Neu laden
        </Button>
      </>
    );

    enqueueSnackbar("Ein App Update steht zur Verfügung!", {
      variant: "info",
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      key: "appUpdateSnackbar",
      action,
    });
  }, [classes.btn, closeSnackbar, enqueueSnackbar, setIsAppUpdateAvailable]);

  useEffect(() => {
    if (notification && notification.message !== "") {
      enqueueSnackbar(notification.message, {
        variant: notification.variant,
        autoHideDuration: notification.delay || NotificationDelay.DEFAULT,
        preventDuplicate: true,
      });
    }
  }, [notification, enqueueSnackbar]);

  useEffect(() => {
    const threeHours = process.env.REACT_APP_UPDATE_INTERVAL;

    if (isAppUpdateAvailable) {
      appUpdateAvailableNotification();

      const appUpdateIntervall = setInterval(
        () => {
          appUpdateAvailableNotification();
        },
        threeHours ? +threeHours : 10800000
      );
      return () => clearInterval(appUpdateIntervall);
    }
  }, [appUpdateAvailableNotification, isAppUpdateAvailable]);

  return null;
};

export default withStyles(styles)(NotificationsComponent);
