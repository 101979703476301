import { InitActionType } from "./init.actions";
import { put } from "redux-saga-test-plan/matchers";
import { takeLeading } from "redux-saga/effects";
import { loadBetriebe } from "../betriebe/betriebe.actions";
import { loadTranslations } from "../i18n/i18n.actions";
import { loadChecklisten } from "../checklisten/checklisten.actions";
import { loadContactDetails } from "../contact-details/contact-details.actions";

export function* init() {
  yield put(loadBetriebe());
  yield put(loadChecklisten());
  yield put(loadContactDetails());
}

export function* initTranslations() {
  yield put(loadTranslations());
}

export default function* initSaga() {
  yield takeLeading(InitActionType.INIT, init);
  yield takeLeading(InitActionType.INIT_TRANSLATIONS, initTranslations);
}
