import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    overflowY: "hidden",
    color: Colors.Grey,
  },
  wrapperHeader: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "200px",
  },
  pageTitle: {
    color: "grey",
    fontSize: "xx-large",
  },
  emailDescription: {
    width: "52%",
    alignSelf: "center",
    lineHeight: "22px",
  },
  passwordDescription: {
    width: "51%",
    fontSize: "14px",
    lineHeight: "22px",
    paddingTop: "10px",
    alignSelf: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    width: "350px",
    minHeight: "45px",
    alignSelf: "center",
  },
  list: {
    margin: 0,
  },
  license: {
    fontSize: "12px",
    alignSelf: "center",
    width: "445px",
    marginTop: "50px",
  },
  button: {
    alignSelf: "center",
    padding: "10px 0",
    "&:last-of-type": {
      marginTop: "auto",
    },
  },
  passwordField: {
    position: "relative",
    alignSelf: "center",
  },
  eyeIcon: {
    position: "absolute",
    top: "13px",
    right: "5px",
  },
});
