import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

import createSagaMiddleware, { SagaMiddleware, SagaMiddlewareOptions } from "@redux-saga/core";

import { rootReducer, sagas } from "./index";

const logger = (createLogger as any)({ collapsed: true });
const history = createBrowserHistory();

const dev = process.env.NODE_ENV === "development";

let sagaMiddleware: SagaMiddleware;

const sagaOptions: SagaMiddlewareOptions = {};
sagaMiddleware = createSagaMiddleware(sagaOptions);

let middleware = dev
  ? applyMiddleware(logger, routerMiddleware(history), sagaMiddleware)
  : applyMiddleware(routerMiddleware(history), sagaMiddleware);

if (dev) {
  middleware = composeWithDevTools(middleware);
}

export default () => {
  const store = createStore(rootReducer(history), {}, middleware);

  // then run the sagas
  sagas.forEach(saga => {
    sagaMiddleware.run(saga, store.dispatch);
  });

  return store;
};

export { history };
