import { FunctionComponent } from "react";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./reset-password-form.styles";
import renderTextField from "../../common/render-text-field/render-text-field";
import { SubmitHandler, Field, reduxForm } from "redux-form";
import { ButtonSize } from "../../../shared/buttons-style";
import { PlaceHolder, FieldName } from "../../../shared/field-data";
import { Typography } from "@material-ui/core";
import { required, email } from "../../../utils/authValidation/auth-validation";
import { useTranslation } from "react-i18next";

interface Props {
  handleSubmit: SubmitHandler;
}

const ResetPasswordForm: FunctionComponent<Props> = ({ handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Field
        className={classes.field}
        placeholder={t(PlaceHolder.EMAIL)}
        name={FieldName.EMAIL}
        component={renderTextField}
        validate={[required, email]}
      />
      <Typography className={classes.clarification}>{t("E-Mail eingeben - registriert")}</Typography>
      <div className={classes.button}>
        <AppButton type="submit" className={classes.dischargeBtn} size={ButtonSize.LARGE_BUTTON}>
          {t("PASSWORT ZURÜCKSETZEN")}
        </AppButton>
      </div>
    </form>
  );
};

export default reduxForm({ form: "resetPassword" })(ResetPasswordForm);
