import logo from "./img/logo.svg";
import agrar from "./img/iq-agrar.jpg";
import prtbl from "./img/prtbl.png";
import isn from "./img/isn.png";
import fbf from "./img/fbf.jpg";

const iqImages = {
  logo,
  agrar,
  prtbl,
  isn,
  fbf,
};

export default iqImages;
