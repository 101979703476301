import { FunctionComponent, MouseEventHandler, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  createStyles,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Colors } from "../../theme/colors";
import { Home } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { grey } from "@material-ui/core/colors";
import RoutePaths from "../../routes/route-paths";
import iqImages from "../../images/iq-images";
import LanguageMenuComponent from "../language-menu/language-menu.component";
import AuthService from "../../shared/services/auth.service";
import { LoadingStatus } from "../../store/loading-status";
import LogoutOfflineModalWindow from "../modal-windows/logout-offline/logout-offline.component";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import classnames from "classnames";

export interface ConnectedDispatch {
  navigateTo: (path: string) => void;
  logout: () => void;
}

export interface ConnectedState {
  appTitle: string;
  selectedVvvo: string;
  checklistenEingabenStatus: LoadingStatus;
  profileStatus: LoadingStatus;
  changeEmailStatus: LoadingStatus;
  changePasswordStatus: LoadingStatus;
  betriebstaetteStatus: LoadingStatus;
  betriebeStatus: LoadingStatus;
  logoutStatus: LoadingStatus;
  isAllChecklistenEingabenSynced: boolean;
}

interface Props extends WithStyles<typeof styles>, ConnectedDispatch, ConnectedState {}

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      position: "static",
      backgroundColor: grey[50],
      height: "70px",
    },
    centered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flex: 1,
      position: "relative",
    },
    pageName: {
      paddingLeft: "24px",
    },
    logo: {
      height: 85,
      cursor: "pointer",
    },
    iconButton: {
      padding: 6,
    },
    icon: {
      border: "1px solid rgb(123, 124, 126)",
      borderRadius: "7px",
      color: Colors.Grey,
      fontSize: "30px",
      padding: "5px",
    },
    vvvo: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    cloudIcon: {
      padding: 6,
      fontSize: "30px",
    },
    synced: {
      color: Colors.AmpelGruen,
    },
    unsynced: {
      color: Colors.Grey,
    },
  });

const AppBarComponent: FunctionComponent<Props> = props => {
  const {
    classes,
    navigateTo,
    appTitle,
    selectedVvvo,
    logout,
    isAllChecklistenEingabenSynced,
    checklistenEingabenStatus,
    profileStatus,
    changeEmailStatus,
    changePasswordStatus,
    betriebstaetteStatus,
    betriebeStatus,
    logoutStatus,
  } = props;
  const [name, setName] = useState("PigCheck");
  const [vvvo, setVvvo] = useState("");
  const [open, setOpen] = useState(false);

  const authService = AuthService.getInstance();
  let disableButtons = false;

  useEffect(() => {
    setName(appTitle);
  }, [setName, appTitle, selectedVvvo]);

  useEffect(() => {
    setVvvo(selectedVvvo);
  }, [selectedVvvo]);

  const closeWindow: MouseEventHandler = () => {
    setOpen(false);
  };

  const handleLogoutButton = () => {
    if (isAllChecklistenEingabenSynced) {
      logout();
    } else {
      setOpen(true);
    }
  };

  if (
    profileStatus.pending ||
    changeEmailStatus.pending ||
    changePasswordStatus.pending ||
    checklistenEingabenStatus.pending ||
    betriebstaetteStatus.pending ||
    betriebeStatus.pending ||
    logoutStatus.pending
  ) {
    disableButtons = true;
  }

  return (
    <AppBar className={classes.appBar}>
      <Toolbar disableGutters={true}>
        {authService.isLoggedIn() && (
          <img
            src={iqImages.logo}
            className={classes.logo}
            alt={"PIG-CHECK"}
            data-cy="app-logo"
            onClick={() => navigateTo(`${RoutePaths.Betriebe}`)}
          />
        )}
        {!vvvo ? (
          <span className={classes.centered}>
            <Typography variant="h5" color="textPrimary" className={classes.pageName}>
              <Box fontWeight="fontWeightBold" data-cy="app-title">
                {name}
              </Box>
            </Typography>
          </span>
        ) : (
          <span className={classes.centered}>
            <Typography variant="h5" color="textPrimary" className={classes.pageName}>
              <Box fontWeight="fontWeightBold" data-cy="app-title">
                {name}
                <br />
                <span className={classes.vvvo}>{`VVVO ${vvvo}`}</span>
              </Box>
            </Typography>
          </span>
        )}
        <IconButton
          disabled={disableButtons && true}
          onClick={() => navigateTo(`${RoutePaths.Betriebe}`)}
          className={classes.iconButton}
        >
          <Home className={classes.icon} />
        </IconButton>
        {authService.isLoggedIn() && (
          <IconButton
            disabled={disableButtons && true}
            onClick={() => navigateTo(`${RoutePaths.Profile}`)}
            className={classes.iconButton}
          >
            <PersonIcon className={classes.icon} />
          </IconButton>
        )}
        <IconButton
          disabled={disableButtons && true}
          onClick={() => navigateTo(`${RoutePaths.Info}`)}
          className={classes.iconButton}
        >
          <InfoOutlinedIcon className={classes.icon} />
        </IconButton>
        {authService.isLoggedIn() && (
          <IconButton
            disabled={disableButtons && true}
            onClick={handleLogoutButton}
            className={classes.iconButton}
          >
            <ExitToAppIcon className={classes.icon} />
          </IconButton>
        )}
        {authService.isLoggedIn() && (
          <CloudUploadIcon
            className={classnames(
              classes.cloudIcon,
              isAllChecklistenEingabenSynced ? classes.synced : classes.unsynced
            )}
          />
        )}
        <LanguageMenuComponent />
      </Toolbar>
      {open && <LogoutOfflineModalWindow closeModal={closeWindow} open={open} />}
    </AppBar>
  );
};

export default withStyles(styles)(AppBarComponent);
