import InfoPage, { ConnectedState } from "./info.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ConnectedDispatch } from "./info.component";
import { setCurrentTitle } from "../../store/common/common.actions";
import { AppState } from "../../store/app.state";
import { getLogoutStatus } from "../../store/logout/logout.selectors";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setCurrentTitle: (title: string) => dispatch(setCurrentTitle(title)),
});

const mapStateToProps = (state: AppState): ConnectedState => ({
  logoutStatus: getLogoutStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPage);
