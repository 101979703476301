import { AppState } from "../../../store/app.state";
import ProfileContactDetailsForm, { ConnectedDispatch, ConnectedState } from "./contact-form.component";
import { connect } from "react-redux";
import {
  getProfileModalWindow,
  getContactDetails,
} from "../../../store/contact-details/contact-details.selectors";
import { Dispatch } from "redux";
import { toggleProfileDetailsModalWindow } from "../../../store/contact-details/contact-details.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  isOpen: getProfileModalWindow(state),
  user: getContactDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  toggleProfileDetailsModalWindow: (isOpen: boolean) => dispatch(toggleProfileDetailsModalWindow(isOpen)),
});

interface Props extends ConnectedDispatch, ConnectedState {}

const ProfileContactDetailsContainer = (props: Props) => (
  <ProfileContactDetailsForm initialValues={props.user} {...props} />
);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContactDetailsContainer);
