import { FunctionComponent, useState } from "react";
import AppButton from "../../common/app-button/app-button";
import renderTextField from "../../common/render-text-field/render-text-field";
import useStyles from "./change-password-form.styles";
import ShowPasswordButton from "../../common/show-password-button/show-password-button.component";
import { Typography } from "@material-ui/core";
import { FieldName, PlaceHolder } from "../../../shared/field-data";
import { ButtonSize } from "../../../shared/buttons-style";
import { SubmitHandler, reduxForm, Field } from "redux-form";
import {
  required,
  MinLengths,
  MaxLengths,
  passwordsMatch,
  password,
} from "../../../utils/authValidation/auth-validation";
import { useTranslation } from "react-i18next";

interface Props {
  handleSubmit: SubmitHandler;
}

const ChangePasswordForm: FunctionComponent<Props> = ({ handleSubmit }) => {
  const classes = useStyles();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
  const { t } = useTranslation();

  const switchPasswordType = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const switchConfirmPasswordType = () => {
    setIsConfirmPasswordHidden(!isConfirmPasswordHidden);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.flexWrapper}>
      <div className={classes.passwordField}>
        <Field
          name={FieldName.PASSWORD}
          placeholder={t(PlaceHolder.NEW_PASSWORD)}
          component={renderTextField}
          className={classes.field}
          validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
          type={isPasswordHidden ? "password" : "text"}
        />
        <ShowPasswordButton switchHandler={switchPasswordType} isPasswordHidden={isPasswordHidden} />
      </div>
      <div className={classes.passwordField}>
        <Field
          name={FieldName.CONFIRM_PASSWORD}
          placeholder={t(PlaceHolder.CONFIRM_NEW_PASSWORD)}
          component={renderTextField}
          className={classes.field}
          validate={[required, passwordsMatch]}
          type={isConfirmPasswordHidden ? "password" : "text"}
        />
        <ShowPasswordButton
          switchHandler={switchConfirmPasswordType}
          isPasswordHidden={isConfirmPasswordHidden}
        />
      </div>
      <Typography component={"ul"} className={classes.clarification}>
        {t("Passwort Richtlinien")}
        <ul className={classes.list}>
          <li>{t("Passwort Richtlinien - 1")}</li>
          <li>{t("Passwort Richtlinien - 2")}</li>
        </ul>
      </Typography>
      <div className={classes.button}>
        <AppButton className={classes.dischargeBtn} type="submit" size={ButtonSize.LARGE_BUTTON}>
          {t("PASSWORT ÄNDERN")}
        </AppButton>
      </div>
    </form>
  );
};

export default reduxForm({ form: "changePassword" })(ChangePasswordForm);
