import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: Colors.Grey,
    fontSize: "18pt",
    marginBottom: "20px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    width: "80%",
  },
  confirmButton: {
    backgroundColor: Colors.Red,
    "&:hover": {
      opacity: 0.75,
    },
  },
  cancelButton: {
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
  },
}));

export default useStyles;
