import { FunctionComponent, ReactNode, MouseEventHandler } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";
import { IButtonSize } from "../../../shared/buttons-style";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: Colors.DarkGreen,
    width: "190px",
    height: "40px",
    color: "white",
    fontWeight: "bold",
    fontSize: "13pt",
    borderRadius: "8px",
    borderColor: "transparent",
    "&:hover": {
      opacity: 0.75,
    },
    "&:focus": {
      outline: "none",
      border: "none",
      opacity: 0.75,
    },
    cursor: "pointer",
  },
}));

export interface Props {
  children: ReactNode;
  handler?: MouseEventHandler;
  type?: string;
  className?: string;
  size?: IButtonSize;
  disabled?: boolean;
}

const AppButton: FunctionComponent<Props> = ({ handler, size, children, className, disabled }) => {
  const classes = useStyles();
  return (
    <button
      tabIndex={0}
      onClick={handler}
      style={size}
      className={`${className} ${classes.button}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default AppButton;
