import { FunctionComponent, useEffect } from "react";
import { getCloseChecklisteWindow, getCloseButton, getValidationButton } from "./checkliste.utils";
import { OptionPageModel, Props, Result } from "./checkliste.types";
import * as Survey from "survey-react";
import logger from "../../logger";
import RoutePaths from "../../routes/route-paths";
import StorageService from "../../shared/services/storage.service";
import { v1 as uuid } from "uuid";
import "survey-react/survey.css";
import "./bootstrap-custom.css";

const logInfo = logger.info("checkliste.component");

// See http://iq-openproject/projects/team-edv/wiki/checklisten
enum QuestionName {
  BetriebName = "betrieb_name",
  Vvvo = "vvvo_number",
  BetriebVorname = "betrieb_vorname",
  BetriebFullName = "betrieb_full_name",
  BetriebFullAddress = "betrieb_full_address",
}

const SURVEY_STATE = "SurveyJS_LoadState";
const SIGNATURE_TYPE = "signaturepad";
const TEN_SECONDS = 10000;

const ChecklisteComponent: FunctionComponent<Props> = props => {
  const {
    checkliste,
    saveChecklistenEingabe,
    navigateTo,
    checklistenEingabeName,
    betrieb,
    loadChecklisten,
    loadBetriebe,
    showValidationError,
    showSuccessValidation,
    checklistenEingabeDraft,
    removeDraftChecklistenEingabe,
    copyOfChecklistenEingabe,
    removeCopyOfChecklistenEingabe,
  } = props;

  const storageService = StorageService.getInstance();
  let timerId: NodeJS.Timeout;

  useEffect(() => {
    if (!checkliste) {
      loadChecklisten();
    }
    if (!betrieb) {
      loadBetriebe();
    }
  }, [checkliste, betrieb, loadBetriebe, loadChecklisten]);

  useEffect(() => {
    window.onbeforeunload = () => false;
  });

  timerId = setInterval(() => {
    saveState(model);
  }, TEN_SECONDS);

  useEffect(() => () => clearInterval(timerId), [timerId]);

  useEffect(
    () => () => {
      removeDraftChecklistenEingabe();
      removeCopyOfChecklistenEingabe();
    },
    [removeCopyOfChecklistenEingabe, removeDraftChecklistenEingabe]
  );

  if (!checkliste) {
    return null;
  }

  const onValueChanged = (survey: Survey.SurveyModel, options: any) => {
    logInfo(`Value changed - ${options.name}: ${options.value}`);
  };

  const onComplete = (result: any) => {
    saveChecklistenEingabe({
      checklistenId: checkliste!.id!,
      checklistenName: checkliste!.name!,
      eingabe: JSON.stringify(result.data),
      createdAtUtc: new Date(Date.now()),
      betriebId: betrieb.id,
      checklistenEingabeName,
      synced: 0,
      uniqueId: checklistenEingabeDraft ? checklistenEingabeDraft.uniqueId : uuid(),
      isDraft: false,
      isValid: true,
    });
    logInfo("Complete!", result);
    storageService.removeItem(SURVEY_STATE);
    navigateTo(`${RoutePaths.ChecklistenOverview}/${betrieb.id}/${betrieb.name}/${betrieb.vvvo.value}`);
  };

  const afterRenderSetOptionItem = (survey: Survey.SurveyModel, options: Survey.QuestionTextModel) => {
    const { name, vorname, strasse, postleitzahl, ort, vvvo } = betrieb;
    if (options.question.name === QuestionName.BetriebName) {
      options.htmlElement.value = name;
      options.question.value = name;
      options.htmlElement.disabled = true;
    }
    if (options.question.name === QuestionName.Vvvo) {
      options.htmlElement.value = vvvo.value;
      options.question.value = vvvo.value;
      options.htmlElement.disabled = true;
    }
    if (options.question.name === QuestionName.BetriebVorname) {
      options.htmlElement.value = vorname;
      options.question.value = vorname;
      options.htmlElement.disabled = true;
    }
    if (options.question.name === QuestionName.BetriebFullName) {
      options.htmlElement.value = `${vorname} ${name}`.trim();
      options.question.value = `${vorname} ${name}`.trim();
      options.htmlElement.disabled = true;
    }
    if (options.question.name === QuestionName.BetriebFullAddress) {
      options.htmlElement.value = `${strasse}, ${postleitzahl} ${ort}`;
      options.question.value = `${strasse}, ${postleitzahl} ${ort}`;
      options.htmlElement.disabled = true;
    }
  };

  const addAdditionalElements = (survey: Survey.SurveyModel, options: OptionPageModel) => {
    const closeChecklisteButton = getCloseButton();
    const modalWindow = getCloseChecklisteWindow(saveAsDraft);
    const validationButton = getValidationButton(survey, showValidationError, showSuccessValidation);

    options.htmlElement.appendChild(closeChecklisteButton);
    options.htmlElement.appendChild(modalWindow);
    options.htmlElement.appendChild(validationButton);
  };

  const saveState = (survey: Survey.SurveyModel, options?: OptionPageModel) => {
    const result: Result = {
      currentPageNo: survey.currentPageNo,
      data: survey.data,
    };
    storageService.setItem(SURVEY_STATE, JSON.stringify(result));
  };

  const loadState = (survey: Survey.SurveyModel) => {
    if (checklistenEingabeDraft) {
      survey.data = JSON.parse(checklistenEingabeDraft.eingabe);
      return;
    }
    if (copyOfChecklistenEingabe) {
      survey.data = JSON.parse(copyOfChecklistenEingabe.eingabe);
      return;
    }

    const storageState = storageService.getItem(SURVEY_STATE) || "";

    let result: Result = {};
    if (storageState) {
      result = JSON.parse(storageState);
    }

    if (result.data) {
      survey.data = result.data;
    }

    if (result.currentPageNo) {
      survey.currentPageNo = result.currentPageNo;
    }
  };

  const saveAsDraft = () => {
    saveState(model);
    const storageState = storageService.getItem(SURVEY_STATE) || "";

    let result: Result = {};
    if (storageState) {
      result = JSON.parse(storageState);
    }

    if (Object.values(result).length) {
      saveChecklistenEingabe({
        checklistenId: checkliste!.id!,
        checklistenName: checkliste!.name!,
        eingabe: JSON.stringify(result.data),
        createdAtUtc: new Date(Date.now()),
        betriebId: betrieb.id,
        checklistenEingabeName,
        synced: 0,
        uniqueId: checklistenEingabeDraft ? checklistenEingabeDraft.uniqueId : uuid(),
        isDraft: true,
      });
      logInfo("Saved as draft", result);
      storageService.removeItem(SURVEY_STATE);
      navigateTo(`${RoutePaths.ChecklistenOverview}/${betrieb.id}/${betrieb.name}/${betrieb.vvvo.value}`);
    }
  };

  Survey.StylesManager.applyTheme("bootstrap");
  const model = new Survey.Model(checkliste.content);
  // Validate all visible questions on complete button click. If there are errors on previous pages, then the page with the first error becomes the current.
  model.checkErrorsMode = "onComplete";
  model.onAfterRenderQuestionInput.add(afterRenderSetOptionItem);
  model.onAfterRenderPage.add(addAdditionalElements);
  model.onCurrentPageChanged.add(saveState);
  loadState(model);

  if (copyOfChecklistenEingabe) {
    model.getAllQuestions().forEach(question => {
      if (question.getType() === SIGNATURE_TYPE) {
        question.clearValue();
      }
    });
  }

  return (
    <div className="use-bootstrap">
      <Survey.Survey model={model} onComplete={onComplete} onValueChanged={onValueChanged} />
    </div>
  );
};

export default ChecklisteComponent;
