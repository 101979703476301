import "./index.css";
import StorageService from "./shared/services/storage.service";

import ReactDOM from "react-dom";

import { DEBUG_BASE } from "./logger";
import ReduxRoot from "./redux-root";
import * as serviceWorker from "./service-worker-registration";

const storageService = StorageService.getInstance();
if (process.env.NODE_ENV !== "production") {
  storageService.setItem("debug", `${DEBUG_BASE}*`);
}

const rootEl = document.getElementById("root");
ReactDOM.render(<ReduxRoot />, rootEl);

// if (module.hot) {
//   module.hot.accept("./redux-root", () => {
//     const NextApp = require("./redux-root").default;
//     ReactDOM.render(<NextApp />, rootEl);
//   });
// }

serviceWorker.register();
