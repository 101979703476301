import { call, put, takeLatest, takeLeading } from "redux-saga/effects";

import logger from "../../logger";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import { I18NActionType, loadTranslationsFailure, loadTranslationsSuccess } from "./i18n.actions";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Action } from "../action";
import { select } from "redux-saga-test-plan/matchers";
import { getCurrentLanguage } from "../common/common.selectors";
import { setCurrentLanguage } from "../common/common.actions";
import StorageService from "../../shared/services/storage.service";
import apiMethods from "../../api/api-methods";
import { ITranslationsResponseDto } from "../../api/backend-api-v1";

const logInfo = logger.info("translation.saga");
const logError = logger.error("translation.saga");
const storageService = StorageService.getInstance();

let backendClient: BackendClient;

function initI18n(language: string, resources: any) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: "de",
      keySeparator: false, // we do not use keys in form messages.welcome
      lng: language,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}

export function* loadTranslations() {
  try {
    const language: string = yield select(getCurrentLanguage);
    const hasLanguage = storageService.getItem("language");
    if (!hasLanguage) {
      storageService.setItem("language", language);
    }
    const translations: ITranslationsResponseDto = yield call([backendClient, apiMethods.GetTranslations]);
    yield call(initI18n, language, translations);
    yield put(loadTranslationsSuccess());
    logInfo("Successfully fetched translations", translations);
  } catch (e) {
    yield put(loadTranslationsFailure(e.message));
    logError("Could not fetch translations", e);
  }
}

export function* setLanguage(action: Action<string>) {
  try {
    const language = action.payload;
    yield call([i18n, "changeLanguage"], language);
    yield put(setCurrentLanguage(language));
    storageService.setItem("language", language);
  } catch (e) {
    logError(`Could not change language to ${action.payload}`, e);
  }
}

export function* setLanguageOnServer(action: Action<string>) {
  try {
    const language = action.payload;
    yield call([backendClient, apiMethods.ChangeUserLanguage], language);
  } catch (e) {
    logError(`Could not change language to ${action.payload}`, e);
  }
}

export default function* i18nSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(I18NActionType.LOAD_TRANSLATIONS, loadTranslations);
  yield takeLatest(I18NActionType.CHANGE_LANGUAGE, setLanguage);
  yield takeLatest(I18NActionType.CHANGE_LANGUAGE_ON_SERVER, setLanguageOnServer);
}
