import { connect, useDispatch } from "react-redux";
import Login, { ConnectedState } from "./login.component";
import { fetchUser } from "../../store/login/login.actions";
import { LoginDto } from "../../store/login/login.actions";
import { AppState } from "../../store/app.state";
import { getResetPasswordStatus } from "../../store/reset-password/reset-password.selectors";

const LoginContainer = (props: any) => {
  const dispatch = useDispatch();
  // TODO: find correct type for submit.
  const submitHandler: any = (userData: LoginDto) => {
    dispatch(fetchUser(userData));
  };
  return <Login onSubmit={submitHandler} {...props} />;
};

const mapStateToProps = (state: AppState): ConnectedState => ({
  resetPasswordStatus: getResetPasswordStatus(state),
});

export default connect(mapStateToProps, null)(LoginContainer);
