import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ISyncedChecklistenEingabeDto } from "../../db/database";
import { AppState } from "../../store/app.state";
import ChecklisteComponent from "./checkliste.component";
import { ConnectedDispatch, ConnectedState } from "./checkliste.types";
import { getChecklisteById } from "../../store/checklisten/checklisten.selectors";
import { Dispatch } from "redux";
import { IChecklisteDto } from "../../api/backend-api-v1";
import { saveCheckliste, loadChecklisten } from "../../store/checklisten/checklisten.actions";
import {
  removeCopyOfChecklistenEingabe,
  removeDraftChecklistenEingabe,
  saveChecklistenEingabe,
} from "../../store/checklisten-eingabe/checklisten-eingabe.actions";
import { push } from "connected-react-router";
import { getBetriebe } from "../../store/betriebe/betriebe.selectors";
import { loadBetriebe } from "../../store/betriebe/betriebe.actions";
import { showFailedRequest, showSuccessfulRequest } from "../../store/notifications/notifications.actions";
import { FailureMessages, SuccessMessages } from "../../shared/notifications-messages";
import I18n from "i18next";
import {
  getChecklistenEingabeDraft,
  getCopyOfChecklistenEingabe,
} from "../../store/checklisten-eingabe/checklisten-engabe.selectors";

const mapStateToProps = (
  state: AppState,
  props: RouteComponentProps<{
    checklisteId: string | undefined;
    betriebId: string;
    betriebName: string;
    betriebVvvo: string;
    checklistenEingabenName: string;
  }>
): ConnectedState => {
  const checklisteId = props.match.params.checklisteId;
  const betriebId = parseInt(props.match.params.betriebId, undefined);
  const checklistenEingabeName = props.match.params.checklistenEingabenName;

  return {
    checkliste: checklisteId ? getChecklisteById(checklisteId)(state) : undefined,
    checklistenEingabeName,
    betrieb: getBetriebe(state)[betriebId],
    checklistenEingabeDraft: getChecklistenEingabeDraft(state),
    copyOfChecklistenEingabe: getCopyOfChecklistenEingabe(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
  saveCheckliste: (checkliste: IChecklisteDto) => dispatch(saveCheckliste()),
  saveChecklistenEingabe: (checklistenEingabe: ISyncedChecklistenEingabeDto) =>
    dispatch(saveChecklistenEingabe(checklistenEingabe)),
  loadChecklisten: () => dispatch(loadChecklisten()),
  loadBetriebe: () => dispatch(loadBetriebe()),
  showValidationError: () =>
    dispatch(showFailedRequest(I18n.t(FailureMessages.SHOW_VALIDATION_ERROR_FOR_SURVEY_PAGE))),
  showSuccessValidation: () =>
    dispatch(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_NO_VALIDATION_ERROR_FOR_SURVEY_PAGE))),
  removeDraftChecklistenEingabe: () => dispatch(removeDraftChecklistenEingabe()),
  removeCopyOfChecklistenEingabe: () => dispatch(removeCopyOfChecklistenEingabe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklisteComponent);
