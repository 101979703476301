import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  clarification: {
    fontSize: "10pt",
    width: "57%",
    color: Colors.Grey,
    marginRight: "30px",
  },
  field: {
    width: "300px",
    borderRadius: "10px",
    marginBottom: "15px",
  },
  list: {
    margin: "0",
    paddingLeft: "15px",
  },
  button: {
    display: "flex",
    justifyContent: "start",
    marginTop: "10px",
    width: "80%",
  },
  dischargeBtn: {
    marginRight: "10px",
  },
  passwordField: {
    position: "relative",
  },
}));

export default useStyles;
