import { Action } from "../action";

export enum I18NActionType {
  LOAD_TRANSLATIONS = "LOAD_TRANSLATIONS",
  LOAD_TRANSLATIONS_SUCCESS = "LOAD_TRANSLATIONS_SUCCESS",
  LOAD_TRANSLATIONS_FAILURE = "LOAD_TRANSLATIONS_FAILURE",
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
  CHANGE_LANGUAGE_ON_SERVER = "CHANGE_LANGUAGE_ON_SERVER",
}

export const loadTranslations = (): Action<null> => ({
  type: I18NActionType.LOAD_TRANSLATIONS,
  payload: null,
});

export const loadTranslationsSuccess = (): Action<null> => ({
  type: I18NActionType.LOAD_TRANSLATIONS_SUCCESS,
  payload: null,
});

export const loadTranslationsFailure = (errorMessage: string): Action<string> => ({
  type: I18NActionType.LOAD_TRANSLATIONS_FAILURE,
  payload: errorMessage,
});

export const changeLanguage = (language: string): Action<string> => ({
  type: I18NActionType.CHANGE_LANGUAGE,
  payload: language,
});

export const changeLanguageOnServer = (language: string): Action<string> => ({
  type: I18NActionType.CHANGE_LANGUAGE_ON_SERVER,
  payload: language,
});
