import { useEffect, FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./resend-registration-email.styles";
import iqImages from "../../../images/iq-images";
import LocationService from "../../../shared/services/location.service";
import { history } from "../../../store/configureStore";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../../store/loading-status";

export interface ConnectedState {
  translationsStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  resendRegistrationEmail: (userId: string) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const ResendRegistrationEmailComponent: FunctionComponent<Props> = ({
  resendRegistrationEmail,
  translationsStatus,
}) => {
  const classes = useStyles();
  const locationService = LocationService.getInstance(history.location.search);
  const { t } = useTranslation();

  useEffect(() => {
    // TODO: It's a quick and dirty solution, we need to wait until translations will be uploaded for preventing unnecessary rendering.
    if (translationsStatus.success) {
      const userId = locationService.getUserId();
      resendRegistrationEmail(userId);
    }
  });

  return (
    <TransitionsModal open={true}>
      <div className={classes.flexWrapper} data-cy="resend-email-dialog">
        <img src={iqImages.logo} alt="PIG-CHECK" className={classes.logo} />
        <Typography className={classes.title}>{t("Neue E-Mail gesendet!")}</Typography>
        <Typography className={classes.description}>
          {t("Eine neue E-Mail zur Bestätigung der Registrierung wurde gesendet.")}
        </Typography>
      </div>
    </TransitionsModal>
  );
};

export default ResendRegistrationEmailComponent;
