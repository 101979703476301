import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import {
  ConfirmAuthorizeVvvoActionType,
  confirmAuthorizeVvvoSuccess,
  confirmAuthorizeVvvoFailure,
} from "./confirm-authorize-vvvo.actions";
import { takeLeading, put, call } from "redux-saga/effects";
import { Dispatch } from "redux";
import { IConfirmUserVvvoAuthorizationRequestDto } from "../../api/backend-api-v1";
import { Action } from "../action";

let backendClient: BackendClient;
const logError = logger.error("confirmAuthorizeVvvo.saga");

export function* confirmAuthorize(action: Action<IConfirmUserVvvoAuthorizationRequestDto>) {
  try {
    yield call([backendClient, apiMethods.AuthorizeConfirmVvvo], action.payload);
    yield put(confirmAuthorizeVvvoSuccess(true));
  } catch (e) {
    logError(e.message);
    yield put(confirmAuthorizeVvvoFailure(e.message));
  }
}

export default function* confirmAuthorizeVvvoSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO, confirmAuthorize);
}
