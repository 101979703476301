import { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

import AppContainer from "./app.container";
import configureStore from "./store/configureStore";

const logger = (createLogger as any)();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let middleware = applyMiddleware(logger);

if (process.env.NODE_ENV === "development") {
  middleware = composeWithDevTools(middleware);
}

const store = configureStore();

const ReduxRoot: FunctionComponent<{}> = () => (
  <Provider store={store}>
    <AppContainer />
  </Provider>
);

export default ReduxRoot;
