import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import CloseButton from "../../common/close-button/close-button.component";
import useStyles from "./terms-and-conditions.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const TermsAndConditions: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open} sizeWidth="900px">
      <CloseButton closeModal={closeModal} />
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("AGB")}</Typography>
        <div className={classes.textWrapper}>
          <Typography className={classes.description}>{t("Text - AGB")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 2")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 3")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 4")}</Typography>
          <div className={classes.description}>
            <ul>
              <li>
                <Typography>{t("Text - AGB 5")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 6")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 7")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 8")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 9")}</Typography>
              </li>
            </ul>
          </div>
          <Typography className={classes.description}>{t("Text - AGB 10")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 11")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 12")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 13")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 14")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 15")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 16")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 17")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 18")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 19")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 59")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 20")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 21")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 22")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 23")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 24")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 25")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 26")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 27")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 28")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 29")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 30")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 31")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 32")}</Typography>
          <Typography>{t("Text - AGB 33")}</Typography>
          <div>
            <ul>
              <li>
                <Typography>{t("Text - AGB 34")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 35")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 36")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 37")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - AGB 38")}</Typography>
              </li>
            </ul>
          </div>
          <Typography className={classes.description}>{t("Text - AGB 39")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 40")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 41")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 42")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 43")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 44")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 45")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 46")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 47")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 48")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 49")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 50")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 51")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 52")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 53")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 54")}</Typography>
          <Typography className={classes.topic}>{t("Text - AGB 55")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 56")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 57")}</Typography>
          <Typography className={classes.description}>{t("Text - AGB 58")}</Typography>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default TermsAndConditions;
