import AuthService from "../shared/services/auth.service";
import RoutePaths from "../routes/route-paths";
import { AxiosResponse } from "axios";
import { errorOccurred } from "../store/error/error.actions";
import { Dispatch } from "redux";
import { history } from "../store/configureStore";

export const responseHandler = (response: AxiosResponse<any>, dispatch: Dispatch) => {
  if (!response) {
    return;
  }

  const authService = AuthService.getInstance();
  const { status } = response;

  if (status > 401 && status !== 404 && status !== 409) {
    dispatch(errorOccurred());
  }

  if (status === 401) {
    authService.endSession();
    history.push(RoutePaths.Login);
  }
};
