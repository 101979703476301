import { FunctionComponent } from "react";
import TransitionsModal from "../modal/modal-window";
import useStyles from "./modal-failure.styles";
import AppButton from "../app-button/app-button";
import RoutePaths from "../../../routes/route-paths";
import { history } from "../../../store/configureStore";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface Props {
  title: string;
  description: string;
}

const ModalFailure: FunctionComponent<Props> = ({ title, description }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push(RoutePaths.Login);
  };

  return (
    <TransitionsModal open={true}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>
          {description}, {t("da der ausgewählte Link seine Gültigkeit verloren hat.")}
        </Typography>
        <div className={classes.test}>
          <Typography className={classes.tryAgain}>{t("Bitte versuchen Sie es erneut.")}</Typography>
          <Typography className={classes.wishes}>
            {t("Mit freundlichen Grüßen")}
            <br />
            {t("Ihr PIG-CHECK Team")}
          </Typography>
        </div>
        <AppButton className={classes.button} handler={handleClick}>
          {t("BESTÄTIGEN")}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ModalFailure;
