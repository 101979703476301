import { useEffect } from "react";
import ChangePasswordForm from "./change-password-form.component";
import TransitionsModal from "../../common/modal/modal-window";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./change-password.styles";
import LocationService from "../../../shared/services/location.service";
import { isDateExpired } from "../../../utils/checkExpiresDate";
import RoutePaths from "../../../routes/route-paths";
import {
  ChangeConfirmPasswordDto,
  sendConfirmPassword,
} from "../../../store/change-confirm-password/change-confirm-password.actions";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { history } from "../../../store/configureStore";
import { useTranslation } from "react-i18next";

interface FormData {
  password: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const locationService = LocationService.getInstance(history.location.search);

  useEffect(() => {
    const expiresDate = locationService.getExpiresAtValue();
    if (isDateExpired(expiresDate)) {
      history.push(RoutePaths.ChangePasswordFailure);
    }
  });

  const onCancelHandler = () => {
    history.push(RoutePaths.Login);
  };

  const submitHandler: any = (fieldData: FormData) => {
    const token = locationService.getToken();
    const userId = locationService.getUserId();
    const data: ChangeConfirmPasswordDto = { ...fieldData, token, userId };
    dispatch(sendConfirmPassword(data));
  };

  return (
    <TransitionsModal open={true}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Passwort ändern ")}</Typography>
        <Typography className={classes.description}>{t("Neues Passwort eintragen")}</Typography>
        <ChangePasswordForm onSubmit={submitHandler} />
        <AppButton className={classes.cancelBtn} handler={onCancelHandler}>
          {t("ABBRECHEN")}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ChangePassword;
