import { ChangeEvent, FunctionComponent, useState, useCallback } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./copy-checklisten-eingabe.styles";
import AppButton from "../../common/app-button/app-button";
import { TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ISyncedChecklistenEingabeDto } from "../../../db/database";
import RoutePaths from "../../../routes/route-paths";

interface Props extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
  checklistenEingabeForCopy: ISyncedChecklistenEingabeDto | undefined;
  betriebName: string;
  betriebVvvo: string;
}

export interface ConnectedDispatch {
  setCopyOfChecklistenEingabe: (checklistenEingabe: ISyncedChecklistenEingabeDto) => void;
  navigateTo: (path: string) => void;
}

const CopyChecklistenEingabeComponent: FunctionComponent<Props> = props => {
  const {
    open,
    closeModal,
    checklistenEingabeForCopy,
    betriebName,
    betriebVvvo,
    setCopyOfChecklistenEingabe,
    navigateTo,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    setShouldValidate(false);
  }, []);

  const handleConfirmButton = useCallback(() => {
    if (checklistenEingabeForCopy && value) {
      const { checklistenId, betriebId } = checklistenEingabeForCopy;
      setCopyOfChecklistenEingabe({ ...checklistenEingabeForCopy, checklistenEingabeName: value });
      navigateTo(
        `${RoutePaths.Checkliste}/${checklistenId}/${betriebId}/${betriebName}/${betriebVvvo}/${value}`
      );
    } else {
      setShouldValidate(true);
    }
  }, [betriebName, betriebVvvo, checklistenEingabeForCopy, navigateTo, setCopyOfChecklistenEingabe, value]);

  return (
    <TransitionsModal closeModal={closeModal} open={open} sizeWidth="750px">
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Checkliste als Vorlage nutzen")}</Typography>
        <div className={classes.descriptionContainer}>
          <Typography className={classes.description}>
            {t(
              "Möchten Sie die Inhalte der ausgewählten Checkliste als Vorlage für eine neue Checkliste laden?"
            )}
          </Typography>
          <Typography className={classes.description}>
            {t("Geben Sie bitte einen Namen für die neue Checkliste ein")}:
          </Typography>
        </div>
        <TextField
          placeholder="Name"
          variant="outlined"
          required={true}
          value={value}
          onChange={handleInputChange}
          className={classes.textfield}
          error={shouldValidate}
          helperText={shouldValidate && t("Wert wird benötigt")}
        />
        <div className={classes.buttons}>
          <AppButton className={classes.cancelButton} handler={closeModal}>
            {t("ABBRECHEN")}
          </AppButton>
          <AppButton className={classes.confirmButton} handler={handleConfirmButton}>
            {t("INHALTE LADEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default CopyChecklistenEingabeComponent;
