import OpenDraftComponent, { ConnectedDispatch } from "./open-draft.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { setDraftChecklistenEingabe } from "../../../store/checklisten-eingabe/checklisten-eingabe.actions";
import { ISyncedChecklistenEingabeDto } from "../../../db/database";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setDraftChecklistenEingabe: (checklistenEingabe: ISyncedChecklistenEingabeDto) =>
    dispatch(setDraftChecklistenEingabe(checklistenEingabe)),
  navigateTo: (path: string) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(OpenDraftComponent);
