import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
    fontSize: "14pt",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16pt",
    marginBottom: "20px",
    marginTop: "10px",
  },
  description: {
    marginBottom: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  approveButton: {
    marginTop: "10px",
    fontSize: "16px",
    backgroundColor: Colors.LightGreen,
  },
  discardButton: {
    marginRight: "35px",
    marginTop: "10px",
    fontSize: "16px",
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
  },
}));

export default useStyles;
