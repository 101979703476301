import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
    fontSize: "14pt",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16pt",
    marginBottom: "20px",
    marginTop: "10px",
  },
  description: {
    marginBottom: "20px",
  },
}));

export default useStyles;
