import { Action } from "../action";
import { ICreateUserRequestDto, IUserDto, ISendUserCreationEmailRequestDto } from "../../api/backend-api-v1";

export enum RegistrationActionType {
  SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE = "SAVE_USER_FAILURE",
  SAVE_USER = "SAVE_USER",
  RESEND_REGISTRATION_EMAIL = "RESEND_REGISTRATION_EMAIL",
  RESEND_REGISTRATION_EMAIL_SUCCESS = "RESEND_REGISTRATION_EMAIL_SUCCESS",
  RESEND_REGISTRATION_EMAIL_FAILURE = "RESEND_REGISTRATION_EMAIL_FAILURE",
}

export const saveUser = (user: ICreateUserRequestDto): Action<ICreateUserRequestDto> => ({
  type: RegistrationActionType.SAVE_USER,
  payload: user,
});

export const saveUserSucces = (user: IUserDto): Action<IUserDto> => ({
  type: RegistrationActionType.SAVE_USER_SUCCESS,
  payload: user,
});

export const saveUserFailure = (errorMessage: string): Action<string> => ({
  type: RegistrationActionType.SAVE_USER_FAILURE,
  payload: errorMessage,
});

export const resendRegistrationEmail = (
  data: ISendUserCreationEmailRequestDto
): Action<ISendUserCreationEmailRequestDto> => ({
  type: RegistrationActionType.RESEND_REGISTRATION_EMAIL,
  payload: data,
});

export const resendRegistrationEmailSuccess = (): Action<null> => ({
  type: RegistrationActionType.RESEND_REGISTRATION_EMAIL_SUCCESS,
  payload: null,
});

export const resendRegistrationEmailFailure = (errorMessage: string): Action<string> => ({
  type: RegistrationActionType.RESEND_REGISTRATION_EMAIL_FAILURE,
  payload: errorMessage,
});
