import logger from "../../logger";
import RoutePaths from "../../routes/route-paths";
import { ErrorActionType } from "./error.actions";
import { takeEvery } from "redux-saga/effects";
import { Dispatch } from "redux";
import { history } from "../../store/configureStore";

const logError = logger.error("error.saga");

export function error() {
  try {
    history.push(RoutePaths.Error);
  } catch (e) {
    logError(e.message);
    history.push(RoutePaths.Error);
  }
}

export default function* errorSaga(dispatch: Dispatch) {
  yield takeEvery(ErrorActionType.ERROR_OCCURRED, error);
}
