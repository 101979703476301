import CopyChecklistenEingabeComponent, { ConnectedDispatch } from "./copy-checklisten-eingabe.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { setCopyOfChecklistenEingabe } from "../../../store/checklisten-eingabe/checklisten-eingabe.actions";
import { ISyncedChecklistenEingabeDto } from "../../../db/database";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setCopyOfChecklistenEingabe: (checklistenEingabe: ISyncedChecklistenEingabeDto) =>
    dispatch(setCopyOfChecklistenEingabe(checklistenEingabe)),
  navigateTo: (path: string) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(CopyChecklistenEingabeComponent);
