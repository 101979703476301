import { Colors } from "../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  field: {
    width: "370px",
    borderRadius: "10px",
    marginBottom: "15px",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
  },
  approveButton: {
    marginTop: "10px",
    fontSize: "16px",
    backgroundColor: Colors.LightGreen,
  },
  discardButton: {
    marginTop: "10px",
    fontSize: "16px",
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
  },
  passwordField: {
    position: "relative",
  },
}));

export default useStyles;
