import { Action } from "../action";
import { IConfirmEmailRequestDto, IChangeEmailRequestDto } from "../../api/backend-api-v1";

export enum ConfirmEmailActionType {
  CONFIRM_CREATION_EMAIL = "CONFIRM_CREATION_EMAIL",
  CONFIRM_CREATION_EMAIL_SUCCESS = "CONFIRM_CREATION_EMAIL_SUCCESS",
  CONFIRM_CREATION_EMAIL_FAILURE = "CONFIRM_CREATION_EMAIL_FAILURE",
  CONFIRM_CHANGE_EMAIL = "CONFIRM_CHANGE_EMAIL",
  CONFIRM_CHANGE_EMAIL_SUCCESS = "CONFIRM_CHANGE_EMAIL_SUCCESS",
  CONFIRM_CHANGE_EMAIL_FAILURE = "CONFIRM_CHANGE_EMAIL_FAILURE",
}

export const confirmCreationEmail = (data: IConfirmEmailRequestDto): Action<IConfirmEmailRequestDto> => ({
  type: ConfirmEmailActionType.CONFIRM_CREATION_EMAIL,
  payload: data,
});

export const confirmCreationEmailSuccess = (isCreated: boolean): Action<boolean> => ({
  type: ConfirmEmailActionType.CONFIRM_CREATION_EMAIL_SUCCESS,
  payload: isCreated,
});

export const confirmCreationEmailFailure = (errorMessage: string): Action<string> => ({
  type: ConfirmEmailActionType.CONFIRM_CREATION_EMAIL_FAILURE,
  payload: errorMessage,
});

export const confirmChangeEmail = (data: IChangeEmailRequestDto): Action<IChangeEmailRequestDto> => ({
  type: ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL,
  payload: data,
});

export const confirmChangeEmailSuccess = (isChanged: boolean): Action<boolean> => ({
  type: ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL_SUCCESS,
  payload: isChanged,
});

export const confirmChangeEmailFailure = (errorMessage: string): Action<string> => ({
  type: ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL_FAILURE,
  payload: errorMessage,
});
