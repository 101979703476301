import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import AuthService from "../../shared/services/auth.service";
import RoutePaths from "../../routes/route-paths";
import { IConfirmEmailRequestDto, IChangeEmailRequestDto } from "../../api/backend-api-v1";
import {
  ConfirmEmailActionType,
  confirmCreationEmailSuccess,
  confirmCreationEmailFailure,
  confirmChangeEmailSuccess,
  confirmChangeEmailFailure,
} from "./confirm-creation-change-email.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { takeLeading, put, call } from "redux-saga/effects";
import { Dispatch } from "redux";
import { Action } from "../action";
import { history } from "../../store/configureStore";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";

let backendClient: BackendClient;
const logError = logger.error("confirmEmail.saga");
const authService = AuthService.getInstance();

export function* emailConfirmCreation(action: Action<IConfirmEmailRequestDto>) {
  try {
    yield call([backendClient, apiMethods.ConfirmCreationEmail], action.payload);
    yield put(confirmCreationEmailSuccess(true));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CONFIRM_CREATION_EMAIL)));
  } catch (e) {
    logError(e.message);
    yield put(confirmCreationEmailFailure(e.message));
  }
}

export function* emailConfirmChange(action: Action<IChangeEmailRequestDto>) {
  try {
    yield call([backendClient, apiMethods.ConfirmChangeEmail], action.payload);
    yield call([backendClient, apiMethods.Logout]);
    authService.removeAccessToken();
    history.push(RoutePaths.Login);
    yield put(confirmChangeEmailSuccess(true));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CONFIRM_CHANGE_EMAIL)));
  } catch (e) {
    logError(e.message);
    history.push(RoutePaths.Login);
    yield put(confirmChangeEmailFailure(e.message));
    yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_CONFIRM_CREATION_CHANGE_EMAIL)));
  }
}

export default function* confirmCreationChangeEmailSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ConfirmEmailActionType.CONFIRM_CREATION_EMAIL, emailConfirmCreation);
  yield takeLeading(ConfirmEmailActionType.CONFIRM_CHANGE_EMAIL, emailConfirmChange);
}
