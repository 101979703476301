import { FunctionComponent, MouseEventHandler, useEffect, useState } from "react";
import { Field, reduxForm, SubmitHandler } from "redux-form";
import { useStyles } from "./betriebstaette-auth.style";
import { IBetriebDto, IVvvoDto } from "../../api/backend-api-v1";
import renderTextField from "../../components/common/render-text-field/render-text-field";
import {
  required,
  ExactLengths,
  MaxLengths,
  email,
  isNumber,
} from "../../utils/authValidation/auth-validation";
import AppButton from "../../components/common/app-button/app-button";
import { ButtonSize } from "../../shared/buttons-style";
import { FormName } from "../../shared/form-name";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import VvvoExistingModalWindow from "../../components/modal-windows/vvvo-existing/vvvo-existing.component";
import VvvoNewModalWindow from "../../components/modal-windows/vvvo-new/vvvo-new.component";
import { useTranslation } from "react-i18next";
import BetriebExistsModalWindow from "../../components/modal-windows/betrieb-exists/betrieb-exists.component";
import { Typography } from "@material-ui/core";
import { FieldLabel, FieldName } from "../../shared/field-data";
import { LoadingStatus } from "../../store/loading-status";
import PreLoader from "../../components/preloader/preloader.component";

interface FieldsValue {
  email?: string;
  name?: string;
  ort?: string;
  postleitzahl?: string;
  strasse?: string;
  vorname?: string;
  vvvo?: string;
}

export interface ConnectedDispatch {
  setCurrentTitle: (title: string) => void;
  openModalWindow: (isOpen: boolean) => void;
  resetBetriebState: (resetFlag: boolean) => void;
  toggleExistingVvvoModalWindow: (isOpen: boolean) => void;
  resetForm: (formName: string) => void;
  changeVvvo: (form: string, field: string, value: string) => void;
  authorizeNewBetriebVVVO: (modifiedBetrieb: IBetriebDto) => void;
  authorizeExistingBetriebVVVO: (modifiedBetrieb: IBetriebDto) => void;
  checkVvvo: (vvvo: string) => void;
}

export interface ConnectedState {
  fields: FieldsValue;
  isOpen: boolean;
  isExistingVvvoOpen: boolean;
  isBetriebExists: boolean;
  existedEmail?: string;
  isAuthorizationExists: boolean;
  isAuthorizationConfirmed: boolean;
  betriebstaetteStatus: LoadingStatus;
  logoutStatus: LoadingStatus;
}

interface Props extends ConnectedState, ConnectedDispatch {
  handleSubmit: SubmitHandler;
  handleVvvoPrufen: MouseEventHandler;
  vvvo: IVvvoDto;
  isUnauthorizeBetrieb: boolean;
}

const BetriebStaetteAuth: FunctionComponent<Props> = props => {
  const {
    handleSubmit,
    handleVvvoPrufen,
    isOpen,
    vvvo,
    isBetriebExists,
    existedEmail,
    isAuthorizationExists,
    isAuthorizationConfirmed,
    isExistingVvvoOpen,
    setCurrentTitle,
    openModalWindow,
    resetBetriebState,
    toggleExistingVvvoModalWindow,
    resetForm,
    changeVvvo,
    isUnauthorizeBetrieb,
    betriebstaetteStatus,
    logoutStatus,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(!isOpen);
  const [formDisabled, setFormDisabled] = useState(false);
  const [existingWindowOpen, setExistingWindowOpen] = useState(isExistingVvvoOpen);
  const [isVvvoComplete, setIsVvvvoComplete] = useState(false);

  const handleWindow: MouseEventHandler = () => {
    setOpen(false);
    openModalWindow(false);
  };

  const closeExistingVvvoModal: MouseEventHandler = () => {
    setExistingWindowOpen(false);
    toggleExistingVvvoModalWindow(false);
  };

  const changeVVVOHandler = (e: any) => {
    if (e.target.value.length === 15) {
      setIsVvvvoComplete(true);
    } else {
      setIsVvvvoComplete(false);
    }
    resetForm("betriebstaetteAuth");
    changeVvvo("betriebstaetteAuth", "vvvo", vvvo.value);
    setFormDisabled(true);
  };

  useEffect(() => {
    setOpen(isOpen);
    setFormDisabled(false);
    return () => {
      resetBetriebState(true);
    };
  }, [isOpen, resetBetriebState]);

  useEffect(() => {
    setExistingWindowOpen(isExistingVvvoOpen);
    setFormDisabled(false);
  }, [isExistingVvvoOpen]);

  useEffect(() => {
    setCurrentTitle(t("Betriebsstätte hinzufügen"));
  }, [setCurrentTitle, t]);

  useEffect(() => {
    setFormDisabled(true);
  }, []);

  useEffect(() => {
    if (isUnauthorizeBetrieb) {
      setFormDisabled(false);
    }
  }, [isUnauthorizeBetrieb, formDisabled]);

  if (betriebstaetteStatus.pending || logoutStatus.pending) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return (
    <div>
      <AppBarContainer />
      <div className={classes.wrapper}>
        <div className={classes.wrapperHeader}>
          <Typography>{t("Text - Betriebsstätte hinzufügen")}</Typography>
        </div>
        <div className={classes.formWrapper}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.VVVO)}</label>
              <Field
                className={classes.field}
                name={FieldName.VVVO}
                component={renderTextField}
                validate={[required, isNumber, ExactLengths.LENGTH_15]}
                type="text"
                onChange={changeVVVOHandler}
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.BETRIEB_NAME)}</label>
              <Field
                className={classes.field}
                name={FieldName.BETRIEB_NAME}
                component={renderTextField}
                validate={!isBetriebExists ? [required, MaxLengths.LENGTH_50] : []}
                disabled={formDisabled === false ? isBetriebExists : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.VORNAME)}</label>
              <Field
                className={classes.field}
                name={FieldName.VORNAME_GERMAN}
                component={renderTextField}
                validate={!isBetriebExists ? [required, MaxLengths.LENGTH_50] : []}
                disabled={formDisabled === false ? isBetriebExists : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.ADRESSE)}</label>
              <Field
                className={classes.field}
                name={FieldName.ADRESSE_GERMAN}
                component={renderTextField}
                validate={!isBetriebExists ? [required, MaxLengths.LENGTH_50] : []}
                disabled={formDisabled === false ? isBetriebExists : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.PLZ)}</label>
              <Field
                className={classes.field}
                name={FieldName.PLZ_GERMAN}
                component={renderTextField}
                validate={!isBetriebExists ? [required, MaxLengths.LENGTH_10] : []}
                disabled={formDisabled === false ? isBetriebExists : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.ORT)}</label>
              <Field
                className={classes.field}
                name={FieldName.ORT_GERMAN}
                component={renderTextField}
                validate={!isBetriebExists ? [required, MaxLengths.LENGTH_50] : []}
                disabled={formDisabled === false ? isBetriebExists : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                type="text"
              />
            </div>
            <div className={classes.fieldWrapper}>
              <label className={classes.label}>{t(FieldLabel.EMAIL)}</label>
              <Field
                className={classes.field}
                name={FieldName.EMAIL}
                component={renderTextField}
                validate={!isBetriebExists || !existedEmail ? [required, email] : []}
                disabled={formDisabled === false ? isBetriebExists && !!existedEmail : formDisabled}
                formName={formDisabled ? "" : FormName.BETRIEBSTATTE_FORM}
                label={!formDisabled ? isBetriebExists && existedEmail : ""}
                type="text"
              />
            </div>
            {formDisabled ||
            (isBetriebExists && isAuthorizationConfirmed && isAuthorizationExists) ||
            (isBetriebExists && !isAuthorizationConfirmed && isAuthorizationExists) ? (
              <div className={classes.autorisierungButton}>
                <AppButton
                  type="submit"
                  size={ButtonSize.LARGE_BUTTON}
                  disabled={formDisabled}
                  className={classes.disabledButton}
                >
                  {t("AUTORISIERUNG ANFORDERN")}
                </AppButton>
              </div>
            ) : (
              <div className={classes.autorisierungButton}>
                <AppButton className={classes.enabledButton} type="submit" size={ButtonSize.LARGE_BUTTON}>
                  {t("AUTORISIERUNG ANFORDERN")}
                </AppButton>
              </div>
            )}
          </form>
          <div className={classes.proffenButton}>
            <AppButton
              className={isVvvoComplete ? classes.enabledButton : classes.disabledButton}
              size={ButtonSize.MEDIUM_BUTTON}
              handler={isVvvoComplete ? handleVvvoPrufen : undefined}
            >
              {t("VVVO PRÜFEN")}
            </AppButton>
          </div>
        </div>
        {open && <VvvoNewModalWindow closeModal={handleWindow} open={open} vvvo={vvvo} />}
        {isAuthorizationExists && isBetriebExists && existingWindowOpen && (
          <VvvoExistingModalWindow
            closeModal={closeExistingVvvoModal}
            open={existingWindowOpen}
            vvvo={vvvo}
          />
        )}
        {isBetriebExists && !isAuthorizationConfirmed && isAuthorizationExists && existingWindowOpen && (
          <BetriebExistsModalWindow
            closeModal={closeExistingVvvoModal}
            open={existingWindowOpen}
            vvvo={vvvo}
          />
        )}
      </div>
    </div>
  );
};
export default reduxForm<any, any>({ form: "betriebstaetteAuth" })(BetriebStaetteAuth);
