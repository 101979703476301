import { useState, useEffect, FunctionComponent } from "react";
import { Field, reduxForm, SubmitHandler } from "redux-form";
import { Typography } from "@material-ui/core";
import iqImages from "../../images/iq-images";
import { useStyles } from "./registration.style";
import renderTextField from "../../components/common/render-text-field/render-text-field";
import AppButton from "../../components/common/app-button/app-button";
import { Link } from "react-router-dom";
import {
  required,
  MinLengths,
  MaxLengths,
  email,
  passwordsMatch,
  password,
} from "../../utils/authValidation/auth-validation";
import ShowPasswordButton from "../../components/common/show-password-button/show-password-button.component";
import SuccessfulRegistrationContainer from "../../components/modal-windows/successful-registration/successful-registration.container";
import RoutePaths from "../../routes/route-paths";
import Cookies from "../../components/modal-windows/cookies/cookies.component";
import TermsAndConditions from "../../components/modal-windows/terms-and-conditions/terms-and-conditions.component";
import PrivacyPolicy from "../../components/modal-windows/privacy-policy/privacy-policy.component";
import { ButtonSize } from "../../shared/buttons-style";
import { useDispatch } from "react-redux";
import { openModalWindow } from "../../store/common/common.actions";
import { PlaceHolder, FieldName } from "../../shared/field-data";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../store/loading-status";
import PreLoader from "../../components/preloader/preloader.component";

export interface ConnectedState {
  registrationStatus: LoadingStatus;
  loginStatus: LoadingStatus;
}

interface Props extends ConnectedState {
  handleSubmit: SubmitHandler;
  isOpen: boolean;
}

const SignUp: FunctionComponent<Props> = ({ handleSubmit, isOpen, registrationStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(!isOpen);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
  const [isAGBOpen, setIsAGBOpen] = useState(false);
  const [isCookiesOpen, setIsCookiesOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const handleWindow = () => {
    setOpen(false);
    dispatch(openModalWindow(false));
  };

  const switchPasswordType = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const switchConfirmPasswordType = () => {
    setIsConfirmPasswordHidden(!isConfirmPasswordHidden);
  };

  const AGBHandler = () => {
    setIsAGBOpen(!isAGBOpen);
  };

  const cookiesHandler = () => {
    setIsCookiesOpen(!isCookiesOpen);
  };

  const privacyPolicyHandler = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (registrationStatus.pending) {
    return (
      <>
        <PreLoader />
      </>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperHeader}>
        <img className={classes.logo} src={iqImages.logo} alt={"Icon"} />
        <h2 className={classes.pageTitle}>{t("Registrierung")}</h2>
      </div>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.emailDescription}>{t("E-Mail eingeben - Registrierung")}</div>
        <Field
          className={classes.field}
          name={FieldName.EMAIL}
          component={renderTextField}
          placeholder={t(PlaceHolder.EMAIL)}
          validate={[required, email]}
          type="email"
        />
        <div className={classes.passwordDescription}>
          {t("Passwort Richtlinien")}
          <ul className={classes.list}>
            <li>{t("Passwort Richtlinien - 1")}</li>
            <li>{t("Passwort Richtlinien - 2")}</li>
          </ul>
        </div>
        <div className={classes.passwordField}>
          <Field
            className={classes.field}
            name={FieldName.PASSWORD}
            component={renderTextField}
            placeholder={t(PlaceHolder.PASSWORD)}
            validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
            type={isPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton switchHandler={switchPasswordType} isPasswordHidden={isPasswordHidden} />
        </div>
        <div className={classes.passwordField}>
          <Field
            className={classes.field}
            name={FieldName.CONFIRMED_PASSWORD}
            component={renderTextField}
            placeholder={t(PlaceHolder.CONFIRMED_PASSWORD)}
            validate={[required, passwordsMatch]}
            type={isConfirmPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton
            switchHandler={switchConfirmPasswordType}
            isPasswordHidden={isConfirmPasswordHidden}
          />
        </div>
        <Typography className={classes.license}>
          {t("Einverständniserklärung - 1")}{" "}
          <Link onClick={AGBHandler} to="#">
            {t("AGB")}
          </Link>{" "}
          {t("Einverständniserklärung - 2")}{" "}
          <Link onClick={privacyPolicyHandler} to="#">
            {t("Datenschutzrichtlinien")}
          </Link>{" "}
          {t("Einverständniserklärung - 3")}{" "}
          <Link onClick={cookiesHandler} to="#">
            {t("Nutzung von Cookies")}
          </Link>{" "}
          {t("Einverständniserklärung - 4")}
        </Typography>
        {isAGBOpen && <TermsAndConditions closeModal={AGBHandler} open={isAGBOpen} />}
        {isCookiesOpen && <Cookies closeModal={cookiesHandler} open={isCookiesOpen} />}
        {isPrivacyPolicyOpen && (
          <PrivacyPolicy closeModal={privacyPolicyHandler} open={isPrivacyPolicyOpen} />
        )}
        <div className={classes.button}>
          <AppButton type="submit" size={ButtonSize.MEDIUM_BUTTON}>
            {t("REGISTRIEREN")}
          </AppButton>
        </div>
      </form>
      <Link to={RoutePaths.Login}>{t("Zurück zum Login")}</Link>
      {open && <SuccessfulRegistrationContainer closeModal={handleWindow} open={open} />}
    </div>
  );
};
export default reduxForm<any, any>({ form: "signup" })(SignUp);
