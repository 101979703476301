import { FunctionComponent } from "react";
import ContactDetailsForm from "../../components/contact-details-form/contact-details-form.component";
import iqImages from "../../images/iq-images";
import useStyles from "./contact-details.styles";
import { Typography } from "@material-ui/core";
import { UserGroupValue } from "../../shared/field-data";
import { useTranslation } from "react-i18next";

export interface ContactDetailsDto {
  name: string;
  street: string;
  city: string;
  firstName: string;
  postCode: string;
  country: string;
  userGroup: string;
  companyBerater?: string;
  companyTierarzt?: string;
  company?: string;
}

export interface ConnectedDispatch {
  resetField: (form: string, field: string, value: string) => void;
  saveContactDetails: (data: ContactDetailsDto) => void;
}

export interface ConnectedState {
  fields: ContactDetailsDto | {};
}

interface Props extends ConnectedDispatch, ConnectedState {}

const ContactDetails: FunctionComponent<Props> = ({ fields, resetField, saveContactDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userGroupDescription = t("Welcher Nutzergruppe gehören Sie an:");
  const buttonName = t("BESTÄTIGEN");

  const submitHandler: any = (data: ContactDetailsDto) => {
    switch (data.userGroup) {
      case UserGroupValue.BERATER:
        const { companyBerater, ...restBerater } = data;
        saveContactDetails({ ...restBerater, company: companyBerater });
        break;
      case UserGroupValue.TIERARZT:
        const { companyTierarzt, ...restTierarzt } = data;
        saveContactDetails({ ...restTierarzt, company: companyTierarzt });
        break;
      default:
        saveContactDetails(data);
        break;
    }
  };

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.header}>
        <img className={classes.logo} src={iqImages.logo} alt="PIG-CHECK" />
        <Typography className={classes.title} variant="h4">
          {t("Meine Kontaktdaten")}
        </Typography>
      </div>
      <Typography className={classes.description}>
        {t("Herzlich willkommen bei PIG-CHECK,")}
        <br />
        {t("Zur Nutzung des vollen Funktionsumfangs ist die Angabe ihrer Kontaktdaten notwendig.")}
        <br />
        {t("Meine Daten ändern.")}
      </Typography>
      <Typography className={classes.formDescription}>
        {t("Bitte vervollständigen Sie die Angaben:")}
      </Typography>
      <ContactDetailsForm
        onSubmit={submitHandler}
        userGroupDescription={userGroupDescription}
        buttonName={buttonName}
        fields={fields}
        resetField={resetField}
      />
    </div>
  );
};

export default ContactDetails;
