const convertBase64ToPdf = (base64: string) => {
  const binary = atob(base64.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([view], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  return url;
};

export default convertBase64ToPdf;
