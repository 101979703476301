import { useEffect, FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./confirm-authorize-vvvo.styles";
import iqImages from "../../../images/iq-images";
import LocationService from "../../../shared/services/location.service";
import { history } from "../../../store/configureStore";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../../store/loading-status";

export interface ConnectedState {
  translationsStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  confirmAuthorizeVvvo: (authorizationId: number, token: string) => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const ConfirmAuthorizeVvvo: FunctionComponent<Props> = ({ confirmAuthorizeVvvo, translationsStatus }) => {
  const classes = useStyles();
  const locationService = LocationService.getInstance(history.location.search);
  const { t } = useTranslation();

  useEffect(() => {
    // TODO: It's a quick and dirty solution, we need to wait until translations will be uploaded for preventing unnecessary rendering.
    if (translationsStatus.success) {
      const authorizationId = +locationService.getAuthorizationId();
      const token = locationService.getToken();
      confirmAuthorizeVvvo(authorizationId, token);
    }
  });

  return (
    <TransitionsModal open={true}>
      <div className={classes.flexWrapper}>
        <img src={iqImages.logo} alt="PIG-CHECK" className={classes.logo} />
        <Typography className={classes.title}>{t("Zugriff erteilt!")}</Typography>
        <Typography className={classes.description}>
          {t("Sie haben den Zugriff auf Ihre Stammdaten bestätigt.")}
        </Typography>
      </div>
    </TransitionsModal>
  );
};

export default ConfirmAuthorizeVvvo;
