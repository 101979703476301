import { takeEvery, put, call } from "redux-saga/effects";

import I18n from "i18next";
import logger from "../../logger";
import { loadChecklistenSuccess, loadChecklistenFailure, ChecklistenEntities } from "./checklisten.actions";
import { IChecklisteDto } from "../../api/backend-api-v1";
import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import { Dispatch } from "redux";
import { normalize, NormalizedSchema } from "normalizr";
import { checklistenSchema } from "./checklisten.schema";
import { ChecklistenActionType } from "./checklisten.actions";
import { showFailedRequest } from "../notifications/notifications.actions";
import { FailureMessages } from "../../shared/notifications-messages";

const logError = logger.error("checklisten.saga");
const networkErrorMessage = "Network Error";

let backendClient: BackendClient;

export function* loadChecklisten() {
  try {
    const checklisten: IChecklisteDto[] = yield call([backendClient, apiMethods.GetChecklisten]);

    const normalizedData: NormalizedSchema<ChecklistenEntities, number[]> = normalize(checklisten, [
      checklistenSchema,
    ]);

    yield put(loadChecklistenSuccess(normalizedData.entities));
  } catch (e) {
    logError("Could not fetch checklisten", e.message);
    if (e.message === networkErrorMessage) {
      yield put(showFailedRequest(I18n.t(FailureMessages.ERROR_OF_FETCHING_DATA)));
    }
    yield put(loadChecklistenFailure(e.message));
  }
}

export default function* checklistenSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(ChecklistenActionType.LOAD_CHECKLISTEN, loadChecklisten);
}
