import createReducer from "../createReducer";
import { Action } from "../action";
import { loadSuccess, loadFailed, loadPending, LoadingStatus, loadInitial } from "../loading-status";
import { I18NActionType } from "./i18n.actions";

export interface I18NState {
  loadStatus: LoadingStatus;
  translationLoaded?: boolean;
}

export const initialState: I18NState = {
  loadStatus: loadInitial(),
  translationLoaded: false,
};

export default createReducer(initialState, {
  [I18NActionType.LOAD_TRANSLATIONS](state: I18NState): I18NState {
    return { ...state, loadStatus: loadPending() };
  },
  [I18NActionType.LOAD_TRANSLATIONS_SUCCESS](state: I18NState): I18NState {
    return { ...state, loadStatus: loadSuccess(), translationLoaded: true };
  },
  [I18NActionType.LOAD_TRANSLATIONS_FAILURE](state: I18NState, action: Action<string>): I18NState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
});
