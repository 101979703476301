import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    tableControls: {
      margin: "10px",
    },
    tableToolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "7px",
    },
    deleteButton: {
      marginLeft: "15px",
      backgroundColor: Colors.Red,
      "&:hover": {
        opacity: 0.75,
      },
      "&:focus": {
        outline: "none",
        border: "none",
        opacity: 0.75,
      },
    },
    addButton: {
      marginRight: "15px",
    },
    deleteChecklistDescription: {
      textAlign: "center",
      padding: "0px 0 10px 0",
      color: Colors.Grey,
    },
    buttonSection: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    cancelBtn: {
      backgroundColor: Colors.Grey,
      "&:hover": {
        opacity: 0.75,
      },
    },
    emptyChecklisten: {
      color: Colors.Grey,
      marginTop: "20px",
      marginLeft: "13px",
    },
    syncedIcon: {
      color: Colors.AmpelGruen,
    },
    unsyncedIcon: {
      color: Colors.PrimaryMain,
    },
    deleteChecklistButton: {
      backgroundColor: Colors.Red,
      "&:hover": {
        opacity: 0.75,
        backgroundColor: Colors.Red,
      },
    },
    actionIcon: {
      color: Colors.White,
    },
    syncedButton: {
      backgroundColor: Colors.DarkGreen,
      "&:hover": {
        opacity: 0.75,
        backgroundColor: Colors.DarkGreen,
      },
    },
    unsyncedButton: {
      backgroundColor: Colors.PrimaryMain,
      "&:hover": {
        opacity: 0.75,
        backgroundColor: Colors.PrimaryMain,
      },
    },
    invalidChip: {
      backgroundColor: Colors.AmpelGelb,
      color: "black",
      fontWeight: "bolder",
    },
    draftChip: {
      color: "black",
      backgroundColor: Colors.PrimaryMain,
      fontWeight: "bolder",
    },
  })
);

export default useStyles;
