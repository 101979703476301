import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
  },
  logo: {
    height: "100px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    fontWeight: "bold",
    marginLeft: "80px",
  },
  description: {
    color: Colors.Grey,
  },
  formDescription: {
    width: "760px",
    marginTop: "20px",
  },
}));

export default useStyles;
