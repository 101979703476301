import { FunctionComponent, MouseEventHandler } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./logout-offline.styles";
import AppButton from "../../common/app-button/app-button";
import { ButtonSize } from "../../../shared/buttons-style";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { logout } from "../../../store/logout/logout.actions";
import { useDispatch } from "react-redux";

interface Props {
  open: boolean;
  closeModal: MouseEventHandler;
}

const LogoutOfflineModalWindow: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onLogoutHandler = () => {
    dispatch(logout());
  };

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Checklisten wurden nicht synchronisiert")}</Typography>
        <Typography className={classes.description}>
          {t(
            "Wenn Sie sich nun ausloggen gehen alle nicht synchronisierten Änderungen an Ihren Checklisten verloren."
          )}
        </Typography>
        <div className={classes.buttonWrapper}>
          <AppButton className={classes.discardButton} size={ButtonSize.MEDIUM_BUTTON} handler={closeModal}>
            {t("ABBRECHEN")}
          </AppButton>
          <AppButton className={classes.approveButton} handler={onLogoutHandler}>
            {t("AUSLOGGEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default LogoutOfflineModalWindow;
