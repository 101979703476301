import { FunctionComponent, MouseEventHandler, useEffect, useState } from "react";
import { Field, reduxForm, SubmitHandler } from "redux-form";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AppButton from "../../components/common/app-button/app-button";
import iqImages from "../../images/iq-images";
import { PlaceHolder, FieldName } from "../../shared/field-data";
import renderTextField from "../../components/common/render-text-field/render-text-field";
import useStyles from "./login.styles";
import PartnerSection from "../../components/partner-section/partner-section";
import ResetPassword from "../../components/modal-windows/reset-password/reset-password.component";
import RoutePaths from "../../routes/route-paths";
import { required } from "../../utils/authValidation/auth-validation";
import ShowPasswordButton from "../../components/common/show-password-button/show-password-button.component";
import { useTranslation } from "react-i18next";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import { useDispatch } from "react-redux";
import { setCurrentTitle } from "../../store/common/common.actions";
import { ButtonSize } from "../../shared/buttons-style";
import { LoadingStatus } from "../../store/loading-status";
import PreLoader from "../../components/preloader/preloader.component";

export interface ConnectedState {
  resetPasswordStatus: LoadingStatus;
}

interface Props extends ConnectedState {
  handleSubmit: SubmitHandler;
}

const Login: FunctionComponent<Props> = ({ handleSubmit, resetPasswordStatus }, props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openWindow: MouseEventHandler = () => {
    setOpen(true);
  };

  const closeWindow: MouseEventHandler = () => {
    setOpen(false);
  };

  const switchPasswordType = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  useEffect(() => {
    dispatch(setCurrentTitle(""));
  });

  if (resetPasswordStatus.pending) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return (
    <>
      <AppBarContainer />
      <div className={classes.flexWrapper}>
        <img src={iqImages.logo} alt={"PIG-CHECK"} className={classes.logo} />
        <Typography className={classes.description}>
          {t("Text - Login")}{" "}
          <a href="https://www.ringelschwanz.info/" target="blank">
            www.ringelschwanz.info
          </a>
          .
        </Typography>
        <form onSubmit={handleSubmit} className={classes.flexWrapper}>
          <Field
            name={FieldName.USER_NAME}
            placeholder={t(PlaceHolder.EMAIL)}
            component={renderTextField}
            className={classes.field}
            type="text"
            validate={[required]}
          />
          <div className={classes.passwordField}>
            <Field
              name={FieldName.PASSWORD}
              placeholder={t(PlaceHolder.PASSWORD)}
              component={renderTextField}
              className={classes.field}
              type={isPasswordHidden ? "password" : "text"}
              validate={[required]}
            />
            <ShowPasswordButton switchHandler={switchPasswordType} isPasswordHidden={isPasswordHidden} />
          </div>
          <div className={classes.button}>
            <AppButton size={ButtonSize.EXTRA_LARGE_BUTTON} type="submit">
              {t("ANMELDEN")}
            </AppButton>
          </div>
          <div className={classes.links}>
            <Link className={classes.link} to="#" onClick={openWindow}>
              {t("Passwort vergessen?")}
            </Link>
            <Link className={classes.link} to={RoutePaths.Registration}>
              {t("Registrierung")}
            </Link>
          </div>
        </form>
      </div>
      <PartnerSection />
      {open && <ResetPassword closeWindow={closeWindow} open={open} />}
    </>
  );
};
// @ts-ignore
export default reduxForm({ form: "login" })(Login);
