import { Action } from "../action";
import createReducer from "../createReducer";
import { ChecklistenEingabeActionType, DeleteChecklistenEingabeDto } from "./checklisten-eingabe.actions";
import { IPdfDto } from "../../api/backend-api-v2";
import { ISyncedChecklistenEingabeDto } from "../../db/database";
import { loadFailed, LoadingStatus, loadInitial, loadPending, loadSuccess } from "../loading-status";

export interface ChecklistenEingabeState {
  checklistenEingabeDraft: ISyncedChecklistenEingabeDto | undefined;
  copyOfChecklistenEingabe: ISyncedChecklistenEingabeDto | undefined;
  loadStatus: LoadingStatus;
  checklistenEingaben: { [betriebId: number]: ISyncedChecklistenEingabeDto[] };
  lastFetched: Date | undefined;
  checklistenEingabePdf: IPdfDto | undefined;
}

export const initialState: ChecklistenEingabeState = {
  checklistenEingabeDraft: undefined,
  copyOfChecklistenEingabe: undefined,
  loadStatus: loadInitial(),
  checklistenEingaben: {},
  lastFetched: undefined,
  checklistenEingabePdf: undefined,
};

export default createReducer(initialState, {
  [ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState,
    action: Action<ISyncedChecklistenEingabeDto>
  ): ChecklistenEingabeState {
    const { betriebId, isDraft, uniqueId } = action.payload;

    if (isDraft) {
      const shouldUpdateDraft = !!state.checklistenEingaben[betriebId].find(
        item => item.uniqueId === uniqueId
      );
      if (shouldUpdateDraft) {
        return {
          ...state,
          checklistenEingaben: {
            ...state.checklistenEingaben,
            [betriebId]: state.checklistenEingaben[betriebId].map(item => {
              if (item.uniqueId === uniqueId) {
                return { ...item, ...action.payload };
              }
              return item;
            }),
          },
          loadStatus: loadPending(),
        };
      }
    }

    return {
      ...state,
      checklistenEingaben: {
        ...state.checklistenEingaben,
        [betriebId]: [...state.checklistenEingaben[betriebId], action.payload],
      },
      loadStatus: loadPending(),
    };
  },
  [ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE_SUCCESS](
    state: ChecklistenEingabeState,
    action: Action<ISyncedChecklistenEingabeDto>
  ): ChecklistenEingabeState {
    const { uniqueId, betriebId } = action.payload;
    return {
      ...state,
      checklistenEingaben: {
        ...state.checklistenEingaben,
        [betriebId]: state.checklistenEingaben[betriebId].map(item => {
          if (item.uniqueId === uniqueId) {
            return { ...item, synced: 1 };
          }
          return item;
        }),
      },
    };
  },
  [ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE_FAILURE](
    state: ChecklistenEingabeState,
    action: Action<string>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN](
    state: ChecklistenEingabeState
  ): ChecklistenEingabeState {
    return { ...state, loadStatus: loadPending() };
  },
  [ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN_SUCCESS](
    state: ChecklistenEingabeState,
    action: Action<{ [betriebId: number]: ISyncedChecklistenEingabeDto[] }>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      checklistenEingaben: {
        ...state.checklistenEingaben,
        ...action.payload,
      },
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenEingabeActionType.LOAD_LATEST_CHECKLISTEN_EINGABEN](
    state: ChecklistenEingabeState,
    action: Action<{ [betriebId: number]: ISyncedChecklistenEingabeDto[] }>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      checklistenEingaben: {
        ...action.payload,
        ...state.checklistenEingaben,
      },
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN_FAILURE](
    state: ChecklistenEingabeState,
    action: Action<string>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE_SUCCESS](
    state: ChecklistenEingabeState,
    action: Action<DeleteChecklistenEingabeDto>
  ): ChecklistenEingabeState {
    const { uniqueId, betriebId } = action.payload;
    return {
      ...state,
      checklistenEingaben: {
        ...state.checklistenEingaben,
        [betriebId]: state.checklistenEingaben[betriebId].filter(item => item.uniqueId !== uniqueId),
      },
      loadStatus: loadSuccess(),
    };
  },
  [ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE_FAILURE](
    state: ChecklistenEingabeState,
    action: Action<string>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE_SUCCESS](
    state: ChecklistenEingabeState,
    action: Action<IPdfDto>
  ): ChecklistenEingabeState {
    return {
      ...state,
      checklistenEingabePdf: action.payload,
      loadStatus: loadSuccess(),
    };
  },
  [ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE_FAILURE](
    state: ChecklistenEingabeState,
    action: Action<string>
  ): ChecklistenEingabeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [ChecklistenEingabeActionType.RESET_PDF_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState
  ): ChecklistenEingabeState {
    return {
      ...state,
      checklistenEingabePdf: undefined,
    };
  },
  [ChecklistenEingabeActionType.SET_DRAFT_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState,
    action: Action<ISyncedChecklistenEingabeDto>
  ): ChecklistenEingabeState {
    return {
      ...state,
      checklistenEingabeDraft: action.payload,
    };
  },
  [ChecklistenEingabeActionType.REMOVE_DRAFT_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState,
    action: Action<null>
  ): ChecklistenEingabeState {
    return {
      ...state,
      checklistenEingabeDraft: undefined,
    };
  },
  [ChecklistenEingabeActionType.SET_COPY_OF_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState,
    action: Action<ISyncedChecklistenEingabeDto>
  ): ChecklistenEingabeState {
    return {
      ...state,
      copyOfChecklistenEingabe: action.payload,
    };
  },
  [ChecklistenEingabeActionType.REMOVE_COPY_OF_CHECKLISTEN_EINGABE](
    state: ChecklistenEingabeState,
    action: Action<null>
  ): ChecklistenEingabeState {
    return {
      ...state,
      copyOfChecklistenEingabe: undefined,
    };
  },
});
