import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import CloseButton from "../../common/close-button/close-button.component";
import useStyles from "./contacts.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const Contacts: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <CloseButton closeModal={closeModal} />
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}> {t("Kontakt")}</Typography>
        <div>
          <Typography className={classes.contactDetails}>
            {t("Sie haben Fragen zu unseren Services, wir helfen Ihnen gerne weiter.")}
          </Typography>
          <Typography className={classes.contactDetails}>
            IQ-Agrar Service GmbH
            <br />
            Iburger Straße 225
            <br />
            49082 Osnabrück
          </Typography>
          <Typography className={classes.contactDetails}>
            Tel.: +49 (0) 541 / 600 288 80
            <br />
            Fax: +49 (0) 541 / 600 288 90
            <br />
            E-Mail: info@iq-agrar.de
          </Typography>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default Contacts;
