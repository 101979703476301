import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import CloseButton from "../../common/close-button/close-button.component";
import useStyles from "./privacy-policy.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const PrivacyPolicy: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open} sizeWidth="900px">
      <CloseButton closeModal={closeModal} />
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Datenschutzerklärung")}</Typography>
        <div className={classes.textWrapper}>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung")}</Typography>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 2")}</Typography>
          <div className={classes.description}>
            <ul>
              <li>
                <Typography>{t("Webseite")} www.iq-agrar.de</Typography>
              </li>
              <li>
                <Typography>{t("Webseite 2")} portal.iq-agrar.de</Typography>
              </li>
              <li>
                <Typography>
                  {t("Webseite 3")} IQ-Agrar MOBIL: Play Store (Android), Apple App Store (iOS)
                </Typography>
              </li>
              <li>
                <Typography>{t("Webseite 4")} PigMonitor: app.pigmonitor.de</Typography>
              </li>
              <li>
                <Typography>{t("Webseite 5")} PIG-CHECK: app.pig-check.de</Typography>
              </li>
              <li>
                <Typography>{t("Webseite 6")}</Typography>
              </li>
            </ul>
          </div>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 3")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 4")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 5")}</Typography>
          <Typography>
            {t("Text - Datenschutzerklärung 6")}
            <br />
            {t("Text - Datenschutzerklärung 7")}
          </Typography>
          <div>
            <ul>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 8")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 9")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 10")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 11")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 12")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 13")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 14")}</Typography>
              </li>
            </ul>
          </div>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 15")}</Typography>
          <div>
            <ul>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 16")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 17")}</Typography>
              </li>
            </ul>
            <Typography className={classes.description}>{t("Text - Datenschutzerklärung 18")}</Typography>
          </div>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 19")}</Typography>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 20")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 21")}</Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 22")}
            <br />
            {t("Text - Datenschutzerklärung 23")}
          </Typography>
          <div>
            <ul>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 24")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 25")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 26")}</Typography>
              </li>
              <li>
                <Typography>{t("Text - Datenschutzerklärung 27")}</Typography>
              </li>
            </ul>
          </div>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 28")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 29")}</Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 30")}
            <br />
            {t("Text - Datenschutzerklärung 31")}
            <br />
            {t("Text - Datenschutzerklärung 32")}
            <br />
            {t("Text - Datenschutzerklärung 33")}
            <br />
            {t("Text - Datenschutzerklärung 34")}
            <br />
            {t("Text - Datenschutzerklärung 35")}
            <br />
            {t("Text - Datenschutzerklärung 36")}
            <br />
            {t("Text - Datenschutzerklärung 37")}
          </Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 38")}</Typography>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 39")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 59")}.</Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 58")}
            <br />
            {t("Text - Datenschutzerklärung 40")}
            <br />
            {t("Text - Datenschutzerklärung 41")}
          </Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 42")}</Typography>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 43")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 44")}</Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 45")}
            <br />
            {t("Text - Datenschutzerklärung 46")}
            <br />
            {t("Text - Datenschutzerklärung 47")}
            <br />
            {t("Text - Datenschutzerklärung 48")}
            <br />
            {t("Text - Datenschutzerklärung 49")}
          </Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 50")}</Typography>
          <Typography className={classes.description}>{t("Text - Datenschutzerklärung 51")}</Typography>
          <Typography className={classes.topic}>{t("Text - Datenschutzerklärung 52")}</Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 53")}
            <br />
            {t("Text - Datenschutzerklärung 54")}
          </Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 55")}
            <br />
            {t("Text - Datenschutzerklärung 56")}
          </Typography>
          <Typography className={classes.description}>
            IQ-Agrar Service GmbH, Iburger Straße 225, 49082 Osnabrück,
            <br />
            Tel.: +49 (0) 541 / 600288 80,
            <br />
            Fax: +49 (0) 541 / 600 288 90,
            <br />
            E-Mail: info@iq-agrar.de
          </Typography>
          <Typography className={classes.description}>
            {t("Text - Datenschutzerklärung 57")} IQ-Agrar Service GmbH, Stephanie Bering, Iburger Straße 225,
            49082 Osnabrück,
            <br />
            Tel.: +49 (0) 541 / 600 288 80,
            <br />
            Fax: +49 (0) 541 / 600 288 90,
            <br />
            E-Mail: info@iq-agrar.de
          </Typography>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default PrivacyPolicy;
