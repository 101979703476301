import { IUserDto } from "../../api/backend-api-v1";
import { loadFailed, LoadingStatus, loadInitial, loadPending, loadSuccess } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { RegistrationActionType } from "./registration.actions";

export interface RegistrationState {
  user: IUserDto;
  loadStatus: LoadingStatus;
  resendEmailStatus: LoadingStatus;
}

export const initialState: RegistrationState = {
  user: {
    id: "",
    username: "",
  },
  loadStatus: loadInitial(),
  resendEmailStatus: loadInitial(),
};

export default createReducer(initialState, {
  [RegistrationActionType.SAVE_USER](state: RegistrationState): RegistrationState {
    return { ...state, loadStatus: loadPending() };
  },
  [RegistrationActionType.SAVE_USER_SUCCESS](
    state: RegistrationState,
    action: Action<IUserDto>
  ): RegistrationState {
    const user = action.payload;
    return {
      ...state,
      user: { ...user },
      loadStatus: loadSuccess(),
    };
  },
  [RegistrationActionType.SAVE_USER_FAILURE](
    state: RegistrationState,
    action: Action<string>
  ): RegistrationState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [RegistrationActionType.RESEND_REGISTRATION_EMAIL](state: RegistrationState): RegistrationState {
    return { ...state, resendEmailStatus: loadPending() };
  },
  [RegistrationActionType.RESEND_REGISTRATION_EMAIL_SUCCESS](state: RegistrationState): RegistrationState {
    return {
      ...state,
      resendEmailStatus: loadSuccess(),
    };
  },
  [RegistrationActionType.RESEND_REGISTRATION_EMAIL_FAILURE](
    state: RegistrationState,
    action: Action<string>
  ): RegistrationState {
    return { ...state, resendEmailStatus: loadFailed(action.payload) };
  },
});
