export enum Colors {
  SchmuckfarbeCyan = "#229bd3",
  SchmuckfarbeViolett = "#b067a2",
  AmpelGruen = "#3ba858",
  AmpelGelb = "#fecc00",
  AmpelRot = "#e32118",
  PrimaryLight = "#f3f3f4",
  PrimaryMain = "#a7a8aa",
  PrimaryDark = "#4b4b4d",
  White = "#FFFFFF",
  BluetoothBlue = "#1854fb",
  DarkGreen = "rgb(92, 162, 40)",
  LightGreen = "rgb(112, 176, 62)",
  Grey = "rgb(123, 124, 126)",
  Red = "rgb(200,5,49)",
}
