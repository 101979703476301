import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: Colors.Grey,
    fontSize: "18pt",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  descriptionContainer: {
    textAlign: "center",
  },
  description: {
    margin: "20px 70px",
    width: "80%",
    fontSize: "14pt",
    color: Colors.Grey,
  },
  textfield: {
    width: "70%",
    margin: "20px 0px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  confirmButton: {
    backgroundColor: Colors.DarkGreen,
    "&:hover": {
      opacity: 0.75,
    },
  },
  cancelButton: {
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
  },
}));

export default useStyles;
