import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import AppButton from "../../common/app-button/app-button";
import RoutePaths from "../../../routes/route-paths";
import useStyles from "./successful-registration.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "../../../shared/buttons-style";

export interface ConnectedDispatch {
  navigateTo: (path: string) => void;
}

interface Props extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
}

const SuccessfulRegistrationComponent: FunctionComponent<Props> = ({ closeModal, open, navigateTo }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onClickHandler = () => {
    closeModal();
    navigateTo(`${RoutePaths.Login}`);
  };

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <div className={classes.flexWrapper} data-cy="dialog-content">
        <Typography className={classes.title}>{t("Bestätigungsmail gesendet")}</Typography>
        <Typography className={classes.description}>{t("Bestätigungslink")}</Typography>
        <div className={classes.buttonWrapper}>
          <AppButton size={ButtonSize.MEDIUM_BUTTON} handler={onClickHandler}>
            {t("VERSTANDEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default SuccessfulRegistrationComponent;
