import { AppState } from "../app.state";

export const getExistingVvvoModalWindow = (state: AppState) => state.betriebstaetteAuth.isExistingVvvoOpen;

export const getBetriebExists = (state: AppState) => state.betriebstaetteAuth.betriebExists;

export const getExistedEmail = (state: AppState) => state.betriebstaetteAuth.existedEmail;

export const getAuthorizationExists = (state: AppState) => state.betriebstaetteAuth.authorizationExists;

export const getAuthorizationConfirmed = (state: AppState) => state.betriebstaetteAuth.authorizationConfirmed;

export const getBetriebstaetteStatus = (state: AppState) => state.betriebstaetteAuth.loadStatus;
