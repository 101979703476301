import { FunctionComponent } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppRadio from "../app-radio/app-radio.component";
import useStyles from "./app-radio-group.styles";
import { FormControl } from "@material-ui/core";
import { CommonFieldInputProps, WrappedFieldMetaProps } from "redux-form";
import { FieldLabel, FieldName } from "../../../shared/field-data";
import { useTranslation } from "react-i18next";

export interface Props {
  input: CommonFieldInputProps;
  meta: WrappedFieldMetaProps;
  clickHandler: (fieldName: string) => void;
}

interface HelperProps {
  helper: object;
}

const AppRadioGroup: FunctionComponent<Props> = ({
  input,
  meta: { touched, error },
  clickHandler,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const styles: HelperProps = {
    helper: { position: "absolute", bottom: "-40px", color: "#f44336", width: "300px" },
  };

  const onClickHandler = (fieldName: string) => {
    clickHandler(fieldName);
  };

  return (
    <FormControl>
      <RadioGroup {...input} {...rest}>
        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            value={FieldName.LANDWIRT}
            control={<AppRadio />}
            label={t(FieldLabel.LANDWIRT)}
            onClick={() => onClickHandler(FieldName.LANDWIRT)}
          />
        </div>
        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            value={FieldName.BERATER}
            control={<AppRadio />}
            label={t(FieldLabel.BERATER)}
            onClick={() => onClickHandler(FieldName.BERATER)}
          />
        </div>
        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            value={FieldName.TIERARZT}
            control={<AppRadio />}
            label={t(FieldLabel.TIERARZT)}
            onClick={() => onClickHandler(FieldName.TIERARZT)}
          />
        </div>
      </RadioGroup>
      <div style={styles.helper}> {touched && error}</div>
    </FormControl>
  );
};

export default AppRadioGroup;
