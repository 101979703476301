import logger from "../../logger";
import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import { ResetPasswordActionType, submitEmailSuccess, submitEmailFailure } from "./reset-password.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { put, call, takeLeading } from "redux-saga/effects";
import { stopSubmit } from "redux-form";
import { Dispatch } from "redux";
import { Action } from "../action";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";

let backendClient: BackendClient;
const logError = logger.error("resetPassword.saga");

export function* reset(action: Action<string>) {
  try {
    yield call([backendClient, apiMethods.ResetPassword], action.payload);
    yield put(submitEmailSuccess());
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_RESET_PASSWORD)));
  } catch (e) {
    yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_RESET_PASSWORD)));
    yield put(stopSubmit("resetPassword", { email: true }));
    yield put(submitEmailFailure(e.message));
    logError("Could not fetch user", e.message);
  }
}

export default function* resetPasswordSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ResetPasswordActionType.SUBMIT_EMAIL, reset);
}
