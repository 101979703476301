import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import { Action } from "../action";
import { IRejectUserVvvoAuthorizationRequestDto } from "../../api/backend-api-v1";
import {
  RejectAuthorizeVvvoActionType,
  rejectAuthorizeVvvoSuccess,
  rejectAuthorizeVvvoFailure,
} from "./reject-authorize-vvvo.actions";
import { takeLeading, put, call } from "redux-saga/effects";
import { Dispatch } from "redux";

let backendClient: BackendClient;
const logError = logger.error("rejectAuthorizeVvvo.saga");

export function* rejectAuthorize(action: Action<IRejectUserVvvoAuthorizationRequestDto>) {
  try {
    yield call([backendClient, apiMethods.AuthorizeRejectVvvo], action.payload);
    yield put(rejectAuthorizeVvvoSuccess(true));
  } catch (e) {
    logError(e.message);
    yield put(rejectAuthorizeVvvoFailure(e.message));
  }
}

export default function* rejectAuthorizeVvvoSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO, rejectAuthorize);
}
