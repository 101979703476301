import { FunctionComponent, useState, useEffect, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import StorageService from "../../shared/services/storage.service";
import AuthService from "../../shared/services/auth.service";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { languages, ILanguage } from "../../shared/languages";
import { changeLanguage, changeLanguageOnServer } from "../../store/i18n/i18n.actions";

const LanguageMenuComponent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const authService = AuthService.getInstance();
  const actualLanguage = StorageService.getInstance().getItem("language");

  const [flagCode, setFlagCode] = useState<FlagIconCode>(languages.de.flagCode);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const language = languages[actualLanguage!];
    if (language) {
      setFlagCode(language.flagCode);
      dispatch(changeLanguage(language.languageCode));
    }
  }, [actualLanguage, dispatch]);

  const isUserMenuOpen = Boolean(anchorEl);

  const handleClickLanguageMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null);
  };

  const handleClickLanguageSelected = (event: MouseEvent<HTMLElement>) => {
    dispatch(changeLanguage(event.currentTarget.id));
    setFlagCode(languages[event.currentTarget.id].flagCode);
    if (authService.isLoggedIn()) {
      dispatch(changeLanguageOnServer(event.currentTarget.id));
    }
    handleCloseLanguageMenu();
  };

  const languageMenuItem = (language: ILanguage) => (
    <MenuItem id={language.languageCode} onClick={handleClickLanguageSelected}>
      <FlagIcon code={language.flagCode} size={36} />
    </MenuItem>
  );

  return (
    <>
      <IconButton aria-controls="language-menu" aria-haspopup="true" onClick={handleClickLanguageMenu}>
        <FlagIcon code={flagCode} size={36} />
        <ArrowDropDownIcon />
      </IconButton>{" "}
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={isUserMenuOpen}
        onClose={handleCloseLanguageMenu}
      >
        {languageMenuItem(languages.de)}
        {languageMenuItem(languages.en)}
        {/* TODO: uncomment this language when it's will be ready in backend. */}
        {/* {languageMenuItem(languages.nl)} */}
      </Menu>
    </>
  );
};

export default LanguageMenuComponent;
