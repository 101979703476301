import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import checklistenReducer from "./checklisten/checklisten.reducer";
import checklistenEingabeReducer from "./checklisten-eingabe/checklisten-eingabe.reducer";
import initSaga from "./init/init.saga";
import checklistenSaga from "./checklisten/checklisten.saga";
import checklistenEingabeSaga from "./checklisten-eingabe/checklisten-eingabe.saga";
import loginSaga from "./login/login.saga";
import notificationReducer from "./notifications/notifications.reducer";
import betriebeSaga from "./betriebe/betriebe.saga";
import betriebeReducer from "./betriebe/betriebe.reducer";
import commonReducer from "./common/common.reducer";
import loginReducer from "./login/login.reducer";
import resetPasswordReducer from "./reset-password/reset-password.reducer";
import resetPasswordSaga from "./reset-password/reset-password.saga";
import { reducer as formReducer } from "redux-form";
import registrationReducer from "../store/registration/registration.reducer";
import registrationSaga from "../store/registration/registration.saga";
import changePasswordSaga from "../store/change-password/change-password.saga";
import changePasswordReducer from "../store/change-password/change-password.reducer";
import betriebVVVOReducer from "./betriebstaette-auth/betriebstaette-auth.reducer";
import betriebVVVOSaga from "./betriebstaette-auth/betriebstaette-auth.saga";
import i18nReducer from "./i18n/i18n.reducer";
import i18nSaga from "./i18n/i18n.saga";
import logoutSaga from "./logout/logout.saga";
import logoutReducer from "./logout/logout.reducer";
import contactDetailsReducer from "./contact-details/contact-details.reducer";
import contactDetailsSaga from "./contact-details/contact-details.saga";
import confirmAuthorizeVvvoSaga from "./confirm-authorize-vvvo/confirm-authorize-vvvo.saga";
import confirmAuthorizeVvvoReducer from "./confirm-authorize-vvvo/confirm-authorize-vvvo.reducer";
import rejectAuthorizeVvvoSaga from "./reject-authorize-vvvo/reject-authorize-vvvo.saga";
import rejectAuthorizeVvvoReducer from "./reject-authorize-vvvo/reject-authorize-vvvo.reducer";
import confirmCreationChangeEmailSaga from "./confirm-creation-change-email/confirm-creation-change-email.saga";
import confirmCreationChangeEmailReducer from "./confirm-creation-change-email/confirm-creation-change-email.reducer";
import changeEmailSaga from "./change-email/change-email.saga";
import changeEmailReducer from "./change-email/change-email.reducer";
import changeConfirmPasswordSaga from "./change-confirm-password/change-confirm-password.saga";
import changeConfirmPasswordReducer from "./change-confirm-password/change-confirm-password.reducer";
import errorSaga from "./error/error.saga";
import errorReducer from "./error/error.reducer";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    checklisten: checklistenReducer,
    checklistenEingabe: checklistenEingabeReducer,
    notification: notificationReducer,
    betriebe: betriebeReducer,
    common: commonReducer,
    form: formReducer,
    login: loginReducer,
    registration: registrationReducer,
    changePassword: changePasswordReducer,
    changeConfirmPassword: changeConfirmPasswordReducer,
    betriebstaetteAuth: betriebVVVOReducer,
    resetPassword: resetPasswordReducer,
    logout: logoutReducer,
    contactDetails: contactDetailsReducer,
    confirmAuthorizeVvvo: confirmAuthorizeVvvoReducer,
    rejectAuthorizeVvvo: rejectAuthorizeVvvoReducer,
    confirmEmail: confirmCreationChangeEmailReducer,
    changeEmail: changeEmailReducer,
    error: errorReducer,
    i18n: i18nReducer,
  });

const sagas = [
  initSaga,
  checklistenSaga,
  checklistenEingabeSaga,
  betriebeSaga,
  loginSaga,
  registrationSaga,
  changePasswordSaga,
  changeConfirmPasswordSaga,
  betriebVVVOSaga,
  resetPasswordSaga,
  i18nSaga,
  logoutSaga,
  contactDetailsSaga,
  confirmAuthorizeVvvoSaga,
  rejectAuthorizeVvvoSaga,
  confirmCreationChangeEmailSaga,
  changeEmailSaga,
  errorSaga,
];

export { rootReducer, sagas };
