import { FunctionComponent } from "react";
import { reduxForm, Field } from "redux-form";
import { useStyles } from "../profile.styles";
import renderTextField from "../../../components/common/render-text-field/render-text-field";
import { required, email } from "../../../utils/authValidation/auth-validation";
import AppButton from "../../../components/common/app-button/app-button";
import { ButtonSize } from "../../../shared/buttons-style";
import TransitionsModal from "../../../components/common/modal/modal-window";
import ChangeEmailForm from "../../../components/change-email/change-email-form.component";
import AuthService from "../../../shared/services/auth.service";
import { ChangeEmailDto } from "../../../store/change-email/change-email.actions";
import { FieldName } from "../../../shared/field-data";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  sendEmail: (data: ChangeEmailDto) => void;
  toggleEmailModalWindow: (isOpen: boolean) => void;
}

const EmailForm: FunctionComponent<Props> = ({ isOpen, sendEmail, toggleEmailModalWindow }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const submitHandler: any = (fieldData: ChangeEmailDto) => {
    const data: ChangeEmailDto = fieldData;
    sendEmail(data);
  };

  const openWindow = () => {
    toggleEmailModalWindow(true);
  };

  const closeWindow = () => {
    toggleEmailModalWindow(false);
  };

  const profileEmail: any = AuthService.getInstance().getEmail();
  return (
    <div>
      <form className={classes.emailWrapper}>
        <div className={classes.fieldWrapper}>
          <label className={classes.label}> {t("E-Mail Adresse")}</label>
          <Field
            className={classes.field}
            name={FieldName.EMAIL}
            component={renderTextField}
            placeholder={profileEmail}
            validate={[required, email]}
            type="text"
            disabled={true}
          />
        </div>
      </form>
      <div className={classes.autorisierungButton}>
        <AppButton handler={openWindow} size={ButtonSize.MEDIUM_BUTTON}>
          {t("ÄNDERN")}
        </AppButton>
      </div>
      {isOpen && (
        <TransitionsModal closeModal={closeWindow} open={isOpen}>
          <h2 className={classes.title}>{t("E-Mail Adresse ändern")}</h2>
          <p className={classes.description}>{t("Text - E-Mail Adresse ändern")}</p>
          <ChangeEmailForm onSubmit={submitHandler} onClose={closeWindow} />
        </TransitionsModal>
      )}
    </div>
  );
};
export default reduxForm<any, any>({ form: "email" })(EmailForm);
