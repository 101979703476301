import { Colors } from "../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.Grey,
  },
  logo: {
    height: "200px",
    marginTop: "150px",
  },
  description: {
    fontSize: "24px",
  },
  link: {
    marginTop: "30px",
    fontSize: "18px",
  },
}));

export default useStyles;
