import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
    position: "relative",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16pt",
    borderBottom: "1px solid rgb(123, 124, 126)",
  },
  description: {
    marginTop: "15px",
    width: "550px",
  },
  test: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "92%",
  },
  tryAgain: {
    margin: "20px 0",
  },
  wishes: {
    marginBottom: "60px",
  },
  button: {
    position: "absolute",
    bottom: "0",
    right: "40px",
    backgroundColor: Colors.DarkGreen,
    "&:hover": {
      opacity: 0.75,
    },
  },
}));

export default useStyles;
