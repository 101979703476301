import { FunctionComponent, useEffect } from "react";
import { reduxForm } from "redux-form";
import { useStyles } from "./profile.styles";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import EmailFormContainer from "./email-form/email-form.container";
import PasswordFormContainer from "./password-form/password-form.container";
import { Typography } from "@material-ui/core";
import ProfileContactDetailsContainer from "./contact-form/contact-form.container";
import PreLoader from "../../components/preloader/preloader.component";
import { LoadingStatus } from "../../store/loading-status";

import { useTranslation } from "react-i18next";

export interface ConnectedState {
  profileStatus: LoadingStatus;
  changeEmailStatus: LoadingStatus;
  changePasswordStatus: LoadingStatus;
  logoutStatus: LoadingStatus;
}
export interface ConnectedDispatch {
  setCurrentTitle: (title: string) => void;
  setContactDetails: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const Profile: FunctionComponent<Props> = ({
  setCurrentTitle,
  setContactDetails,
  profileStatus,
  changeEmailStatus,
  changePasswordStatus,
  logoutStatus,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTitle(t("Meine Daten"));
  }, [setCurrentTitle, t]);

  useEffect(() => {
    setContactDetails();
  }, [setContactDetails]);

  if (
    profileStatus.pending ||
    changeEmailStatus.pending ||
    changePasswordStatus.pending ||
    logoutStatus.pending
  ) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return (
    <div>
      <AppBarContainer />
      <Typography variant="h6" className={classes.lineAccross}>
        {t("Meine Zugangsdaten")}
      </Typography>
      <div className={classes.wrapper}>
        <div className={classes.formGroupWrapper}>
          <EmailFormContainer />
          <PasswordFormContainer />
        </div>
        <Typography variant="h6" className={classes.lineAccross}>
          {t("Meine Kontaktdaten")}
        </Typography>
        <div className={classes.contactForm}>
          <ProfileContactDetailsContainer />
        </div>
      </div>
    </div>
  );
};
export default reduxForm<any, any>({ form: "profile" })(Profile);
