class StorageService {
  static instance: StorageService;

  static getInstance(storage: Storage = localStorage) {
    if (StorageService.instance) {
      return StorageService.instance;
    }
    return new StorageService(storage);
  }

  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
    if (StorageService.instance) {
      throw new Error("Instantiation failed: use service.getInstance() instead of new.");
    } else {
      StorageService.instance = this;
    }
  }

  setItem(itemName: string, item: string): void {
    this.storage.setItem(itemName, item);
  }

  getItem(item: string) {
    return this.storage.getItem(item);
  }

  removeItem(item: string): void {
    this.storage.removeItem(item);
  }

  clearStorage(): void {
    this.storage.clear();
  }
}
export default StorageService;
