import createReducer from "../createReducer";
import { ErrorActionType } from "./error.actions";

export interface ErrorState {
  isError: boolean;
}

export const initialState: ErrorState = {
  isError: false,
};

export default createReducer(initialState, {
  [ErrorActionType.ERROR_OCCURRED](state: ErrorState): ErrorState {
    return { ...state, isError: true };
  },
});
