export enum PlaceHolder {
  USER_NAME = "Benutzername",
  EMAIL = "E-Mail Adresse",
  PASSWORD = "Passwort",
  NEW_PASSWORD = "Neues Passwort",
  CONFIRM_NEW_PASSWORD = "Neues Passwort bestätigen",
  CONFIRMED_PASSWORD = "Passwort wiederholen",
  NAME = "Name",
  ADRESSE = "Adresse",
  ORT = "Ort",
  VORNAME = "Vorname",
  PLZ = "PLZ",
  LAND = "Land",
  UNTERNEHMEN = "Unternehmen",
  HIDDEN_PASSWORD = "************",
}

export enum FieldName {
  USER_NAME = "username",
  EMAIL = "email",
  PASSWORD = "password",
  CURRENT_PASSWORD = "currentPassword",
  NEW_PASSWORD = "newPassword",
  CONFIRM_PASSWORD = "confirmPassword",
  CONFIRMED_PASSWORD = "confirmed_password",
  NAME = "name",
  ADRESSE = "street",
  ADRESSE_GERMAN = "strasse",
  ORT = "city",
  ORT_GERMAN = "ort",
  VORNAME = "firstName",
  VORNAME_GERMAN = "vorname",
  PLZ = "postCode",
  PLZ_GERMAN = "postleitzahl",
  LAND = "country",
  USERGROUP = "userGroup",
  COMPANY_BERATER = "companyBerater",
  COMPANY_TIERARZT = "companyTierarzt",
  LANDWIRT = "0",
  BERATER = "1",
  TIERARZT = "2",
  COMPANY = "company",
  NEW_EMAIL = "newEmail",
  VVVO = "vvvo",
  BETRIEB_NAME = "name",
}

export enum FieldLabel {
  NAME = "Name",
  ADRESSE = "Adresse",
  ORT = "Ort",
  VORNAME = "Vorname",
  PLZ = "PLZ",
  LAND = "Land",
  LANDWIRT = "Landwirt/in",
  BERATER = "Berater/in",
  TIERARZT = "Tierarzt/Tierärztin",
  VVVO = "VVVO",
  BETRIEB_NAME = "Name",
  EMAIL = "E-Mail Landwirt",
}

export enum UserGroupValue {
  LANDWIRT = "0",
  BERATER = "1",
  TIERARZT = "2",
}
