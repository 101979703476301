import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  clarification: {
    fontSize: "12px",
    width: "80%",
    margin: "20px 0 10px 0",
    color: Colors.Grey,
  },
  field: {
    width: "300px",
    borderRadius: "10px",
  },
  button: {
    display: "flex",
    justifyContent: "start",
    marginTop: "10px",
    width: "85%",
  },
  dischargeBtn: {
    marginRight: "10px",
  },
}));

export default useStyles;
