import { connect } from "react-redux";

import { AppState } from "../../store/app.state";
import { getNotification } from "../../store/notifications/notifications.selectors";
import { isAppUpdateAvailable } from "../../store/common/common.selectors";
import { toggleIsAppUpdateAvailable } from "../../store/common/common.actions";
import NotificationComponent, { ConnectedState, ConnectedDispatch } from "./notifications.component";
import { Dispatch } from "redux";

const mapStateToProps = (state: AppState): ConnectedState => ({
  notification: getNotification(state),
  isAppUpdateAvailable: isAppUpdateAvailable(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setIsAppUpdateAvailable: (isAvailable: boolean) => dispatch(toggleIsAppUpdateAvailable(isAvailable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
