import { Dispatch } from "redux";
import EnterChecklistenNameComponent, { ConnectedDispatch } from "./enter-checklisten-name.component";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { showMissingName } from "../../../store/notifications/notifications.actions";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
  showMissingName: () => dispatch(showMissingName()),
});

export default connect(null, mapDispatchToProps)(EnterChecklistenNameComponent);
