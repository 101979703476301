import { takeEvery, call, put, takeLeading } from "redux-saga/effects";
import { Action } from "../action";
import {
  DeleteChecklistenEingabeDto,
  saveChecklistenEingabeSuccess,
  saveChecklistenEingabeFailure,
  ChecklistenEingabeActionType,
  loadChecklistenEingabenSuccess,
  loadChecklistenEingabenFailure,
  deleteChecklistenEingabenSuccess,
  deleteChecklistenEingabenFailure,
  getPdfChecklistenEingabeSuccess,
  getPdfChecklistenEingabeFailure,
} from "./checklisten-eingabe.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import { Dispatch } from "redux";
import {
  IChecklistenEingabeDto,
  AddChecklistenEingabeDto,
  PdfResponseDto,
  ChecklistenEingabeDto,
} from "../../api/backend-api-v2";
import logger from "../../logger";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import AuthService from "../../shared/services/auth.service";
import db, { ISyncedChecklistenEingabeDto } from "../../db/database";
import I18n from "i18next";
import { checklistenEingabenAreSynced, shouldLoadChecklistenEingaben } from "../common/common.actions";

const logError = logger.error("checklistenEingabe.saga");
let backendClient: BackendClient;

export function* loadChecklistenEingaben(action: Action<number>) {
  try {
    const betriebId = action.payload;
    const checklistenEingaben: IChecklistenEingabeDto[] = yield call(
      [backendClient, apiMethods.GetChecklistenEingaben],
      betriebId
    );
    const syncedChecklistenEingaben = checklistenEingaben.map(item => ({ ...item, synced: 1 }));
    yield put(loadChecklistenEingabenSuccess({ [betriebId]: syncedChecklistenEingaben }));
    yield put(shouldLoadChecklistenEingaben(false));
  } catch (e) {
    logError("Could not fetch checklistenEingaben", e.message);
    yield put(loadChecklistenEingabenFailure("Could not fetch checklistenEingaben " + e.message));
  }
}

export function* trySendChecklistenEingabe(
  unsyncedChecklisteEingabe: ISyncedChecklistenEingabeDto,
  lastChecklisteEingabe: boolean
) {
  try {
    const response: ChecklistenEingabeDto = yield call(
      [backendClient, apiMethods.SaveChecklistenEingabe],
      new AddChecklistenEingabeDto(unsyncedChecklisteEingabe)
    );
    yield put(saveChecklistenEingabeSuccess(response));
    yield call([db, "markSyncedChecklistenEingabe"], unsyncedChecklisteEingabe);
    yield call([db, "removeSyncedChecklistenEingabe"]);

    const checklistenEingabenCount: number = yield call(db.getChecklisteEingabenCount);

    if (lastChecklisteEingabe) {
      yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CREATE_CHECKLISTE)));
      yield put(shouldLoadChecklistenEingaben(true));
      yield put(checklistenEingabenAreSynced(checklistenEingabenCount === 0));
    }
  } catch (e) {
    yield put(saveChecklistenEingabeFailure(I18n.t(FailureMessages.SHOW_FAILURE_CREATE_CHECKLISTE)));
    yield put(checklistenEingabenAreSynced(false));
    logError("Could not save", e.message);
  }
}

export function* trySyncChecklistenEingabe() {
  try {
    const isLoggedIn = AuthService.getInstance().isLoggedIn();
    if (!isLoggedIn) {
      return;
    }
    const unsyncedChecklistenEingabe: ISyncedChecklistenEingabeDto[] = yield call(
      db.getUnsyncedChecklistenEingabe
    );
    yield put(checklistenEingabenAreSynced(unsyncedChecklistenEingabe.length === 0));
    if (unsyncedChecklistenEingabe && unsyncedChecklistenEingabe.length > 0) {
      for (const [index, unsyncedChecklisteEingabe] of unsyncedChecklistenEingabe.entries()) {
        const lastChecklisteEingabe = index === unsyncedChecklistenEingabe.length - 1;
        yield call(trySendChecklistenEingabe, unsyncedChecklisteEingabe, lastChecklisteEingabe);
      }
    }
  } catch (e) {
    logError("Could not sync checklisten eingabe", e);
  }
}

export function* saveChecklistenEingabe(action: Action<ISyncedChecklistenEingabeDto>) {
  try {
    const { isDraft } = action.payload;
    const response: ChecklistenEingabeDto = yield call(
      [backendClient, apiMethods.SaveChecklistenEingabe],
      new AddChecklistenEingabeDto(action.payload)
    );
    const checklistenEingabenCount: number = yield call(db.getChecklisteEingabenCount);

    yield put(saveChecklistenEingabeSuccess(response));

    if (isDraft) {
      yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_SAVE_AS_DRAFT)));
    } else {
      yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CREATE_CHECKLISTE)));
    }

    yield put(shouldLoadChecklistenEingaben(true));
    yield put(checklistenEingabenAreSynced(checklistenEingabenCount === 0));
  } catch (e) {
    yield call([db, "addChecklistenEingabe"], action.payload);
    if (e.message !== FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_CREATE_CHECKLISTE)));
    }
    yield put(saveChecklistenEingabeFailure(FailureMessages.SHOW_FAILURE_CREATE_CHECKLISTE));
    yield put(checklistenEingabenAreSynced(false));
    logError("Could not save", e.message);
  }
}

export function* deleteChecklistenEingabe(action: Action<DeleteChecklistenEingabeDto>) {
  try {
    const { uniqueId } = action.payload;
    yield call([backendClient, apiMethods.DeleteChecklistenEingabe], uniqueId);
    yield put(deleteChecklistenEingabenSuccess(action.payload));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_DELETE_CHECKLISTE)));
  } catch (e) {
    yield put(deleteChecklistenEingabenFailure(FailureMessages.SHOW_FAILURE_DELETE_CHECKLISTE));
    yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_DELETE_CHECKLISTE)));
    logError("Could not delete", e.message);
  }
}

export function* getPdfChecklistenEingabe(action: Action<string>) {
  try {
    const response: PdfResponseDto = yield call(
      [backendClient, apiMethods.GetPdfChecklistenEingabe],
      action.payload
    );
    yield put(getPdfChecklistenEingabeSuccess(response.pdf));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_DOWNLOAD_PDF)));
  } catch (e) {
    logError("Could not fetch pdf", e.message);
    yield put(getPdfChecklistenEingabeFailure(e.message));
    if (e.message === FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_DOWNLOAD_PDF_WITHOUT_NETWORK));
    }
  }
}

export default function* checklistenEingabeSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN, loadChecklistenEingaben);
  yield takeEvery(ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE, saveChecklistenEingabe);
  yield takeLeading(ChecklistenEingabeActionType.SYNC_CHECKLISTEN_EINGABE, trySyncChecklistenEingabe);
  yield takeEvery(ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE, deleteChecklistenEingabe);
  yield takeLeading(ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE, getPdfChecklistenEingabe);
}
