import { Action } from "../action";
import createReducer from "../createReducer";
import { ChecklistenActionType } from "./checklisten.actions";
import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { IChecklisteDto } from "../../api/backend-api-v1";

export interface ChecklistenLoadState {
  checklisten: { [checklisteId: string]: IChecklisteDto };
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
}

export const initialState: ChecklistenLoadState = {
  checklisten: {},
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [ChecklistenActionType.LOAD_CHECKLISTEN](state: ChecklistenLoadState): ChecklistenLoadState {
    return { ...state, loadStatus: loadPending() };
  },
  [ChecklistenActionType.LOAD_CHECKLISTEN_SUCCESS](
    state: ChecklistenLoadState,
    action: Action<{
      checklisten: { [checklisteId: number]: IChecklisteDto };
    }>
  ): ChecklistenLoadState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      checklisten: action.payload.checklisten,
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenActionType.LOAD_CHECKLISTEN_FAILURE](
    state: ChecklistenLoadState,
    action: Action<string>
  ): ChecklistenLoadState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenActionType.SAVE_CHECKLISTE](state: ChecklistenLoadState): ChecklistenLoadState {
    return { ...state, loadStatus: loadPending() };
  },
  [ChecklistenActionType.SAVE_CHECKLISTE_SUCCESS](
    state: ChecklistenLoadState,
    action: Action<{
      checklisten: { [checklisteId: number]: IChecklisteDto };
    }>
  ): ChecklistenLoadState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      checklisten: action.payload.checklisten,
      lastFetched: new Date(Date.now()),
    };
  },
  [ChecklistenActionType.SAVE_CHECKLISTE_FAILURE](
    state: ChecklistenLoadState,
    action: Action<string>
  ): ChecklistenLoadState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      checklisten: {},
      lastFetched: new Date(Date.now()),
    };
  },
});
