import { Colors } from "../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "65%",
    margin: "0 auto",
    paddingTop: "20px",
    color: Colors.Grey,
  },
  wrapperHeader: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignSelf: "start",
  },
  logo: {
    height: "100px",
  },
  proffenButton: {
    marginLeft: "30px",
    marginTop: "13px",
  },
  proffenModalButton: {
    display: "flex",
    justifyContent: "center",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "410px",
    height: "55px",
    marginTop: "7px",
  },
  field: {
    width: "290px",
  },
  label: {
    alignSelf: "center",
    fontWeight: "bold",
    width: "110px",
  },
  autorisierungButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "15px",
  },
  disabledButton: {
    backgroundColor: Colors.Grey,
    "&:hover": {
      opacity: 0.75,
    },
    fontSize: "16px",
  },
  enabledButton: {
    fontSize: "16px",
  },
});
