import {
  BackendApiV1,
  BetriebDto,
  BetriebRequestDto,
  CheckVvvoRequestDto,
  CreateUserRequestDto,
  IBetriebDto,
  IBetriebeWithChecklistenEingabenResponseDto,
  IBetriebResponseDto,
  IChecklistenOverviewResponseDto,
  RequestExistingUserVvvoAuthorizationRequestDto,
  UserProfileDto,
  UserProfileRequestDto,
  ConfirmEmailRequestDto,
  IConfirmEmailRequestDto,
  IConfirmUserVvvoAuthorizationRequestDto,
  ConfirmUserVvvoAuthorizationRequestDto,
  UserProfileResponseDto,
  RequestChangeEmailRequestDto,
  IRequestChangeEmailRequestDto,
  ResetPasswordRequestDto,
  ChangePasswordRequestDto,
  IChangePasswordRequestDto,
  ChangeEmailRequestDto,
  IChangeEmailRequestDto,
  RejectUserVvvoAuthorizationRequestDto,
  IRejectUserVvvoAuthorizationRequestDto,
  ValidatePasswordRequestDto,
  IValidatePasswordRequestDto,
  IUserResponseDto,
  ICreateUserRequestDto,
  RequestPasswordResetRequestDto,
  ILoginRequestDto,
  LoginRequestDto,
  ICheckVvvoResponseDto,
  IChecklistenResponseDto,
  ICheckVvvoRequestDto,
  IUserProfileDto,
  IRequestExistingUserVvvoAuthorizationRequestDto,
  ValidateEmailRequestDto,
  ISendUserCreationEmailRequestDto,
  SendUserCreationEmailRequestDto,
} from "./backend-api-v1";
import {
  BackendApiV2,
  AddChecklistenEingabeRequestDto,
  IAddChecklistenEingabeResponseDto,
  AddChecklistenEingabeDto,
} from "./backend-api-v2";
import { ChangeConfirmPasswordDto } from "../store/change-confirm-password/change-confirm-password.actions";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { Dispatch } from "redux";
import { responseHandler } from "./backend-exception-handler";
import AuthService from "../shared/services/auth.service";

export default class BackendClient {
  static backendClient: BackendClient;

  static getInstance(dispatch: Dispatch) {
    // eslint-disable-next-line eqeqeq
    if (BackendClient.backendClient == null) {
      BackendClient.backendClient = new BackendClient(dispatch);
    }

    return this.backendClient;
  }

  private apiV1: BackendApiV1;
  private apiV2: BackendApiV2;

  constructor(private dispatch: Dispatch) {
    const axiosInstance = this.createAxiosInstance();
    this.apiV1 = new BackendApiV1("", axiosInstance);
    this.apiV2 = new BackendApiV2("", axiosInstance);
  }

  getClient = () => this.apiV1;

  createSession = async (userData: ILoginRequestDto) => {
    const request = new LoginRequestDto(userData);
    return await this.apiV1.postAuthenticate(request);
  };

  logout = async () => await this.apiV1.postLogout();

  resetPassword = async (email: string) => {
    const request = new RequestPasswordResetRequestDto({ email });
    return await this.apiV1.postRequestPasswordReset(request);
  };

  createUser = async (user: ICreateUserRequestDto) => {
    const request = new CreateUserRequestDto(user);
    const response: IUserResponseDto = await this.apiV1.postCreateUser(request);
    return response.user;
  };

  confirmChangePassword = async (data: ChangeConfirmPasswordDto) => {
    const request = new ResetPasswordRequestDto(data);
    return await this.apiV1.postConfirmPasswordReset(request);
  };

  changePassword = async (data: IChangePasswordRequestDto) => {
    const request = new ChangePasswordRequestDto(data);
    return await this.apiV1.postChangePassword(request);
  };

  requestChangeEmail = async (data: IRequestChangeEmailRequestDto) => {
    const request = new RequestChangeEmailRequestDto(data);
    return await this.apiV1.postRequestChangeEmail(request);
  };

  confirmChangeEmail = async (data: IChangeEmailRequestDto) => {
    const request = new ChangeEmailRequestDto(data);
    return await this.apiV1.postConfirmEmailChange(request);
  };

  checkPassword = async (password: IValidatePasswordRequestDto) => {
    const request = new ValidatePasswordRequestDto(password);
    await this.apiV1.postValidatePassword(request);
  };

  checkVvvo = async (vvvo: ICheckVvvoRequestDto) => {
    const request = new CheckVvvoRequestDto(vvvo);
    const response: ICheckVvvoResponseDto = await this.apiV1.postCheckVvvo(request);
    return response;
  };

  getChecklisten = async () => {
    const checklistenResponse: IChecklistenResponseDto = await this.apiV1.getLatestValidChecklisten();
    return checklistenResponse.checklisten;
  };

  getUserProfile = async () => {
    const userProfileResponse: UserProfileResponseDto = await this.apiV1.getUserProfile();
    return userProfileResponse.userProfile;
  };

  getChecklistenEingaben = async (betriebId: number) => {
    const checklistenEingabeResponse: IChecklistenOverviewResponseDto = await this.apiV1.getChecklistenEingaben(
      betriebId
    );
    return checklistenEingabeResponse.checklistenOverview;
  };

  saveChecklistenEingabe = async (checklistenEingabe: AddChecklistenEingabeDto) => {
    const request = new AddChecklistenEingabeRequestDto({
      checklistenEingabe,
    });
    const response: IAddChecklistenEingabeResponseDto = await this.apiV2.postChecklistenEingabe(request);
    return response.checklistenEingabe;
  };

  getBetriebe = async () => {
    const betriebeResponseDto: IBetriebeWithChecklistenEingabenResponseDto = await this.apiV1.getAllBetriebe();
    return betriebeResponseDto.betriebe;
  };

  saveBetrieb = async (betrieb: IBetriebDto) => {
    const request = new BetriebRequestDto({
      betrieb: new BetriebDto(betrieb),
    });
    const response: IBetriebResponseDto = await this.apiV1.postBetrieb(request);
    return response.betrieb;
  };

  deleteBetrieb = async (betriebId: number) => this.apiV1.deleteAuthorization(betriebId);

  authorizeNewBetriebVvvo = async (betrieb: IBetriebDto) => {
    const request = new BetriebRequestDto({
      betrieb: new BetriebDto(betrieb),
    });
    const response = await this.apiV1.postRequestAuthorizationForNewVvvo(request);
    return response;
  };

  resendVvvoAuthorization = async (betriebId: number) => this.apiV1.resendAuthorizationEmail(betriebId);

  authorizeExistingBetriebVvvo = async (vvvo: IRequestExistingUserVvvoAuthorizationRequestDto) => {
    const request = new RequestExistingUserVvvoAuthorizationRequestDto(vvvo);
    const response = await this.apiV1.postRequestAuthorizationForExistingVvvo(request);
    return response;
  };

  putContactDetails = async (contactDetails: IUserProfileDto) => {
    const request = new UserProfileRequestDto({ userProfile: new UserProfileDto(contactDetails) });
    const response = await this.apiV1.putUserProfile(request);
    return response;
  };

  confirmCreationEmail = async (data: IConfirmEmailRequestDto) => {
    const request = new ConfirmEmailRequestDto(data);
    const response = await this.apiV1.postConfirmEmail(request);
    return response;
  };

  authorizeConfirmVvvo = async (data: IConfirmUserVvvoAuthorizationRequestDto) => {
    const request = new ConfirmUserVvvoAuthorizationRequestDto(data);
    const response = await this.apiV1.postConfirmUserVvvoAuthorization(request);
    return response;
  };

  authorizeRejectVvvo = async (data: IRejectUserVvvoAuthorizationRequestDto) => {
    const request = new RejectUserVvvoAuthorizationRequestDto(data);
    await this.apiV1.postRejectUserVvvoAuthorization(request);
  };

  deleteChecklistenEingabe = async (uniqueId: string) =>
    await this.apiV2.deleteChecklistenEingabeByUniqueId(uniqueId);

  getTranslations = async () => {
    const response = await this.apiV1.getTranslations();
    return response.translations;
  };

  changeUserLanguage = async (language: string) => await this.apiV1.putUpdateLanguage(language);

  validateEmail = async (email: string) => {
    const request = new ValidateEmailRequestDto({ email });
    return await this.apiV1.postValidateEmail(request);
  };

  getPdfChecklistenEingabe = async (uniqueId: string) =>
    this.apiV2.getPdfChecklistenEingabeByUniqueId(uniqueId);

  resendUserRegistrationEmail = async (data: ISendUserCreationEmailRequestDto) => {
    const request = new SendUserCreationEmailRequestDto(data);
    await this.apiV1.postSendUserCreationEmail(request);
  };

  private createAxiosInstance() {
    const axiosInstance = axios.create();

    const requestHandler = async (request: AxiosRequestConfig) => {
      if (request.url === "/api/v1/translations") {
        return request;
      }
      const authService = AuthService.getInstance();
      const token = authService.getAccessToken();
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    };

    axiosInstance.interceptors.request.use(request => requestHandler(request));

    axiosInstance.interceptors.response.use(
      response => {
        responseHandler(response, this.dispatch);
        return response;
      },
      (error: AxiosError) => {
        responseHandler(error.response as AxiosResponse<any>, this.dispatch);
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  }
}
