import ContactDetails, {
  ConnectedDispatch,
  ConnectedState,
  ContactDetailsDto,
} from "./contact-details.component";
import { AppState } from "../../store/app.state";
import { saveContactDetails } from "../../store/contact-details/contact-details.actions";
import { connect } from "react-redux";
import { getFormValues, change } from "redux-form";
import { Dispatch } from "redux";

const mapStateToProps = (state: AppState): ConnectedState => ({
  fields: getFormValues("contactDetails")(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  resetField: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
  saveContactDetails: (data: ContactDetailsDto) => dispatch(saveContactDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
