import RejectAuthorizeVvvo, { ConnectedState, ConnectedDispatch } from "./reject-authorize-vvvo.component";
import { rejectAuthorizeVvvo } from "../../../store/reject-authorize-vvvo/reject-authorize-vvvo.actions";
import { getTranslationsStatus } from "../../../store/i18n/i18n.selectors";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../store/app.state";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  rejectAuthorizeVvvo: (authorizationId: number, token: string) =>
    dispatch(rejectAuthorizeVvvo({ authorizationId, token })),
});

const mapStateToProps = (state: AppState): ConnectedState => ({
  translationsStatus: getTranslationsStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectAuthorizeVvvo);
