import { Action } from "../action";
import { IRejectUserVvvoAuthorizationRequestDto } from "../../api/backend-api-v1";

export enum RejectAuthorizeVvvoActionType {
  REJECT_AUTHORIZE_VVVO = "REJECT_AUTHORIZE_VVVO",
  REJECT_AUTHORIZE_VVVO_SUCCESS = "REJECT_AUTHORIZE_VVVO_SUCCESS",
  REJECT_AUTHORIZE_VVVO_FAILURE = "REJECT_AUTHORIZE_VVVO_FAILURE",
}

export const rejectAuthorizeVvvo = (
  data: IRejectUserVvvoAuthorizationRequestDto
): Action<IRejectUserVvvoAuthorizationRequestDto> => ({
  type: RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO,
  payload: data,
});

export const rejectAuthorizeVvvoSuccess = (isSentMail: boolean): Action<boolean> => ({
  type: RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO_SUCCESS,
  payload: isSentMail,
});

export const rejectAuthorizeVvvoFailure = (errorMessage: string): Action<string> => ({
  type: RejectAuthorizeVvvoActionType.REJECT_AUTHORIZE_VVVO_FAILURE,
  payload: errorMessage,
});
