import { FunctionComponent, ChangeEvent } from "react";
import { FormControl, MenuItem, Select, Input } from "@material-ui/core";
import { useStyles } from "./drop-down.styles";
import { useTranslation } from "react-i18next";

interface Props {
  handleFilter: (filter: number) => void;
  filter: number;
}

const DropDown: FunctionComponent<Props> = ({ handleFilter, filter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<{ value: any }>) => {
    handleFilter(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={filter}
        onChange={handleChange}
        displayEmpty={true}
        inputProps={{ classes: { root: classes.selectInput } }}
        input={<Input className={classes.inputClasses} />}
      >
        <MenuItem value="" disabled={true}>
          {t("Filter")}
        </MenuItem>
        <MenuItem value={0}>{t("Alle Betriebsstätten")}</MenuItem>
        <MenuItem value={1}>{t("Autorisierte Betriebsstätten")}</MenuItem>
        <MenuItem value={2}>{t("Nicht autorisierte Betriebsstätten")}</MenuItem>
      </Select>
    </FormControl>
  );
};
export default DropDown;
