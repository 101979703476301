import { FunctionComponent, useState } from "react";
import AppButton from "../common/app-button/app-button";
import renderTextField from "../common/render-text-field/render-text-field";
import useStyles from "./change-profile-password.style";
import { FieldName, PlaceHolder } from "../../shared/field-data";
import { ButtonSize } from "../../shared/buttons-style";
import { SubmitHandler, reduxForm, Field } from "redux-form";
import {
  required,
  MinLengths,
  MaxLengths,
  passwordsMatch,
  password,
} from "../../utils/authValidation/auth-validation";
import { Typography } from "@material-ui/core";
import ShowPasswordButton from "../common/show-password-button/show-password-button.component";
import { useTranslation } from "react-i18next";

interface Props {
  handleSubmit: SubmitHandler;
  onClose: () => void;
}

const ChangeProfilePasswordForm: FunctionComponent<Props> = ({ handleSubmit, onClose }) => {
  const classes = useStyles();
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
  const { t } = useTranslation();

  const switchCurrentPasswordType = () => {
    setIsCurrentPasswordHidden(!isCurrentPasswordHidden);
  };

  const switchNewPasswordType = () => {
    setIsNewPasswordHidden(!isNewPasswordHidden);
  };

  const switchConfirmPasswordType = () => {
    setIsConfirmPasswordHidden(!isConfirmPasswordHidden);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={classes.flexWrapper}>
        <div className={classes.passwordField}>
          <Field
            name={FieldName.CURRENT_PASSWORD}
            placeholder={t("Altes Passwort")}
            component={renderTextField}
            className={classes.field}
            validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
            type={isCurrentPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton
            switchHandler={switchCurrentPasswordType}
            isPasswordHidden={isCurrentPasswordHidden}
          />
        </div>
        <Typography component={"ul"} className={classes.pwdRequirements}>
          {t("Passwort Richtlinien")}
          <ul>
            <li>{t("Passwort Richtlinien - 1")}</li>
            <li>{t("Passwort Richtlinien - 2")}</li>
          </ul>
        </Typography>
        <div className={classes.passwordField}>
          <Field
            name={FieldName.NEW_PASSWORD}
            placeholder={t("Ihr Passwort")}
            component={renderTextField}
            className={classes.field}
            validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
            type={isNewPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton switchHandler={switchNewPasswordType} isPasswordHidden={isNewPasswordHidden} />
        </div>
        <div className={classes.passwordField}>
          <Field
            name={FieldName.CONFIRM_PASSWORD}
            placeholder={t(PlaceHolder.CONFIRMED_PASSWORD)}
            component={renderTextField}
            className={classes.field}
            validate={[required, passwordsMatch]}
            type={isConfirmPasswordHidden ? "password" : "text"}
          />
          <ShowPasswordButton
            switchHandler={switchConfirmPasswordType}
            isPasswordHidden={isConfirmPasswordHidden}
          />
        </div>
        <div className={classes.buttonSection}>
          <AppButton className={classes.aproveButton} type="submit" size={ButtonSize.LARGE_BUTTON}>
            {t("PASSWORT ÄNDERN")}
          </AppButton>
          <AppButton className={classes.discardButton} handler={onClose} size={ButtonSize.MEDIUM_BUTTON}>
            {t("ABBRECHEN")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default reduxForm<any, any>({ form: "changeProfilePassword" })(ChangeProfilePasswordForm);
