import Dexie from "dexie";
import { IChecklistenEingabeDto } from "../api/backend-api-v2";

export interface ISyncedChecklistenEingabeDto extends IChecklistenEingabeDto {
  synced?: number;
}

export class AppDatabase extends Dexie {
  checklistenEingabe: Dexie.Table<ISyncedChecklistenEingabeDto, string>;

  constructor() {
    super("PigCheckDatabase");
    this.version(1).stores({
      checklistenEingabe: "&tempId, checklistenId, betriebId, createdAtUtc, checklistenEingabeName, synced",
    });
    // Add intermediate version and copy table to temp table, deleting origin version.
    this.version(2)
      .stores({
        checklistenEingabe: null, // Delete old table
        checklistenEingabeTemp: "&uniqueId", // Create temp table
      })
      .upgrade(async tx => {
        const checklistenEingabe = await tx.table("checklistenEingabe").toArray();
        const checklistenEingabeWithUniqueId = checklistenEingabe.map(item => ({
          ...item,
          uniqueId: item.tempId,
        }));
        await tx.table("checklistenEingabeTemp").bulkAdd(checklistenEingabeWithUniqueId);
      });
    // 3. Copy table to new checklistenEingabe table, deleting temp table:
    this.version(3)
      .stores({
        checklistenEingabeTemp: null,
        checklistenEingabe:
          "&uniqueId, checklistenId, betriebId, createdAtUtc, checklistenEingabeName, synced",
      })
      .upgrade(async tx => {
        const checklistenEingabe = await tx.table("checklistenEingabeTemp").toArray();
        await tx.table("checklistenEingabe").bulkAdd(checklistenEingabe);
      });

    this.checklistenEingabe = this.table("checklistenEingabe");
  }

  getChecklisteEingabenCount = async () => await this.checklistenEingabe.count();

  addChecklistenEingabe = async (checklistenEingabe: ISyncedChecklistenEingabeDto) => {
    const unsyncedChecklistenEingabe = {
      ...checklistenEingabe,
      synced: 0,
    };

    const existingChecklistenEingabe = await this.checklistenEingabe
      .where("uniqueId")
      .equals(unsyncedChecklistenEingabe.uniqueId!)
      .toArray();

    if (existingChecklistenEingabe.length) {
      return await this.checklistenEingabe.bulkPut([unsyncedChecklistenEingabe]);
    }

    await this.checklistenEingabe.bulkAdd([unsyncedChecklistenEingabe]);
  };

  getChecklistenEingabe = async (isSynced: boolean) => {
    const checklisten = await this.checklistenEingabe
      .where("synced")
      .equals(+isSynced)
      .toArray();

    return checklisten.map(checklist => {
      const { synced, ...rest } = checklist;
      return rest;
    });
  };

  getUnsyncedChecklistenEingabe = async (): Promise<ISyncedChecklistenEingabeDto[]> =>
    await this.getChecklistenEingabe(false);

  removeSyncedChecklistenEingabe = async () => {
    const syncedChecklistenEingaben = await this.checklistenEingabe.where("synced").equals(1).toArray();

    await this.checklistenEingabe.bulkDelete(
      syncedChecklistenEingaben.map(syncedChecklistenEingabe => syncedChecklistenEingabe.uniqueId!)
    );
  };

  markSyncedChecklistenEingabe = async (checklisteEingabe: ISyncedChecklistenEingabeDto) => {
    const updatedChecklisteEingabe = {
      ...checklisteEingabe,
      synced: 1,
    };
    await this.checklistenEingabe.bulkPut([updatedChecklisteEingabe]);
  };
}

const db = new AppDatabase();
export default db;
