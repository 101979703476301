import { IBetriebDto, ICheckVvvoResponseDto } from "../../api/backend-api-v1";
import { Action } from "../action";

export interface BetriebeEntities {
  betriebe: { [betriebId: number]: IBetriebDto };
}

export type ExistedBetriebDto = Pick<IBetriebDto, "vvvo"> & Partial<IBetriebDto>;

export enum BetriebeActionTypeVVVO {
  AUTHORIZE_EXISTING_BETRIEB_VVVO = "AUTHORIZE_EXISTING_BETRIEB_VVVO",
  AUTHORIZE_EXISTING_BETRIEB_SUCCESS_VVVO = "AUTHORIZE_EXISTING_BETRIEB_SUCCESS_VVVO",
  AUTHORIZE_EXISTING_BETRIEB_FAILURE_VVVO = "AUTHORIZE_EXISTING_BETRIEB_FAILURE_VVVO",
  AUTHORIZE_NEW_BETRIEB_VVVO = "AUTHORIZE_NEW_BETRIEB_VVVO",
  AUTHORIZE_NEW_BETRIEB_SUCCESS_VVVO = "AUTHORIZE_NEW_BETRIEB_SUCCESS_VVVO",
  AUTHORIZE_NEW_BETRIEB_FAILURE_VVVO = "AUTHORIZE_NEW_BETRIEB_FAILURE_VVVO",
  CHECK_VVVO = "CHECK_VVVO",
  CHECK_VVVO_SUCCESS = "CHECK_VVVO_SUCCESS",
  CHECK_VVVO_FAILURE = "CHECK_VVVO_FAILURE",
  RESET_BETRIEB_STATE = "RESET_BETRIEB_STATE",
  RESEND_VVVO_AUTHORIZATION = "RESEND_VVVO_AUTHORIZATION",
  RESEND_VVVO_AUTHORIZATION_SUCCESS = "RESEND_VVVO_AUTHORIZATION_SUCCESS",
  RESEND_VVVO_AUTHORIZATION_FAILURE = "RESEND_VVVO_AUTHORIZATION_FAILURE",
  TOGGLE_EXISTING_VVVO_MODAL_WINDOW = "TOGGLE_EXISTING_VVVO_MODAL_WINDOW",
}

export const authorizeExistingBetriebVVVO = (betrieb: ExistedBetriebDto): Action<ExistedBetriebDto> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_VVVO,
  payload: betrieb,
});

export const authorizeExistingBetriebSuccessVVVO = (vvvo: IBetriebDto): Action<IBetriebDto> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_SUCCESS_VVVO,
  payload: vvvo,
});

export const authorizeExistingBetriebFailureVVVO = (errorMessage: string): Action<string> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_FAILURE_VVVO,
  payload: errorMessage,
});

export const resendVvvoAuthorization = (betriebId: number): Action<number> => ({
  type: BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION,
  payload: betriebId,
});

export const resendVvvoAuthorizationSuccess = (): Action<null> => ({
  type: BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION_SUCCESS,
  payload: null,
});

export const resendVvvoAuthorizationFailure = (errorMessage: string): Action<string> => ({
  type: BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION_FAILURE,
  payload: errorMessage,
});

export const authorizeNewBetriebVVVO = (betrieb: IBetriebDto): Action<IBetriebDto> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_VVVO,
  payload: betrieb,
});

export const authorizeNewBetriebSuccessVVVO = (betrieb: IBetriebDto): Action<IBetriebDto> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_SUCCESS_VVVO,
  payload: betrieb,
});

export const authorizeNewBetriebFailureVVVO = (errorMessage: string): Action<string> => ({
  type: BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_FAILURE_VVVO,
  payload: errorMessage,
});

export const checkVvvo = (vvvo: string): Action<string> => ({
  type: BetriebeActionTypeVVVO.CHECK_VVVO,
  payload: vvvo,
});

export const checkVvvoSuccess = (exists: ICheckVvvoResponseDto): Action<ICheckVvvoResponseDto> => ({
  type: BetriebeActionTypeVVVO.CHECK_VVVO_SUCCESS,
  payload: exists,
});

export const checkVvvoFailure = (errorMessage: string): Action<string> => ({
  type: BetriebeActionTypeVVVO.CHECK_VVVO_FAILURE,
  payload: errorMessage,
});

export const resetBetriebState = (resetFlag: boolean): Action<boolean> => ({
  type: BetriebeActionTypeVVVO.RESET_BETRIEB_STATE,
  payload: resetFlag,
});

export const toggleExistingVvvoModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: BetriebeActionTypeVVVO.TOGGLE_EXISTING_VVVO_MODAL_WINDOW,
  payload: isOpen,
});
