export enum SuccessMessages {
  SHOW_SUCCESSFUL_CHANGE_PASSWORD = "Passwort wurde erfolgreich geändert.",
  SHOW_SUCCESSFUL_CREATE_CHECKLISTE = "Checkliste erfolgreich angelegt.",
  SHOW_SUCCESSFUL_DELETE_CHECKLISTE = "Die Checkliste wurde erfolgreich gelöscht.",
  SHOW_SUCCESSFUL_RESET_PASSWORD = "Es wurde eine E-Mail an die angegebene E-Mail Adresse verschickt.",
  SHOW_SUCCESSFUL_CREATE_BETRIEB_VVVO = "Autorisierung erfolgreich angefordert.",
  SHOW_SUCCESSFUL_LOGOUT = "Sie wurden erfolgreich abgemeldet.",
  SHOW_SUCCESSFUL_SAVE_CONTACT_DETAILS_DATA = "Kontaktdaten übernommen.",
  SHOW_SUCCESSFUL_CONFIRM_CREATION_EMAIL = "Ihr Benutzerkonto wurde erfogreich aktiviert.",
  SHOW_SUCCESSFUL_CONFIRM_CHANGE_EMAIL = "E-Mail Adresse wurde erfolgreich geändert.",
  SHOW_SUCCESSFUL_CHANGE_EMAIL = "Es wurde eine E-Mail an die angegebene E-Mail Adresse verschickt.",
  SHOW_SUCCESSFUL_DELETE_BETRIEB = "Betriebsstätte erfolgreich gelöscht.",
  SHOW_SUCCESSFUL_RETRY_AUTHORIZATION_VVVO = "Autorisierung erneut angefordert.",
  SHOW_SUCCESSFUL_DOWNLOAD_PDF = "Checkliste heruntergeladen",
  SHOW_NO_VALIDATION_ERROR_FOR_SURVEY_PAGE = "Alle Eingaben korrekt",
  SHOW_SUCCESSFUL_SAVE_AS_DRAFT = "Entwurf erfolgreich angelegt",
}

export enum FailureMessages {
  SHOW_FAILURE_CREATE_CHECKLISTE = "Checklisten Eingabe konnte nicht angelegt werden.",
  SHOW_FAILURE_DELETE_CHECKLISTE = "Die Checkliste konnte nicht gelöscht werden.",
  SHOW_FAILURE_CONFIRM_CREATION_CHANGE_EMAIL = "E-Mail Adresse konnte nicht geändert werden.",
  SHOW_FAILURE_CONFIRM_CHANGE_EMAIL = "Bestätigungslink abgelaufen.",
  SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA = "Die Kontaktdaten konnten nicht übernommen werden.",
  SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA_WITHOUT_NETWORK = "Das Verändern der Benutzerdaten ist nur mit Internet-Verbindung möglich",
  SHOW_FAILURE_LOGIN = "E-Mail Adresse und/oder Passwort falsch!",
  SHOW_FAILURE_LOGOUT = "Beim logout ist ein Fehler aufgetreten.",
  SHOW_FAILURE_RESET_PASSWORD = "E-Mail Adresse ist nicht registiert",
  SHOW_FAILURE_CHANGE_PASSWORD = "Passwort konnte nicht geändert werden",
  SHOW_FAILURE_WRONG_PASSWORD = "Passwort falsch",
  SHOW_FAILURE_RETRY_AUTHORIZATION_VVVO = "Eine Autorisierung kann nicht angefordert werden",
  SHOW_FAILURE_CHECK_VVVO = "VVVO konnte nicht geprüft werden.",
  SHOW_FAILURE_DOWNLOAD_PDF_WITHOUT_NETWORK = "Das Herunterladen der PDF-Datei ist nur mit Internet-Verbindung möglich",
  SHOW_FAILURE_DELETE_BETRIEB_WITHOUT_NETWORK = "Das Löschen von Betriebsstätten ist nur mit Internet-Verbindung möglich",
  ERROR_OF_FETCHING_DATA = "Keine Internetverbindung. Daten laden nicht möglich.",
  SHOW_VALIDATION_ERROR_FOR_SURVEY_PAGE = "Fehlerhafte Eingaben vorhanden",
  SHOW_OFFLINE_LOGIN_ERROR = "Offline - Login nicht möglich",
  EMAIL_INVALID = "Ungültige Email Adresse",
  NETWORK_ERROR = "Network Error",
}
