import { FunctionComponent, MouseEventHandler } from "react";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./reset-password.styles";
import ResetPasswordForm from "./reset-password-form.component";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { submitEmail } from "../../../store/reset-password/reset-password.actions";
import TransitionsModal from "../../common/modal/modal-window";
import { useTranslation } from "react-i18next";

export interface Props {
  closeWindow: MouseEventHandler;
  open: boolean;
}

interface FormData {
  email: string;
}

const ResetPassword: FunctionComponent<Props> = ({ closeWindow, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const submitEmailHandler: any = (data: FormData) => {
    dispatch(submitEmail(data.email));
  };

  return (
    <TransitionsModal closeModal={closeWindow} open={open}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Passwort vergessen?")}</Typography>
        <Typography className={classes.description}>
          {t("E-Mail eingeben - Passwort zurücksetzen")}
        </Typography>
        <ResetPasswordForm onSubmit={submitEmailHandler} />
        <AppButton className={classes.cancelBtn} handler={closeWindow}>
          {t("ABBRECHEN")}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ResetPassword;
