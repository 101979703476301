import StorageService from "./storage.service";
import db from "../../db/database";

class AuthService {
  static instance: AuthService;

  static getInstance(storage: Storage = localStorage) {
    if (AuthService.instance) {
      return AuthService.instance;
    }
    return new AuthService(storage);
  }

  storageService: StorageService;

  private readonly accessToken: string = "accessToken";
  private readonly email: string = "email";
  private readonly firstLogin: string = "firstLogin";

  constructor(storage: Storage) {
    this.storageService = StorageService.getInstance(storage);
    if (AuthService.instance) {
      throw new Error("Instantiation failed: use service.getInstance() instead of new.");
    } else {
      AuthService.instance = this;
    }
  }

  setAccessToken(token: string): void {
    this.storageService.setItem(this.accessToken, token);
  }

  getAccessToken() {
    return this.storageService.getItem(this.accessToken);
  }

  setEmail(email: string) {
    this.storageService.setItem(this.email, email);
  }

  getEmail() {
    return this.storageService.getItem(this.email);
  }

  setFirstLogin(firstLogin: boolean) {
    if (firstLogin) {
      return this.storageService.setItem(this.firstLogin, "true");
    } else {
      return this.storageService.setItem(this.firstLogin, "false");
    }
  }

  removeAccessToken() {
    return this.storageService.removeItem(this.accessToken);
  }

  removeEmail() {
    return this.storageService.removeItem(this.email);
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  isFirstLogin() {
    return this.storageService.getItem(this.firstLogin) === "true";
  }

  endSession() {
    this.storageService.removeItem(this.accessToken);
    this.storageService.removeItem(this.email);
    db.close();
    db.delete();
    db.open();
  }
}
export default AuthService;
