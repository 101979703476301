import { AppState } from "../../../store/app.state";
import PasswordForm from "./password-form.component";
import { connect } from "react-redux";
import { getPasswordModalWindow } from "../../../store/change-password/change-password.selector";
import { Dispatch } from "redux";
import {
  ChangePasswordDto,
  sendPassword,
  togglePasswordModalWindow,
} from "../../../store/change-password/change-password.actions";

interface IncomingProps {
  isOpen?: boolean;
}

const mapStateToProps = (state: AppState) => ({
  isOpen: getPasswordModalWindow(state),
});

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  sendPassword: (data: ChangePasswordDto) => dispatch(sendPassword(data)),
  togglePasswordModalWindow: (isOpen: boolean) => dispatch(togglePasswordModalWindow(isOpen)),
});

const PasswordFormContainer = (props: IncomingProps) => <PasswordForm {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordFormContainer);
