import { LoginActionType, fetchUserSuccess, fetchUserFailure } from "./login.actions";
import logger from "../../logger";
import { takeEvery, put, call } from "redux-saga/effects";
import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import { LoginDto } from "./login.actions";
import { Dispatch } from "redux";
import { Action } from "../action";
import { history } from "../../store/configureStore";
import RoutePaths from "../../routes/route-paths";
import AuthService from "../../shared/services/auth.service";
import StorageService from "../../shared/services/storage.service";
import { showFailedRequest } from "../notifications/notifications.actions";
import { stopSubmit } from "redux-form";
import { FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";
import { IAuthResponseDto, ILoginRequestDto } from "../../api/backend-api-v1";

let backendClient: BackendClient;
const logError = logger.error("authenticate.saga");
const authService = AuthService.getInstance();
const storageService = StorageService.getInstance();
const networkErrorMessage = "Network Error";

export function* login(action: Action<LoginDto>) {
  try {
    const data = action.payload;
    authService.setEmail(data.username);
    const language = storageService.getItem("language") || "de";
    const requestData: ILoginRequestDto = { ...data, language };
    const response: IAuthResponseDto = yield call([backendClient, apiMethods.CreateSession], requestData);
    yield put(fetchUserSuccess(response));
    authService.setAccessToken(response.token!);
    authService.setFirstLogin(response.firstLogin!);
    history.push(response.firstLogin ? RoutePaths.ContactDetails : RoutePaths.Betriebe);
  } catch (e) {
    if (e.message === networkErrorMessage) {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_OFFLINE_LOGIN_ERROR)));
    } else {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_LOGIN)));
    }
    yield put(stopSubmit("login", { username: true, password: true }));
    logError("Could not fetch user", e.message);
    yield put(fetchUserFailure(e.message));
  }
}

export default function* loginSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(LoginActionType.FETCH_USER, login);
}
