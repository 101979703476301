import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import RoutePaths from "../../routes/route-paths";
import { takeLeading, put, call } from "redux-saga/effects";
import { Action } from "../action";
import { history } from "../../store/configureStore";
import {
  ChangeConfirmPasswordActionType,
  ChangeConfirmPasswordDto,
  sendConfirmPasswordSuccess,
  sendConfirmPasswordFailure,
} from "./change-confirm-password.actions";
import { showSuccessfulRequest } from "../notifications/notifications.actions";
import { Dispatch } from "redux";
import { stopSubmit } from "redux-form";
import { SuccessMessages } from "../../shared/notifications-messages";
import I18n from "i18next";

let backendClient: BackendClient;
const logError = logger.error("changePassword.saga");

export function* changeConfirmPassword(action: Action<ChangeConfirmPasswordDto>) {
  try {
    const passwordData: ChangeConfirmPasswordDto = action.payload;
    yield call([backendClient, apiMethods.ConfirmChangePassword], passwordData);
    yield put(sendConfirmPasswordSuccess());
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CHANGE_PASSWORD)));
    history.push(RoutePaths.Login);
  } catch (e) {
    logError(e.message);
    yield put(stopSubmit("changePassword", { password: "Invalid password" }));
    yield put(sendConfirmPasswordFailure(e.message));
  }
}

export default function* changeConfirmPasswordSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD, changeConfirmPassword);
}
