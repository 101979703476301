import { Switch, Route } from "react-router";
import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";
import RoutePaths from "./route-paths";
import LoginContainer from "../pages/login/login.container";
import ChangePassword from "../components/modal-windows/change-password/change-password.component";
import RegistrationContainer from "../pages/registration/registration.container";
import ChecklisteContainer from "../pages/checkliste/checkliste.container";
import BetriebeContainer from "../pages/betriebe/betriebe.container";
import ChecklistenOverviewContainer from "../pages/checklisten-overview/checklisten-overview.container";
import ProfileContainer from "../pages/profile/profile.container";
import ContactDetailsContainer from "../pages/contact-details/contact-details.container";
import ChangePasswordFailure from "../components/modal-windows/change-password-failure/change-password-failure.component";
import RegistrationFailure from "../components/modal-windows/registration-failure/registration-failure.component";
import ConfirmEmailContainer from "../components/confirm-creation-email/confirm-creation-email.container";
import ConfirmAuthorizeVvvoContainer from "../components/modal-windows/confirm-authorize-vvvo/confirm-authorize-vvvo.container";
import RejectAuthorizeVvvoContainer from "../components/modal-windows/reject-authorize-vvvo/reject-authorize-vvvo.container";
import BetriebStaetteAuthContainer from "../pages/betriebstaette-auth/betriebstaette-auth.container";
import ConfirmChangeEmail from "../components/confirm-change-email/confirm-change-email.container";
import InfoPageContainer from "../pages/info/info.container";
import ErrorPage from "../pages/error/error.component";
import ResendRegistrationEmailContainer from "../components/modal-windows/resend-registration-email/resend-registration-email.container";

export default (
  <Switch>
    <PublicRoute path={RoutePaths.Login} component={LoginContainer} />
    <PublicRoute path={RoutePaths.Registration} component={RegistrationContainer} />
    <PublicRoute path={RoutePaths.ChangePassword} component={ChangePassword} />
    <PublicRoute path={RoutePaths.ChangePasswordFailure} component={ChangePasswordFailure} />
    <PublicRoute path={RoutePaths.RegistrationFailure} component={RegistrationFailure} />
    <PublicRoute path={RoutePaths.ConfirmEmail} component={ConfirmEmailContainer} />

    <Route path={RoutePaths.ChangeEmail} component={ConfirmChangeEmail} />
    <Route path={RoutePaths.ConfirmAuthorizeVvvo} component={ConfirmAuthorizeVvvoContainer} />
    <Route path={RoutePaths.RejectAuthorizeVvvo} component={RejectAuthorizeVvvoContainer} />
    <Route path={RoutePaths.Info} component={InfoPageContainer} />
    <Route path={RoutePaths.Error} component={ErrorPage} />
    <Route path={RoutePaths.ResendRegistrationEmail} component={ResendRegistrationEmailContainer} />

    <PrivateRoute path={RoutePaths.ContactDetails} component={ContactDetailsContainer} />
    <PrivateRoute exact={true} path={RoutePaths.Betriebe} component={BetriebeContainer} />
    <PrivateRoute
      path={`${RoutePaths.ChecklistenOverview}/:betriebId/:betriebName/:betriebVvvo`}
      component={ChecklistenOverviewContainer}
    />
    <PrivateRoute
      path={`${RoutePaths.Checkliste}/:checklisteId/:betriebId/:betriebName/:betriebVvvo/:checklistenEingabenName`}
      component={ChecklisteContainer}
    />
    <PrivateRoute path={RoutePaths.Profile} component={ProfileContainer} />
    <PrivateRoute path={RoutePaths.BetriebstatteAuth} component={BetriebStaetteAuthContainer} />
  </Switch>
);
