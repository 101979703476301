import { FunctionComponent } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import CloseButton from "../../common/close-button/close-button.component";
import useStyles from "./impressum.styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const Impressum: FunctionComponent<Props> = ({ closeModal, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TransitionsModal closeModal={closeModal} open={open} sizeWidth="900px">
      <CloseButton closeModal={closeModal} />
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>{t("Impressum")}</Typography>
        <div className={classes.textWrapper}>
          <Typography className={classes.description}>IQ-Agrar Service GmbH</Typography>
          <Typography className={classes.description}>
            Peter Schwaer, Stephanie Bering
            <br />
            Iburger Straße 225
            <br />
            49082 Osnabrück
          </Typography>
          <Typography className={classes.description}>
            Tel.: +49 (0) 541 / 600 288 80
            <br />
            Fax: +49 (0) 541 / 600 288 90
            <br />
            E-Mail: info@iq-agrar.de
          </Typography>
          <Typography className={classes.description}>
            {t("Hauptsitz der Gesellschaft")}: Osnabrück
            <br />
            HRB: 20215
            <br />
            {t("Amtsgericht")}: Osnabrück
            <br />
            Ust.-ID.: DE 22 1940600
          </Typography>
          <Typography className={classes.topic}>{t("Haftung")}</Typography>
          <Typography className={classes.description}>{t("Text - Impressum")}</Typography>
          <Typography className={classes.topic}>{t("Text - Impressum 2")}</Typography>
          <Typography className={classes.description}>{t("Text - Impressum 3")}</Typography>
          <Typography className={classes.topic}>{t("Text - Impressum 4")}</Typography>
          <Typography className={classes.description}>{t("Text - Impressum 5")}</Typography>
          <Typography className={classes.topic}>{t("Text - Impressum 6")}</Typography>
          <Typography className={classes.description}>{t("Text - Impressum 7")}</Typography>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default Impressum;
