import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ChangeConfirmPasswordActionType, ChangeConfirmPasswordDto } from "./change-confirm-password.actions";

export interface ChangeConfirmPasswordState extends ChangeConfirmPasswordDto {
  loadStatus: LoadingStatus;
}

export const initialState: ChangeConfirmPasswordState = {
  userId: "",
  token: "",
  password: "",
  confirmPassword: "",
  loadStatus: loadInitial(),
};

export default createReducer(initialState, {
  [ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD](
    state: ChangeConfirmPasswordState,
    action: Action<ChangeConfirmPasswordDto>
  ): ChangeConfirmPasswordState {
    return {
      ...state,
      ...action.payload,
      loadStatus: loadPending(),
    };
  },
  [ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD_SUCCESS](
    state: ChangeConfirmPasswordState
  ): ChangeConfirmPasswordState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [ChangeConfirmPasswordActionType.SEND_CONFIRM_PASSWORD_FAILURE](
    state: ChangeConfirmPasswordState,
    action: Action<string>
  ): ChangeConfirmPasswordState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
});
