import { FunctionComponent, useCallback } from "react";
import TransitionsModal from "../../common/modal/modal-window";
import useStyles from "./open-draft.styles";
import AppButton from "../../common/app-button/app-button";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ISyncedChecklistenEingabeDto } from "../../../db/database";
import RoutePaths from "../../../routes/route-paths";

interface Props extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
  draftChecklistenEingabe: ISyncedChecklistenEingabeDto | undefined;
  betriebName: string;
  betriebVvvo: string;
}

export interface ConnectedDispatch {
  setDraftChecklistenEingabe: (checklistenEingabe: ISyncedChecklistenEingabeDto) => void;
  navigateTo: (path: string) => void;
}

const OpenDraftComponent: FunctionComponent<Props> = props => {
  const {
    open,
    closeModal,
    draftChecklistenEingabe,
    setDraftChecklistenEingabe,
    navigateTo,
    betriebName,
    betriebVvvo,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    if (draftChecklistenEingabe) {
      const { checklistenId, betriebId, checklistenEingabeName } = draftChecklistenEingabe;
      setDraftChecklistenEingabe(draftChecklistenEingabe);
      navigateTo(
        `${RoutePaths.Checkliste}/${checklistenId}/${betriebId}/${betriebName}/${betriebVvvo}/${checklistenEingabeName}`
      );
    }
  }, [betriebName, betriebVvvo, draftChecklistenEingabe, navigateTo, setDraftChecklistenEingabe]);

  return (
    <TransitionsModal closeModal={closeModal} open={open}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.title}>
          {t("Möchten Sie diesen Entwurf zur Bearbeitung öffnen?")}
        </Typography>
        <div className={classes.buttons}>
          <AppButton className={classes.cancelButton} handler={closeModal}>
            {t("ABBRECHEN")}
          </AppButton>
          <AppButton className={classes.confirmButton} handler={handleConfirm}>
            {t("ÖFFNEN")}
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default OpenDraftComponent;
