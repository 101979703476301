import { AppState } from "../app.state";
import { IChecklisteDto } from "../../api/backend-api-v1";
import { createSelector } from "reselect";

export const getChecklisten = (state: AppState): { [checklisteId: number]: IChecklisteDto } =>
  state.checklisten.checklisten;

export const getChecklisteById = (checklisteId: string) =>
  createSelector(
    [getChecklisten],
    (checklisten: { [checklisteId: string]: IChecklisteDto }) => checklisten[checklisteId]
  );

export const getChecklistenStatus = (state: AppState) => state.checklisten.loadStatus;
