import { connect } from "react-redux";

import { AppState } from "../../../store/app.state";
import { getChecklisten, getChecklistenStatus } from "../../../store/checklisten/checklisten.selectors";
import AddChecklistenComponent, { ConnectedDispatch, ConnectedState } from "./add-checkliste.component";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { loadChecklisten } from "../../../store/checklisten/checklisten.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  checklisten: getChecklisten(state),
  checklistenStatus: getChecklistenStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
  loadChecklisten: () => dispatch(loadChecklisten()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChecklistenComponent);
