import { FlagIconCode } from "react-flag-kit";

export interface ILanguage {
  languageCode: string;
  flagCode: FlagIconCode;
}

export interface ILanguages {
  [key: string]: ILanguage;
}

export const languages: ILanguages = {
  en: { languageCode: "en", flagCode: "GB" },
  de: { languageCode: "de", flagCode: "DE" },
  nl: { languageCode: "nl", flagCode: "NL" },
};
