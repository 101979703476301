import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ConfirmAuthorizeVvvoActionType } from "./confirm-authorize-vvvo.actions";

export interface ConfirmAuthorizeVvvoState {
  isConfirmed: boolean;
  loadStatus: LoadingStatus;
  lastConfirm: Date | undefined;
}

export const initialState = {
  isConfirmed: false,
  loadStatus: loadInitial(),
  lastConfirm: undefined,
};

export default createReducer(initialState, {
  [ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO](
    state: ConfirmAuthorizeVvvoState
  ): ConfirmAuthorizeVvvoState {
    return { ...state, loadStatus: loadPending() };
  },
  [ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO_SUCCESS](
    state: ConfirmAuthorizeVvvoState,
    action: Action<boolean>
  ): ConfirmAuthorizeVvvoState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      isConfirmed: action.payload,
      lastConfirm: new Date(Date.now()),
    };
  },
  [ConfirmAuthorizeVvvoActionType.CONFIRM_AUTHORIZE_VVVO_FAILURE](
    state: ConfirmAuthorizeVvvoState,
    action: Action<string>
  ): ConfirmAuthorizeVvvoState {
    return { ...state, loadStatus: loadFailed(action.payload), lastConfirm: new Date(Date.now()) };
  },
});
