import I18n from "i18next";

export const required = (value: any) => (value ? "" : I18n.t("Wert wird benötigt"));
export const requiredUsergroup = (value: any) =>
  value ? "" : I18n.t("Bitte wählen Sie eine Nutzergruppe aus");
export const isNumber = (value: number) =>
  value && isNaN(Number(value)) ? I18n.t("Wert muss eine Nummer sein") : undefined;
const maxLength = (max: number) => (value: string) =>
  value && value.length > max
    ? I18n.t("Darf nicht größer als _PLACEHOLDER_ Zeichen sein")?.replace("_PLACEHOLDER_", max.toString())
    : undefined;
const minLength = (min: number) => (value: string) =>
  value && value.length < min
    ? I18n.t("Muss mind. _PLACEHOLDER_ Zeichen sein")?.replace("_PLACEHOLDER_", min.toString())
    : undefined;
const exactLength = (exactValue: number) => (value: string) =>
  value && value.length !== exactValue
    ? I18n.t("Muss bei _PLACEHOLDER_ Zeichen sein")?.replace("_PLACEHOLDER_", exactValue.toString())
    : undefined;
export const passwordsMatch = (value: string, allValues: any) =>
  value !== (allValues.password || allValues.newPassword)
    ? I18n.t("Passwörter stimmen nicht überein")
    : undefined;
export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? I18n.t("Keine gültige E-Mail-Adresse")
    : undefined;
export const password = (value: string) =>
  value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~]){1,}/gm.test(value)
    ? I18n.t("Das Passwort entspricht nicht den Richtlinien")
    : undefined;
export const MaxLengths = {
  LENGTH_10: maxLength(10),
  LENGTH_24: maxLength(24),
  LENGTH_50: maxLength(50),
};

export const MinLengths = {
  LENGTH_3: minLength(3),
  LENGTH_8: minLength(8),
};

export const ExactLengths = {
  LENGTH_15: exactLength(15),
};
