import BackendClient from "../../api/backend-client";
import { IBetriebDto, VvvoDto, ICheckVvvoResponseDto } from "../../api/backend-api-v1";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
  BetriebeActionTypeVVVO,
  checkVvvoSuccess,
  checkVvvoFailure,
  authorizeNewBetriebSuccessVVVO,
  authorizeNewBetriebFailureVVVO,
  authorizeExistingBetriebSuccessVVVO,
  authorizeExistingBetriebFailureVVVO,
  toggleExistingVvvoModalWindow,
  resendVvvoAuthorizationSuccess,
  resendVvvoAuthorizationFailure,
  ExistedBetriebDto,
} from "./betriebstaette-auth.actions";
import { Dispatch } from "redux";
import { Action } from "../action";
import apiMethods from "../../api/api-methods";
import { openModalWindow } from "../common/common.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { reset, change } from "redux-form";
import { saveBetriebSuccess } from "../betriebe/betriebe.actions";
import { history } from "../../store/configureStore";
import RoutePaths from "../../routes/route-paths";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";

let backendClient: BackendClient;

export function* authorizeNewBetriebVVVO(action: Action<IBetriebDto>) {
  try {
    const betrieb = action.payload;
    const response: IBetriebDto = yield call([backendClient, apiMethods.AuthorizeNewBetriebVvvo], betrieb);
    yield put(authorizeNewBetriebSuccessVVVO(response));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CREATE_BETRIEB_VVVO)));
    yield put(saveBetriebSuccess(betrieb));
    history.push(RoutePaths.Betriebe);
  } catch (e) {
    yield put(authorizeNewBetriebFailureVVVO(`Betrieb konnte nicht gespeichert werden: ${e.message}`));
  }
}

export function* authorizeExistingBetriebVVVO(action: Action<ExistedBetriebDto>) {
  try {
    const betrieb: ExistedBetriebDto = {
      vvvo: action.payload.vvvo,
    };

    if (action.payload.email) {
      betrieb.email = action.payload.email;
    }

    const response: IBetriebDto = yield call(
      [backendClient, apiMethods.AuthorizeExistingBetriebVvvo],
      betrieb
    );
    yield put(authorizeExistingBetriebSuccessVVVO(response));
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CREATE_BETRIEB_VVVO)));
    history.push(RoutePaths.Betriebe);
  } catch (e) {
    yield put(toggleExistingVvvoModalWindow(true));
    yield put(
      authorizeExistingBetriebFailureVVVO(`Autorisierung konnte nicht durchgeführt werden: ${e.message}`)
    );
  }
}

export function* checkVVVO(action: Action<string>) {
  try {
    const vvvo = {
      vvvo: new VvvoDto({ value: action.payload }),
    };
    const response: ICheckVvvoResponseDto = yield call([backendClient, apiMethods.CheckVvvo], vvvo);
    yield put(checkVvvoSuccess(response));
    if (!response.betriebExists) {
      yield put(openModalWindow(true));
    } else {
      yield put(reset("betriebstaetteAuth"));
      yield put(change("betriebstaetteAuth", "vvvo", vvvo.vvvo.value));
      yield put(toggleExistingVvvoModalWindow(true));
    }
  } catch (e) {
    yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_CHECK_VVVO)));
    yield put(checkVvvoFailure(`${FailureMessages.SHOW_FAILURE_CHECK_VVVO}: ${e.message}`));
  }
}

export function* resendVvvoAuthorization(action: Action<number>) {
  try {
    const betriebId = action.payload;
    yield call([backendClient, apiMethods.ResendVvvoAuthorization], betriebId);
    yield put(resendVvvoAuthorizationSuccess());
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_RETRY_AUTHORIZATION_VVVO)));
  } catch (e) {
    yield put(resendVvvoAuthorizationFailure(FailureMessages.SHOW_FAILURE_RETRY_AUTHORIZATION_VVVO));
    yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_RETRY_AUTHORIZATION_VVVO)));
  }
}

export default function* betriebeSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLatest(BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_VVVO, authorizeNewBetriebVVVO);
  yield takeEvery(BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_VVVO, authorizeExistingBetriebVVVO);
  yield takeEvery(BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION, resendVvvoAuthorization);
  yield takeLatest(BetriebeActionTypeVVVO.CHECK_VVVO, checkVVVO);
}
