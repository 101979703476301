import { AppState } from "../app.state";
import { ISyncedChecklistenEingabeDto } from "../../db/database";

export const getChecklistenEingaben = (
  state: AppState
): { [betriebId: number]: ISyncedChecklistenEingabeDto[] } => state.checklistenEingabe.checklistenEingaben;

export const getChecklistenEingabenStatus = (state: AppState) => state.checklistenEingabe.loadStatus;

export const getChecklistenEingabePdf = (state: AppState) => state.checklistenEingabe.checklistenEingabePdf;

export const getChecklistenEingabeDraft = (state: AppState) =>
  state.checklistenEingabe.checklistenEingabeDraft;

export const getCopyOfChecklistenEingabe = (state: AppState) =>
  state.checklistenEingabe.copyOfChecklistenEingabe;
