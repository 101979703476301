import { FunctionComponent } from "react";
import ChangeContactDetails from "./change-contact-details.component";
import { saveContactDetails } from "../../../store/contact-details/contact-details.actions";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../store/app.state";
import { getFormValues, change } from "redux-form";
import { ContactDetailsDto } from "../../../pages/contact-details/contact-details.component";
import { getContactDetails } from "../../../store/contact-details/contact-details.selectors";

interface IncomingProps {
  closeWindow: () => void;
  open: boolean;
}

const ChangeContactDetailsContainer: FunctionComponent<IncomingProps> = (props: any) => (
  <ChangeContactDetails {...props} />
);

const mapStateToProps = (state: AppState) => ({
  contactDetailsFields: getFormValues("contactDetails")(state),
  initialValue: getContactDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  resetField: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
  saveContactDetails: (data: ContactDetailsDto) => dispatch(saveContactDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeContactDetailsContainer);
