import { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import RoutePaths from "./route-paths";
import withAuthService from "../shared/services/with-auth.service";
import AuthService from "../shared/services/auth.service";
import ContactDetailsContainer from "../pages/contact-details/contact-details.container";

export interface ConnectedState {
  isFirstLogin: boolean;
}

interface Props extends RouteProps, ConnectedState {
  authService: AuthService;
}

const PrivateRoute: FunctionComponent<Props> = ({
  component: Component,
  authService,
  isFirstLogin,
  ...rest
}) =>
  authService.isLoggedIn() ? (
    <Route {...rest} component={authService.isFirstLogin() ? ContactDetailsContainer : Component} />
  ) : (
    <Redirect to={RoutePaths.Login} />
  );

export default withAuthService(PrivateRoute);
