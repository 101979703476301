import queryString from "query-string";

class LocationService {
  static instance: LocationService;

  static getInstance(location: string) {
    if (LocationService.instance) {
      return LocationService.instance;
    }
    return new LocationService(location);
  }

  queryParams: any;

  constructor(location: string) {
    this.queryParams = queryString.parse(location);
    if (LocationService.instance) {
      throw new Error("Instantiation failed: use service.getInstance() instead of new.");
    } else {
      LocationService.instance = this;
    }
  }

  getExpiresAtValue() {
    return this.queryParams.expiresAt;
  }

  getToken() {
    return this.queryParams.token;
  }

  getUserId() {
    return this.queryParams.userId;
  }

  getAuthorizationId() {
    return this.queryParams.authorizationId;
  }

  getEmail() {
    return this.queryParams.newEmail;
  }
}

export default LocationService;
