import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import AuthService from "../../shared/services/auth.service";
import { takeLeading, put, call } from "redux-saga/effects";
import { Action } from "../action";
import {
  ChangeEmailActionType,
  ChangeEmailDto,
  sendEmailSuccess,
  sendEmailFailure,
} from "./change-email.actions";
import { Dispatch } from "redux";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { stopSubmit } from "redux-form";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";
import { IValidateEmailResponseDto } from "../../api/backend-api-v1";

let backendClient: BackendClient;

export function* changeEmail(action: Action<ChangeEmailDto>) {
  try {
    const currentEmail = AuthService.getInstance().getEmail();
    const { password, newEmail } = action.payload;
    const email = { newEmail };

    const validatePassword = { password };
    yield call([backendClient, apiMethods.CheckPassword], validatePassword);

    const response: IValidateEmailResponseDto = yield call(
      [backendClient, apiMethods.ValidateEmail],
      newEmail
    );
    if (!response || !response.isEmailValid) {
      yield put(stopSubmit("changeEmail", { newEmail: I18n.t(FailureMessages.EMAIL_INVALID) }));
      return;
    }
    if (newEmail !== currentEmail) {
      yield call([backendClient, apiMethods.RequestChangeEmail], email);
      yield put(sendEmailSuccess());
      yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CHANGE_EMAIL)));
    }
    yield put(stopSubmit("changeEmail", { newEmail: "E-Mail konnte nicht versendet werden" }));
  } catch (e) {
    if (e.message === FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA_WITHOUT_NETWORK));
    } else {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_WRONG_PASSWORD)));
    }
    yield put(stopSubmit("changeEmail", { password: true }));
    yield put(sendEmailFailure(e.message));
  }
}

export default function* changeEmailSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ChangeEmailActionType.SEND_EMAIL, changeEmail);
}
