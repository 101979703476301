import { IBetriebDto, ICheckVvvoResponseDto, VvvoDto } from "../../api/backend-api-v1";
import { loadFailed, LoadingStatus, loadInitial, loadPending, loadSuccess } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { BetriebeActionTypeVVVO } from "./betriebstaette-auth.actions";

export interface BetriebstatteAuthState {
  betrieb: { [betriebId: number]: IBetriebDto };
  betriebExists: boolean;
  existedEmail?: string;
  authorizationExists: boolean;
  authorizationConfirmed: boolean;
  loadStatus: LoadingStatus;
  vvvo: VvvoDto;
  lastFetched: Date | undefined;
  isExistingVvvoOpen: boolean;
}

export const initialState: BetriebstatteAuthState = {
  betrieb: {},
  betriebExists: false,
  authorizationExists: false,
  authorizationConfirmed: false,
  vvvo: new VvvoDto({ value: "" }),
  loadStatus: loadInitial(),
  lastFetched: undefined,
  isExistingVvvoOpen: false,
};

export default createReducer(initialState, {
  [BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_VVVO](state: BetriebstatteAuthState): BetriebstatteAuthState {
    return { ...state, loadStatus: loadPending() };
  },
  [BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_SUCCESS_VVVO](
    state: BetriebstatteAuthState,
    action: Action<IBetriebDto>
  ): BetriebstatteAuthState {
    const betrieb = action.payload;
    return {
      ...state,
      betrieb: { ...state.betrieb, ...betrieb },
      loadStatus: loadSuccess(),
    };
  },
  [BetriebeActionTypeVVVO.AUTHORIZE_NEW_BETRIEB_FAILURE_VVVO](
    state: BetriebstatteAuthState,
    action: Action<string>
  ): BetriebstatteAuthState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
      loadStatus: loadFailed(action.payload),
    };
  },
  [BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_VVVO](
    state: BetriebstatteAuthState
  ): BetriebstatteAuthState {
    return { ...state, loadStatus: loadPending() };
  },
  [BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_SUCCESS_VVVO](
    state: BetriebstatteAuthState,
    action: Action<IBetriebDto>
  ): BetriebstatteAuthState {
    const betrieb = action.payload;
    return {
      ...state,
      betrieb: { ...betrieb },
      loadStatus: loadSuccess(),
    };
  },
  [BetriebeActionTypeVVVO.AUTHORIZE_EXISTING_BETRIEB_FAILURE_VVVO](
    state: BetriebstatteAuthState,
    action: Action<string>
  ): BetriebstatteAuthState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [BetriebeActionTypeVVVO.CHECK_VVVO](state: BetriebstatteAuthState): BetriebstatteAuthState {
    return { ...state };
  },
  [BetriebeActionTypeVVVO.CHECK_VVVO_SUCCESS](
    state: BetriebstatteAuthState,
    action: Action<ICheckVvvoResponseDto>
  ): BetriebstatteAuthState {
    return {
      ...state,
      ...action.payload,
    };
  },
  [BetriebeActionTypeVVVO.CHECK_VVVO_FAILURE](
    state: BetriebstatteAuthState,
    action: Action<string>
  ): BetriebstatteAuthState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [BetriebeActionTypeVVVO.RESET_BETRIEB_STATE]() {
    return {
      ...initialState,
    };
  },
  [BetriebeActionTypeVVVO.TOGGLE_EXISTING_VVVO_MODAL_WINDOW](
    state: BetriebstatteAuthState,
    action: Action<boolean>
  ) {
    return { ...state, isExistingVvvoOpen: action.payload };
  },
  [BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION](state: BetriebstatteAuthState): BetriebstatteAuthState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION_SUCCESS](
    state: BetriebstatteAuthState
  ): BetriebstatteAuthState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [BetriebeActionTypeVVVO.RESEND_VVVO_AUTHORIZATION_FAILURE](
    state: BetriebstatteAuthState,
    action: Action<string>
  ): BetriebstatteAuthState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
});
