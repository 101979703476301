import { history } from "../../store/configureStore";
import styles from "./checkliste-utils.module.css";
import I18n from "i18next";
import StorageService from "../../shared/services/storage.service";
import { SurveyModel } from "survey-react";

export const getCloseChecklisteWindow = (saveAsDraft: () => void) => {
  const storageService = StorageService.getInstance();

  const wrapper = document.createElement("div");
  wrapper.id = "wrapper";
  wrapper.className = styles.wrapper;

  const backgroundWrapper = document.createElement("div");
  backgroundWrapper.id = "backgroundWrapper";
  backgroundWrapper.className = styles.backgroundWrapper;
  backgroundWrapper.appendChild(wrapper);

  const modalWindow = document.createElement("div");
  modalWindow.className = styles.modalWindow;
  wrapper.appendChild(modalWindow);

  const headerTitle = document.createElement("h3");
  headerTitle.innerHTML = I18n.t("Wirklich schließen?");
  modalWindow.appendChild(headerTitle);

  const description = document.createElement("p");
  description.className = styles.description;
  description.innerHTML = I18n.t("schließen checkliste - text");
  modalWindow.appendChild(description);

  const buttonsWrapper = document.createElement("div");
  buttonsWrapper.className = styles.buttonsWrapper;
  modalWindow.appendChild(buttonsWrapper);

  const cancelButton = document.createElement("button");
  cancelButton.type = "button";
  cancelButton.innerHTML = I18n.t("ABBRECHEN");
  cancelButton.style.cssText =
    "background-color: rgb(123, 124, 126)!important; width: 190px; height: 40px; color: white; font-weight: bold;  font-size: 13pt; border-radius: 8px; border-color: transparent;";
  cancelButton.className = styles.button;
  cancelButton.onclick = () => {
    const close = document.getElementById("backgroundWrapper");
    // @ts-ignore
    close.style.cssText =
      "display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 10; background: rgba(0,0,0,0.7);";
  };
  buttonsWrapper.appendChild(cancelButton);

  const draftButton = document.createElement("button");
  draftButton.type = "button";
  draftButton.innerHTML = I18n.t("ENTWURF SPEICHERN");
  draftButton.style.cssText =
    "width: 270px; height: 40px; color: white; font-weight: bold;  font-size: 13pt; border-radius: 8px; border-color: transparent;";
  draftButton.onclick = () => {
    saveAsDraft();
  };
  buttonsWrapper.appendChild(draftButton);

  const confirmButton = document.createElement("button");
  confirmButton.type = "button";
  confirmButton.innerHTML = I18n.t("SCHLIEßEN");
  confirmButton.style.cssText =
    "width: 190px; height: 40px; color: white; font-weight: bold;  font-size: 13pt; border-radius: 8px; border-color: transparent;";
  confirmButton.onclick = () => {
    storageService.removeItem("SurveyJS_LoadState");
    history.goBack();
  };
  buttonsWrapper.appendChild(confirmButton);

  return backgroundWrapper;
};

export const getCloseButton = () => {
  const btn = document.createElement("a");
  btn.className = styles.closeButton;
  btn.innerHTML = "&times;";
  btn.onclick = () => {
    const wrapper = document.getElementById("backgroundWrapper");
    // @ts-ignore
    wrapper.style.cssText =
      "display: block; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 10; background: rgba(0,0,0,0.7);";
  };

  return btn;
};

export const getValidationButton = (
  survey: SurveyModel,
  showValidationError: () => void,
  showSuccessValidation: () => void
) => {
  const button = document.createElement("button");
  button.type = "button";
  button.innerHTML = I18n.t("Eingaben prüfen");
  button.className = styles.validationButton;
  button.onclick = () => {
    if (survey.isCurrentPageHasErrors) {
      showValidationError();
      return true;
    } else {
      showSuccessValidation();
      return false;
    }
  };

  return button;
};
