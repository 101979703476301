const generateLinkForPdf = (pdfUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.type = "pdf";
  link.style.cssText = "display: none";
  link.href = pdfUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default generateLinkForPdf;
