import DeleteBusinessPremises, { ConnectedDispatch } from "./delete-business-premises.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { deleteBetrieb } from "../../../store/betriebe/betriebe.actions";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  deleteBetrieb: (betriebId: number) => dispatch(deleteBetrieb(betriebId)),
});

export default connect(null, mapDispatchToProps)(DeleteBusinessPremises);
