import ConfirmChangeEmail, { ConnectedState, ConnectedDispatch } from "./confirm-change-email.component";
import { confirmChangeEmail } from "../../store/confirm-creation-change-email/confirm-creation-change-email.actions";
import { showFailedRequest } from "../../store/notifications/notifications.actions";
import { getTranslationsStatus } from "../../store/i18n/i18n.selectors";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  confirmChangeEmail: (token: string, userId: string, newEmail: string) =>
    dispatch(confirmChangeEmail({ token, userId, newEmail })),
  showFailedRequest: (message: string) => dispatch(showFailedRequest(message)),
});

const mapStateToProps = (state: AppState): ConnectedState => ({
  translationsStatus: getTranslationsStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmChangeEmail);
