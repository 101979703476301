import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import { ContactDetailsDto } from "../../pages/contact-details/contact-details.component";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ContactDetailsActionType } from "./contact-details.actions";

export interface ContactDetailsState extends ContactDetailsDto {
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
  isOpen: boolean;
}

export const initialState: ContactDetailsState = {
  name: "",
  street: "",
  city: "",
  firstName: "",
  postCode: "",
  country: "",
  userGroup: "",
  company: "",
  loadStatus: loadInitial(),
  lastFetched: undefined,
  isOpen: false,
};

export default createReducer(initialState, {
  [ContactDetailsActionType.LOAD_CONTACT_DETAILS](state: ContactDetailsState): ContactDetailsState {
    return { ...state, loadStatus: loadPending() };
  },
  [ContactDetailsActionType.LOAD_CONTACT_DETAILS_SUCCESS](
    state: ContactDetailsState,
    action: Action<ContactDetailsDto>
  ): ContactDetailsState {
    return {
      ...state,
      ...action.payload,
      loadStatus: loadSuccess(),
      lastFetched: new Date(Date.now()),
    };
  },
  [ContactDetailsActionType.LOAD_CONTACT_DETAILS_FAILURE](
    state: ContactDetailsState,
    action: Action<string>
  ): ContactDetailsState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      lastFetched: new Date(Date.now()),
    };
  },
  [ContactDetailsActionType.SAVE_CONTACT_DETAILS](state: ContactDetailsState): ContactDetailsState {
    return { ...state, loadStatus: loadPending() };
  },
  [ContactDetailsActionType.SAVE_CONTACT_DETAILS_SUCCESS](
    state: ContactDetailsState,
    action: Action<ContactDetailsDto>
  ): ContactDetailsState {
    return {
      ...state,
      ...action.payload,
      isOpen: false,
      loadStatus: loadSuccess(),
      lastFetched: new Date(Date.now()),
    };
  },
  [ContactDetailsActionType.SAVE_CONTACT_DETAILS_FAILURE](
    state: ContactDetailsState,
    action: Action<string>
  ): ContactDetailsState {
    return { ...state, loadStatus: loadFailed(action.payload), lastFetched: new Date(Date.now()) };
  },
  [ContactDetailsActionType.TOGGLE_CONTACT_DETAILS_MODAL_WINDOW](
    state: ContactDetailsState,
    action: Action<boolean>
  ): ContactDetailsState {
    return {
      ...state,
      isOpen: action.payload,
    };
  },
});
