import { useDispatch, connect } from "react-redux";
import SignUp from "./registration.component";
import { saveUser } from "../../store/registration/registration.actions";
import { ICreateUserRequestDto } from "../../api/backend-api-v1";
import { getModalWindow } from "../../store/common/common.selectors";
import { AppState } from "../../store/app.state";
import { SubmitHandler } from "redux-form";
import { getRegistrationStatus } from "../../store/registration/registration.selectors";

interface Props {
  onSubmit: SubmitHandler;
  isOpen: boolean;
}

const mapStateToProps = (state: AppState) => ({
  isOpen: getModalWindow(state),
  registrationStatus: getRegistrationStatus(state),
});

const SignUpContainer = (props: Props) => {
  const dispatch = useDispatch();
  const submitHandler: any = (userData: ICreateUserRequestDto) => {
    dispatch(saveUser(userData));
  };

  const { onSubmit, ...filteredProps } = props;

  return <SignUp onSubmit={submitHandler} {...filteredProps} />;
};

export default connect(mapStateToProps, null)(SignUpContainer);
