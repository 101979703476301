import useStyles from "./error.styles";
import iqImages from "../../images/iq-images";
import RoutePaths from "../../routes/route-paths";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.flexWrapper}>
        <img src={iqImages.logo} alt="PIG-CHECK" className={classes.logo} />
        <Typography className={classes.description}>Es kam zu einem unbekannten Fehler.</Typography>
        <Link className={classes.link} to={RoutePaths.Login}>
          Zurück zum Login
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
