import { Action } from "../action";

export enum ChangeEmailActionType {
  SEND_EMAIL = "SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE",
  TOGGLE_EMAIL_MODAL_WINDOW = "TOGGLE_EMAIL_MODAL_WINDOW",
}

export interface ChangeEmailDto {
  userId: string;
  token: string;
  newEmail: string;
  password: string;
}

export const sendEmail = (data: ChangeEmailDto): Action<ChangeEmailDto> => ({
  type: ChangeEmailActionType.SEND_EMAIL,
  payload: data,
});

export const sendEmailSuccess = (): Action<null> => ({
  type: ChangeEmailActionType.SEND_EMAIL_SUCCESS,
  payload: null,
});

export const sendEmailFailure = (errorMessage: string): Action<string> => ({
  type: ChangeEmailActionType.SEND_EMAIL_FAILURE,
  payload: errorMessage,
});

export const toggleEmailModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: ChangeEmailActionType.TOGGLE_EMAIL_MODAL_WINDOW,
  payload: isOpen,
});
