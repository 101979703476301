import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: Colors.Grey,
  },
  formDescription: {
    display: "flex",
    justifyContent: "start",
    width: "100%",
    marginTop: "20px",
  },
  userInfo: {
    display: "flex",
    width: "100%",
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "350px",
    marginTop: "15px",
  },
  field: {
    width: "250px",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    marginTop: "15px",
  },
  rightColumn: {
    marginLeft: "60px",
  },
  company: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  adviserField: {
    width: "250px",
    marginLeft: "40px",
    marginBottom: "10px",
  },
  vetField: {
    width: "250px",
    marginLeft: "40px",
  },
  submitButton: {
    display: "flex",
    justifyContent: "flex-end",
    width: "91%",
    paddingTop: "15px",
  },
}));

export default useStyles;
