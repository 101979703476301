import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  appversion: {
    paddingLeft: "5px",
    fontSize: "10px",
  },
}));

const getEnvironment = () => {
  let version: string = `Version: ${process.env.REACT_APP_APP_VERSION}`;

  if (process.env.REACT_APP_ENVIRONMENT !== "Production") {
    version += ` Umgebung: ${process.env.REACT_APP_ENVIRONMENT}`;
  }

  return version;
};

const Footer = () => {
  const classes = useStyles();
  return <div className={classes.appversion}>{getEnvironment()}</div>;
};

export default Footer;
