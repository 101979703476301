import { FunctionComponent } from "react";
import ContactDetailsForm from "../../contact-details-form/contact-details-form.component";
import useStyles from "./change-contact-details.styles";
import TransitionsModal from "../../common/modal/modal-window";
import { Typography } from "@material-ui/core";
import { ContactDetailsDto } from "../../../pages/contact-details/contact-details.component";
import AppButton from "../../../components/common/app-button/app-button";
import { UserGroupValue } from "../../../shared/field-data";
import { useTranslation } from "react-i18next";

interface Props {
  closeWindow: () => void;
  open: boolean;
  contactDetailsFields: ContactDetailsDto;
  resetField: (form: string, field: string, value: string) => void;
  saveContactDetails: (data: ContactDetailsDto) => void;
  initialValue: ContactDetailsDto;
}

const ChangeContactDetails: FunctionComponent<Props> = ({
  closeWindow,
  open,
  contactDetailsFields,
  resetField,
  saveContactDetails,
  initialValue,
}) => {
  const classes = useStyles();
  const ModalDetailsSize: string = "800px";
  const submitButtonStyle = { marginRight: "350px" };
  const { t } = useTranslation();

  const submitHandler: any = (data: ContactDetailsDto) => {
    switch (data.userGroup) {
      case UserGroupValue.BERATER:
        const { companyBerater, ...restBerater } = data;
        saveContactDetails({ ...restBerater, company: companyBerater, companyBerater });
        break;
      case UserGroupValue.TIERARZT:
        const { companyTierarzt, ...restTierarzt } = data;
        saveContactDetails({ ...restTierarzt, company: companyTierarzt, companyTierarzt });
        break;
      default:
        const { company, ...rest } = data;
        saveContactDetails(rest);
        break;
    }
  };

  return (
    <TransitionsModal closeModal={closeWindow} open={open} sizeWidth={ModalDetailsSize}>
      <Typography className={classes.title}>{t("Meine Kontaktdaten ändern")}</Typography>
      <ContactDetailsForm
        onSubmit={submitHandler}
        buttonName="ÜBERNEHMEN"
        fields={contactDetailsFields}
        initialValues={initialValue}
        resetField={resetField}
        submitButtonStyle={submitButtonStyle}
      />
      <div className={classes.buttonWrapper}>
        <AppButton handler={closeWindow} className={classes.cancelButton}>
          {t("ABBRECHEN")}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ChangeContactDetails;
