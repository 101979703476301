import { BetriebWithChecklistenEingabenDto, IBetriebDto } from "../../api/backend-api-v1";
import { Action } from "../action";

export interface BetriebeEntities {
  betriebe: { [betriebId: number]: BetriebWithChecklistenEingabenDto };
}

export enum BetriebeActionType {
  LOAD_BETRIEBE = "LOAD_BETRIEBE",
  REFRESH_BETRIEBE = "REFRESH_BETRIEBE",
  LOAD_BETRIEBE_SUCCESS = "LOAD_BETRIEBE_SUCCESS",
  LOAD_BETRIEBE_FAILURE = "LOAD_BETRIEBE_FAILURE",
  SAVE_BETRIEB = "SAVE_BETRIEB",
  SAVE_BETRIEB_SUCCESS = "SAVE_BETRIEB_SUCCESS",
  SAVE_BETRIEB_FAILURE = "SAVE_BETRIEB_FAILURE",
  DELETE_BETRIEB = "DELETE_BETRIEB",
  DELETE_BETRIEB_SUCCESS = "DELETE_BETRIEB_SUCCESS",
  DELETE_BETRIEB_FAILURE = "DELETE_BETRIEB_FAILURE",
}

export const loadBetriebe = (): Action<null> => ({
  type: BetriebeActionType.LOAD_BETRIEBE,
  payload: null,
});

export const refreshBetriebe = (): Action<null> => ({
  type: BetriebeActionType.REFRESH_BETRIEBE,
  payload: null,
});

export const loadBetriebeSuccess = (entities: BetriebeEntities): Action<BetriebeEntities> => ({
  type: BetriebeActionType.LOAD_BETRIEBE_SUCCESS,
  payload: entities,
});

export const loadBetriebeFailure = (errorMessage: string): Action<string> => ({
  type: BetriebeActionType.LOAD_BETRIEBE_FAILURE,
  payload: errorMessage,
});

export const saveBetrieb = (betrieb: IBetriebDto): Action<IBetriebDto> => ({
  type: BetriebeActionType.SAVE_BETRIEB,
  payload: betrieb,
});

export const saveBetriebSuccess = (betrieb: IBetriebDto): Action<IBetriebDto> => ({
  type: BetriebeActionType.SAVE_BETRIEB_SUCCESS,
  payload: betrieb,
});

export const saveBetriebFailure = (errorMessage: string): Action<string> => ({
  type: BetriebeActionType.SAVE_BETRIEB_FAILURE,
  payload: errorMessage,
});

export const deleteBetrieb = (betriebId: number): Action<number> => ({
  type: BetriebeActionType.DELETE_BETRIEB,
  payload: betriebId,
});

export const deleteBetriebSuccess = (): Action<null> => ({
  type: BetriebeActionType.DELETE_BETRIEB_SUCCESS,
  payload: null,
});

export const deleteBetriebFailure = (errorMessage: string): Action<string> => ({
  type: BetriebeActionType.DELETE_BETRIEB_FAILURE,
  payload: errorMessage,
});
