import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import { takeLeading, put, call } from "redux-saga/effects";
import { Action } from "../action";
import {
  ChangePasswordActionType,
  sendPasswordSuccess,
  sendPasswordFailure,
  ChangePasswordDto,
} from "./change-password.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { Dispatch } from "redux";
import { stopSubmit } from "redux-form";
import { openModalWindow } from "../common/common.actions";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";

let backendClient: BackendClient;
const logError = logger.error("changePassword.saga");

export function* changePassword(action: Action<ChangePasswordDto>) {
  try {
    const { currentPassword } = action.payload;
    if (currentPassword) {
      yield call([backendClient, apiMethods.CheckPassword], { password: currentPassword });
    }
    yield call([backendClient, apiMethods.ChangePassword], action.payload);
    yield put(sendPasswordSuccess());
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_CHANGE_PASSWORD)));
    yield put(openModalWindow(false));
  } catch (e) {
    logError(e.message);
    yield put(stopSubmit("changePassword", { password: "Invalid password" }));
    yield put(sendPasswordFailure(e.message));
    if (e.message === FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_SAVE_CONTACT_DETAILS_DATA_WITHOUT_NETWORK));
    } else {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_CHANGE_PASSWORD)));
    }
  }
}

export default function* changePasswordSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLeading(ChangePasswordActionType.SEND_PASSWORD, changePassword);
}
