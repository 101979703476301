enum RoutePaths {
  Betriebe = "/",
  ChecklistenOverview = "/checklistenOverview",
  Checkliste = "/checkliste",
  Login = "/login",
  Registration = "/signup",
  ChangePassword = "/change-password",
  Profile = "/profile",
  ContactDetails = "/contact-details",
  ChangePasswordFailure = "/change-password-failure",
  RegistrationFailure = "/signup-failure",
  ConfirmEmail = "/confirm-email",
  ConfirmAuthorizeVvvo = "/confirm-authorize-vvvo",
  RejectAuthorizeVvvo = "/reject-authorize-vvvo",
  BetriebstatteAuth = "/betriebstatten",
  ChangeEmail = "/change-email",
  Info = "/info",
  Error = "/error",
  ResendRegistrationEmail = "/resend-registration-email",
}
export default RoutePaths;
