import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    color: Colors.Grey,
  },
  formGroupWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  contactForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  emailWrapper: {
    paddingRight: "40px",
  },
  passwordWrapper: {
    marginRight: "25px",
  },
  lineAccross: {
    borderBottom: "2px solid grey",
    paddingLeft: "20px",
    paddingTop: "10px",
    fontWeight: "bold",
    width: "97%",
    color: Colors.Grey,
  },
  logo: {
    height: "100px",
  },
  proffenButton: {
    marginLeft: "30px",
    marginTop: "13px",
  },
  proffenModalButton: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    lineHeight: "20px",
    color: Colors.Grey,
  },
  formContactWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "370px",
    marginTop: "7px",
  },
  field: {
    width: "250px",
  },
  label: {
    alignSelf: "center",
  },
  autorisierungButton: {
    marginTop: "15px",
    marginLeft: "145px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: Colors.Grey,
  },
});
