import { FunctionComponent } from "react";
import { reduxForm, Field } from "redux-form";
import { useStyles } from "../profile.styles";
import renderTextField from "../../../components/common/render-text-field/render-text-field";
import { required, MinLengths, MaxLengths, password } from "../../../utils/authValidation/auth-validation";
import AppButton from "../../../components/common/app-button/app-button";
import { ButtonSize } from "../../../shared/buttons-style";
import { FieldName, PlaceHolder } from "../../../shared/field-data";
import TransitionsModal from "../../../components/common/modal/modal-window";
import ChangeProfilePasswordForm from "../../../components/change-profile-password/change-profile-password-form.component";
import { ChangePasswordDto } from "../../../store/change-password/change-password.actions";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  sendPassword: (data: ChangePasswordDto) => void;
  togglePasswordModalWindow: (isOpen: boolean) => void;
}

const PasswordForm: FunctionComponent<Props> = ({ isOpen, sendPassword, togglePasswordModalWindow }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openWindow = () => {
    togglePasswordModalWindow(true);
  };

  const closeWindow = () => {
    togglePasswordModalWindow(false);
  };

  const submitHandler: any = (fieldData: ChangePasswordDto) => {
    const data: ChangePasswordDto = { ...fieldData };
    sendPassword(data);
  };

  return (
    <div>
      <form className={classes.passwordWrapper}>
        <div className={classes.fieldWrapper}>
          <label className={classes.label}> {t("Passwort")}</label>
          <Field
            className={classes.field}
            name={FieldName.PASSWORD}
            component={renderTextField}
            placeholder={PlaceHolder.HIDDEN_PASSWORD}
            validate={[required, MinLengths.LENGTH_8, MaxLengths.LENGTH_24, password]}
            disabled={true}
            type="password"
          />
        </div>
      </form>
      <div className={classes.autorisierungButton}>
        <AppButton handler={openWindow} size={ButtonSize.MEDIUM_BUTTON}>
          {t("ÄNDERN")}
        </AppButton>
      </div>
      {isOpen && (
        <TransitionsModal closeModal={closeWindow} open={isOpen}>
          <h2 className={classes.title}> {t("Passwort ändern ")}</h2>
          <ChangeProfilePasswordForm onSubmit={submitHandler} onClose={closeWindow} />
        </TransitionsModal>
      )}
    </div>
  );
};
export default reduxForm<any, any>({ form: "password" })(PasswordForm);
