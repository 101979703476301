import logger from "../../logger";
import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import RoutePaths from "../../routes/route-paths";
import { history } from "../../store/configureStore";
import { BetriebDto, IBetriebDto, BetriebWithChecklistenEingabenDto } from "../../api/backend-api-v1";
import { takeEvery, put, call } from "redux-saga/effects";
import { normalize, NormalizedSchema } from "normalizr";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import { showSuccessfulRequest, showFailedRequest } from "../notifications/notifications.actions";
import {
  BetriebeEntities,
  BetriebeActionType,
  loadBetriebeFailure,
  loadBetriebeSuccess,
  saveBetriebSuccess,
  saveBetriebFailure,
  deleteBetriebSuccess,
  deleteBetriebFailure,
} from "./betriebe.actions";
import { betriebeSchema } from "./betriebe.schema";
import { Dispatch } from "redux";
import { Action } from "../action";
import I18n from "i18next";
import { loadLatestChecklistenEingaben } from "../checklisten-eingabe/checklisten-eingabe.actions";

const logError = logger.error("betriebe.saga");

let backendClient: BackendClient;

export function* loadBetriebe() {
  try {
    const betriebe: BetriebWithChecklistenEingabenDto[] = yield call([backendClient, apiMethods.GetBetriebe]);

    const normalizedData: NormalizedSchema<BetriebeEntities, number[]> = normalize(betriebe, [
      betriebeSchema,
    ]);

    let checklistenEingabenData = {};
    const checklistenEingabenDataByBetriebId = betriebe.map(betrieb => ({
      [betrieb.id]: betrieb.checklistenEingaben!.map(item => ({ ...item, synced: 1 })),
    }));
    checklistenEingabenDataByBetriebId.forEach(
      el => (checklistenEingabenData = { ...checklistenEingabenData, ...el })
    );

    yield put(loadBetriebeSuccess(normalizedData.entities));
    yield put(loadLatestChecklistenEingaben(checklistenEingabenData));
  } catch (e) {
    logError("Could not fetch betriebe", e.message);
    yield put(loadBetriebeFailure(`Betriebe konnten nicht geladen werden: ${e.message}`));
  }
}

export function* saveBetrieb(action: Action<IBetriebDto>) {
  try {
    const betrieb = action.payload;
    const response: BetriebDto = yield call([backendClient, apiMethods.SaveBetrieb], betrieb);
    yield put(saveBetriebSuccess(response));
  } catch (e) {
    logError("Could not save betrieb", e.message);
    yield put(saveBetriebFailure(`Betrieb konnte nicht gespeichert werden: ${e.message}`));
  }
}

export function* deleteBetrieb(action: Action<number>) {
  try {
    const betriebId = action.payload;
    yield call([backendClient, apiMethods.DeleteBetrieb], betriebId);
    yield put(deleteBetriebSuccess());
    history.push(RoutePaths.Betriebe);
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_DELETE_BETRIEB)));
  } catch (e) {
    if (e.message === FailureMessages.NETWORK_ERROR) {
      yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_DELETE_BETRIEB_WITHOUT_NETWORK));
    }
    logError("Could not save betrieb", e.message);
    yield put(deleteBetriebFailure(e.message));
  }
}

export default function* betriebeSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(BetriebeActionType.LOAD_BETRIEBE, loadBetriebe);
  yield takeEvery(BetriebeActionType.REFRESH_BETRIEBE, loadBetriebe);
  yield takeEvery(BetriebeActionType.SAVE_BETRIEB, saveBetrieb);
  yield takeEvery(BetriebeActionType.DELETE_BETRIEB, deleteBetrieb);
}
