import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";
import { ButtonSize } from "../../shared/buttons-style";

export const useStyles = makeStyles({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "200px",
  },
  button: {
    ...ButtonSize.LARGE_BUTTON,
    backgroundColor: Colors.Grey,
    marginBottom: "15px",
    "&:hover": {
      opacity: 0.75,
    },
  },
});
