import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ChangeEmailActionType, ChangeEmailDto } from "./change-email.actions";

export interface ChangeEmailState extends ChangeEmailDto {
  loadStatus: LoadingStatus;
  isOpen: boolean;
  password: string;
}

export const initialState: ChangeEmailState = {
  userId: "",
  token: "",
  newEmail: "",
  loadStatus: loadInitial(),
  isOpen: false,
  password: "",
};

export default createReducer(initialState, {
  [ChangeEmailActionType.SEND_EMAIL](
    state: ChangeEmailState,
    action: Action<ChangeEmailDto>
  ): ChangeEmailState {
    return {
      ...state,
      ...action.payload,
      loadStatus: loadPending(),
    };
  },
  [ChangeEmailActionType.SEND_EMAIL_SUCCESS](state: ChangeEmailState): ChangeEmailState {
    return {
      ...state,
      isOpen: false,
      loadStatus: loadSuccess(),
    };
  },
  [ChangeEmailActionType.SEND_EMAIL_FAILURE](
    state: ChangeEmailState,
    action: Action<string>
  ): ChangeEmailState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [ChangeEmailActionType.TOGGLE_EMAIL_MODAL_WINDOW](
    state: ChangeEmailState,
    action: Action<boolean>
  ): ChangeEmailState {
    return {
      ...state,
      isOpen: action.payload,
      loadStatus: loadSuccess(),
    };
  },
});
