import { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import RoutePaths from "./route-paths";
import withAuthService from "../shared/services/with-auth.service";
import AuthService from "../shared/services/auth.service";

interface Props extends RouteProps {
  authService: AuthService;
}

const PublicRoute: FunctionComponent<Props> = ({ component: Component, authService, ...rest }) =>
  authService.isLoggedIn() ? (
    <Redirect to={RoutePaths.Betriebe} />
  ) : (
    <Route {...rest} component={Component} />
  );

export default withAuthService(PublicRoute);
