import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { LogoutActionType } from "./logout.actions";

export interface LogoutState {
  isLogout: boolean;
  loadStatus: LoadingStatus;
}

export const initialState = {
  isLogout: false,
  loadStatus: loadInitial(),
};

export default createReducer(initialState, {
  [LogoutActionType.LOGOUT](state: LogoutState): LogoutState {
    return { ...state, loadStatus: loadPending() };
  },
  [LogoutActionType.LOGOUT_SUCCESS](state: LogoutState, action: Action<boolean>): LogoutState {
    return { ...state, loadStatus: loadSuccess(), isLogout: action.payload };
  },
  [LogoutActionType.LOGOUT_FAILURE](state: LogoutState, action: Action<string>): LogoutState {
    return { ...state, loadStatus: loadFailed(action.payload) };
  },
});
