import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { ChangePasswordActionType, ChangePasswordDto } from "./change-password.actions";

export interface ChangePasswordState extends ChangePasswordDto {
  loadStatus: LoadingStatus;
  isOpen: boolean;
}

export const initialState: ChangePasswordState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  loadStatus: loadInitial(),
  isOpen: false,
};

export default createReducer(initialState, {
  [ChangePasswordActionType.SEND_PASSWORD](
    state: ChangePasswordState,
    action: Action<ChangePasswordDto>
  ): ChangePasswordState {
    return {
      ...state,
      ...action.payload,
      loadStatus: loadPending(),
    };
  },
  [ChangePasswordActionType.SEND_PASSWORD_SUCCESS](state: ChangePasswordState): ChangePasswordState {
    return {
      ...state,
      isOpen: false,
      loadStatus: loadSuccess(),
    };
  },
  [ChangePasswordActionType.SEND_PASSWORD_FAILURE](
    state: ChangePasswordState,
    action: Action<string>
  ): ChangePasswordState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [ChangePasswordActionType.TOGGLE_PASSWORD_MODAL_WINDOW](
    state: ChangePasswordState,
    action: Action<boolean>
  ): ChangePasswordState {
    return {
      ...state,
      isOpen: action.payload,
      loadStatus: loadSuccess(),
    };
  },
});
